import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const addFeedback = async function (name, email, responses) {
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addFeedback(
        $name: String!
        $email: String!
        $responses: [ResponseCreateInput!]!
      ) {
        addFeedback(
          data: { name: $name, email: $email, responses: $responses }
        ) {
          code
          message
          http_status
          data {
            id
          }
          errors
        }
      }
    `,
    variables: {
      name: name,
      email: email,
      responses: responses,
    },
  });

  return data;
};
