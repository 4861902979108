import React from "react";
import FooterImg from "../../../components/footerimg/FooterImg";
import { Container, Typography } from "@mui/material";
import "../../../components/financialhelathhero/FinancialHelathHero--top/FinancialHelathHeroTop.css";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Icon from "@mui/material/Icon";
import {BackDropEffect} from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'

const PrivacyPolicy = () => {

  const StyledTableContainer = styled(TableContainer)({
    marginBottom: 51,
    marginTop: 29,
    boxShadow: "0px 4px 65px 0px rgba(0, 0, 0, 0.20)",
    borderRadius: 15
  })


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: 32,
  verticalAlign: "top",
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#102691",
      color: theme.palette.common.white,
      fontSize: '2.0rem',
      fontWeight: 800,
      lineHeight: "normal"
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '1.6rem',
      fontStyle: "italic",
      borderWidth: 0,
      borderRightWidth: 1,
      borderColor: "rgba(0, 0, 0, 0.20)",
      borderStyle: "solid"
    },
    "&:last-child": {
      borderRightWidth: 0,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    borderWidth:0,
    borderBottomWidth:1,
    borderColor: "rgba(0, 0, 0, 0.20)",
    borderStyle: "solid",
    // hide last border
    "&:last-child td, &:last-child th": {
      borderBottomWidth: 0,
    },
  }));

  const DiamondIcon = styled(Icon)({
    fontSize: '0.8rem',
    background: "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
    transform: "rotate(-45deg)",
    verticalAlign: "middle",
    marginRight: "6px",
  });

 

  return (
    <>
      <Container sx={{position: "relative"}} maxWidth="lg">
      <BackDropEffect/>
      <BackLink/>
        <Typography variant="h1" align="center" >
          Privacy Policy
        </Typography>
        <Typography variant="h3">
          <DiamondIcon />
          Introduction
        </Typography>
        <Typography variant="body1">
          This Privacy Policy (the “Policy”) describes the type of personal
          information that PFScores (the “Company,” “we” or “us”) gathers from
          visitors to our website (the “Site”) and the PFScores application,
          whether accessed via a web browser or as a mobile application (the
          “Service”). The Site provides information about the Company’s business
          activities and means through which interested users may learn more
          about Company. The Service helps users to assess and understand their
          financial health through the provision of financial wellness scores.
        </Typography>
        <Typography variant="h3">
        <DiamondIcon />
          Collection, Use, and Sharing of Personal Information
        </Typography>
        <Typography variant="body1">
          <strong>"Personal Information"</strong> means information that can be
          associated with a particular user. The charts below detail what
          Personal Information we collect.
        </Typography>

        <StyledTableContainer component={Paper}>
          <Table sx={{minWidth: 900}}>
            <TableHead>
              <TableRow>
                <StyledTableCell >
                  What Personal Information We Collect
                </StyledTableCell>
                <StyledTableCell
                
                >
                  How We Collect the Personal Information
                </StyledTableCell>
                <StyledTableCell
                
                >
                  Why We Collect the Personal Information
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <StyledTableRow>
                <StyledTableCell >
                  Collection of information regarding the use of the Site.
                </StyledTableCell>
                <StyledTableCell>
                  We may use cookies and similar tools (with the agreement of
                  the user) in order to record how users interact with the Site.
                </StyledTableCell>
                <StyledTableCell >
                  We collect this information to improve the functionality of
                  the Site.
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell >
                  Information you provide directly that is related to sign-up,
                  including name, age, zip code, and email address.
                </StyledTableCell>
                <StyledTableCell >
                  We ask for this information directly when a user signs up to
                  use the Service.
                </StyledTableCell>
                <StyledTableCell >
                  We require this information in order to deliver the service.
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell >
                  Information you provide directly that is related to the use of
                  the Service, including financial information directly entered
                  by the user in relation to debt management, risk management,
                  and savings.
                </StyledTableCell>
                <StyledTableCell >
                  We request this information directly from users.
                </StyledTableCell>
                <StyledTableCell >
                  We use this information to generate a financial wellness score
                  for the user, as well as to gain insight on users’ preferences
                  and to serve appropriate marketing messages consistent with
                  each individual user’s preferences.
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow>
                <StyledTableCell >
                  Collection of information regarding the use of the Service.
                </StyledTableCell>
                <StyledTableCell>
                  We may use cookies and similar tools (with the agreement of
                  the user) in order to record how users interact with the
                  Service.
                </StyledTableCell>
                <StyledTableCell >
                  We collect this information to improve the functionality of
                  the Service.
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>

        <Typography variant="body1">
          We do not share or sell this information, except as explained below
          under“Third-Party Contractors,” “Business Transitions,” and
          “Compliance with Law and Prevention of Harm.”
        </Typography>

        <Typography variant="body1">
          <strong>Third-Party Contractors – </strong>We may use contractors
          (“Service Providers”) to perform limited services on our behalf, such
          as hosting websites and providing email services. Service Providers
          are required to obtain only the Personal Information they need to
          deliver the service they were hired to perform, to maintain the
          confidentiality of Personal Information, and not to use Personal
          Information for any purpose other than the service they were hired to
          perform.
        </Typography>

        <Typography variant="body1">
          <strong>Business Transitions – </strong>We may share Personal
          Information with businesses that are legally part of the same group as
          the Company, or that become part of that group. We reserve the right –
          in the event of a business transition such as a merger – to transfer
          Personal Information to a new business owner, on the condition that
          such Personal Information must be treated in accordance with this
          Policy.
        </Typography>

        <Typography variant="body1">
          <strong>Compliance with Law and Prevention of Harm – </strong>
          We may disclose your Personal Information or any information submitted
          via the Site or Service if we have a good faith belief that disclosure
          of such information is helpful or reasonably necessary to: (i) comply
          with any applicable law, regulation, legal process or governmental
          request; (ii) enforce any applicable terms of service, including
          investigations of potential violations thereof; (iii) detect, prevent,
          or otherwise address fraud or security issues; or (iv) protect against
          harm to the rights, property or safety of the Company, our users,
          yourself or the public. We may be required to disclose Personal
          Information in response to a lawful request by public authorities,
          including to meet national security or law enforcement requirements.
        </Typography>

        <Typography variant="body1">
          <strong>Aggregated and De-identified Data – </strong>
          As noted above, this Policy addresses the collection and sharing of
          Personal Information, that is, information that can reasonably be
          connected with a particular user. We may occasionally de-identify
          and/or aggregate Personal Information – to the extent permitted by law
          – to the point that it can no longer be associated with a particular
          user. When that occurs, such information is no longer Personal
          Information and not subject to this Policy.
        </Typography>

        <Typography variant="body1">
          <strong>
            Use, Sharing, and/or Sale of Non-personal Information –{" "}
          </strong>
          We may aggregate data on user behavior with respect to the Site or the
          Service, including to deliver or improve the Site or Service. This
          data does not enable identification of individual users and is not
          Personal Information as defined by this Policy. As such, our use,
          sharing, and/or sale of non-personal information is not subject to
          this Policy.
        </Typography>

        <Typography variant="h3"> <DiamondIcon />Deletion of Personal Information</Typography>
        <Typography variant="body1">
          A User may delete Personal Information that we have regarding that
          User by using the Delete Account button in the Service. We may choose
          to delete Personal Information by de-identifying, aggregating, or
          completely erasing the Personal Information.
        </Typography>

        <Typography variant="h3">
        <DiamondIcon />
          Protection and Retention of Personal Information
        </Typography>
        <Typography variant="body1">
          We follow generally accepted industry standards, including the use of
          appropriate administrative, physical and technical safeguards, to
          protect Personal Information.This includes use of SSL encryption and
          measures consistent with TRUSTe certification as well as Verisign
          registration.
        </Typography>
        <Typography variant="body1">
          However, no method of transmission over the Internet or method of
          electronic storage is entirely secure. Therefore, while we strive to
          use commercially reasonable means to protect Personal Information, we
          cannot guarantee its absolute security or confidentiality. Please be
          aware that certain Personal Information and other information provided
          by you in connection with your use of the Site or Service may be
          stored on your device (even if that information is not collected by
          us). You are solely responsible for maintaining the security of your
          device from unauthorized access.
        </Typography>
        <Typography variant="body1">
          Personal Information will be retained for as long as is reasonably
          necessary to achieve the purposes set forth in this Policy, and to
          comply with all applicable laws.
        </Typography>

        <Typography variant="h3"><DiamondIcon />Other Provisions</Typography>
        <Typography variant="body1">
          <strong>Children’s Privacy –</strong> The Site and Service are neither
          directed to nor structured to attract Users under the age of 18. If
          you are under the age of 18, you are not permitted to use the Site or
          Service. The Company does not knowingly collect Personal Information
          from users under the age of 18.
        </Typography>
        <Typography variant="body1">
          <strong>Amendments –</strong> We may modify or amend this Privacy
          Policy from time to time. If we make any material changes, as
          determined by us, to this Privacy Policy, including in the way in
          which Personal Information is collected, used or transferred, we will
          notify you by e-mail to the address specified in your profile or by
          means of a notice on the Site prior to the change becoming effective.
        </Typography>

        <Typography variant="h3"><DiamondIcon />Effective Date</Typography>
        <Typography variant="body1">
          The effective date of this Policy is March 31, 2023.
        </Typography>

        
      </Container>
     
      <FooterImg />
    </>
  );
};

export default PrivacyPolicy;
