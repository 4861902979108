const ceInitialState = {
    // Savings for college education
    ceNumChild: 0,
    children: [],
    one_year_college_cost: 0,
    avg_annual_tuition_inflation: 0,
};

export const individualCeInitialState = {

    // Identifiers
    cIndex: 0,
    id: "",

    // Individual Child Variables
    name_of_child: "",
    age_of_child: 0,
    percent_of_cost_for_child: 0,
    amount_saved_so_far: 0,
    current_monthly_savings_for_child: 0,
    expected_annual_return_on_savings: 0,

    // // Calculated Values
    // total_monthly_contribution_required: 0,
    // additional_monthly_contribution_required: 0
};

const MergeCollegeEducationVar = (state = individualCeInitialState, action_type, action_payload) => {
    switch (action_type) {
        // identifiers
        case "CE_INDEX":
            return {
                ...state,
                cIndex: (state.cIndex = action_payload),
            };
        case "CE_ID":
            return {
                ...state,
                id: (state.id = action_payload),
            };

        // Individual Child Details
        case "C_NAME":
            return {
                ...state,
                name_of_child: (state.name_of_child = action_payload),
            };
        case "C_AGE":
            return {
                ...state,
                age_of_child: (state.age_of_child = action_payload),
            };
        case "C_PERCENT_COST_INTENT":
            return {
                ...state,
                percent_of_cost_for_child: (state.percent_of_cost_for_child = action_payload),
            };
        case "C_AMOUNT_SAVED":
            return {
                ...state,
                amount_saved_so_far: (state.amount_saved_so_far = action_payload),
            };
    
        case "C_MONTHLY_SAVING":
            return {
                ...state,
                current_monthly_savings_for_child: (state.current_monthly_savings_for_child = action_payload),
            };
        case "C_EXPT_ANNUAL_RET":
            return {
                ...state,
                expected_annual_return_on_savings: (state.expected_annual_return_on_savings = action_payload),
            };

        // Calculated Values
        // case "C_EXPT_MONTHLY_CONTRIBUTION":
        //     return {
        //         ...state,
        //         total_monthly_contribution_required: (state.total_monthly_contribution_required = action_payload),
        //     };
        // case "C_SAVING_NEEDED":
        //     return {
        //         ...state,
        //         additional_monthly_contribution_required: (state.additional_monthly_contribution_required = action_payload),
        //     };

        // Update All at once
        case "ALL":
            return {
                ...state,

                cIndex: (state.cIndex = action_payload.cIndex),
                id: (state.id = action_payload.id),

                name_of_child: (state.name_of_child = action_payload.name_of_child),
                age_of_child: (state.age_of_child = action_payload.age_of_child),
                percent_of_cost_for_child: (state.percent_of_cost_for_child = action_payload.percent_of_cost_for_child),
                amount_saved_so_far: (state.amount_saved_so_far = action_payload.amount_saved_so_far),
                current_monthly_savings_for_child: (state.current_monthly_savings_for_child = action_payload.current_monthly_savings_for_child),
                expected_annual_return_on_savings: (state.expected_annual_return_on_savings = action_payload.expected_annual_return_on_savings),

                // total_monthly_contribution_required: (state.total_monthly_contribution_required = action_payload.total_monthly_contribution_required),
                // additional_monthly_contribution_required: (state.additional_monthly_contribution_required = action_payload.additional_monthly_contribution_required)
            };
    
        default:
            return state;
    }

};


export const CollegeEducationReducer = (state = ceInitialState, action) => {
    /**
        {
            payload: {
                itemIndex: Number,
                data: {
                    cIndex: String,
                    cId: String,
                }
            }   
        };
     */

    let item_index = -1;
    let children = state.children;
    
    switch (action.type) {

        // Savings for major purchases
        case "CE_NUM_CHILD":
            return {
                ...state,
                ceNumChild: (state.ceNumChild = action.payload.data),
            };
        case "CE_EDUS":
            return {
                ...state,
                children: (state.children = action.payload.data),
            };
        case "CE_ONE_YEAR_COST":
            return {
                ...state,
                one_year_college_cost: (state.one_year_college_cost = action.payload.data),
            };
        case "CE_TUTION_INFLATION":
            return {
                ...state,
                avg_annual_tuition_inflation: (state.avg_annual_tuition_inflation = action.payload.data),
            };
    
        // Actions
        case "INITIAL_SYNC":
            console.log("Inside INITIAL_SYNC Action!");
            children = [];
            for (let index = 0; index < action.payload.data.children.length; index++) {
                // const element = action.payload.data[index];
                const child = MergeCollegeEducationVar(individualCeInitialState, 'ALL', action.payload.data.children[index]);
                children.push(child);
            }

            return {
                ...state,
                id: (state.id = action.payload.data.id),
                children: children,
                one_year_college_cost: (state.one_year_college_cost = action.payload.data.one_year_college_cost),
                avg_annual_tuition_inflation: (state.avg_annual_tuition_inflation = action.payload.data.avg_annual_tuition_inflation),
            };

        case "ADD":
            console.log("Inside ADD Action!!!!!!!!!!!!!!!!!!!!!");
            // let inState = individualCeInitialState;
            let inState = action.payload.data;
            inState.name_of_child = "";
            // inState.cId = null;
            const newEducationItem = MergeCollegeEducationVar({ ...inState });
            state.children.push(newEducationItem);
            // state.ceNumChild = state.ceNumChild + 1;
            console.log("Adding new item");
            console.log(newEducationItem);
            return state;

        case "UPDATE":
            if (action.payload.itemIndex !== undefined) {
                children = state.children;
            item_index = parseInt(action.payload.itemIndex);
            console.log(`Updating item_index: ${item_index}`);
            const itemOldState = state.children[item_index];
            console.log(`itemOldState: ${itemOldState}`);
            console.log(itemOldState);
            const itemNewState = MergeCollegeEducationVar(itemOldState, action.payload.variable, action.payload.data);
            console.log(`itemNewState: ${itemNewState}`);
            console.log(itemNewState);

            children[item_index] = itemNewState;
            return {
                ...state,
                children
            };
            } else {
                if (action.payload.variable === "CE_ONE_YEAR_COST") {
                    return {
                        ...state,
                        one_year_college_cost: (state.one_year_college_cost = action.payload.data)
                    };
                } else {
                    return {
                        ...state,
                        avg_annual_tuition_inflation: (state.avg_annual_tuition_inflation = action.payload.data),
                    };
                }
            }
            

        case "REMOVE":            
            item_index = parseInt(action.payload.itemIndex);
            console.log(`Removing item_index: ${item_index}`);
            children = state.children;
            if ((typeof item_index) === "number" && item_index > -1) {
                const removedItem = children.splice(item_index, 1);
                console.log(`removedItem is: ${removedItem}`);
                // state.ceNumChild = (1 > parseInt(state.ceNumChild) ? 0 : (parseInt(state.ceNumChild) - 1));
            }

            return {
                ...state,
                children
            };

        default:
            return state;
    }
};
