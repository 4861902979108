import React from "react";
import { Container, Box, Checkbox, Button, Link, Alert, Grid, Typography, SvgIcon } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import heroimg from "../../../assets/images/login.png";


import { useFormik } from "formik";
import * as Yup from "yup";

import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSignin } from "../../../hooks/useSignin";
// import { SiteConstants } from "../../../constants/SiteConstants";
// import { BorderColor } from "@mui/icons-material";
import "../../../components/financialhelathhero/FinancialHelathHero--top/FinancialHelathHeroTop.css";

import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const Login = () => {
  // error
  const { signin, verifyEmail, isLoading, error } = useSignin();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Checkbox" } };
  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Invalid email address")
      .required("Email is required."),
    password: Yup.string("Enter your password")
      .min(4, "Password must be at least 8 characters")
      .required("Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember_me: false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const authResponse = await signin(values["email"], values["password"]);

      if (authResponse) {
        navigate("/scoring-details/personal-details");
      }
    },
  });


  return (

    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Grid container   >
        <Grid item xs={12} md={6}>

          <Typography variant="h1">Login</Typography>

          <form onSubmit={formik.handleSubmit}>

            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="Enter your email"
              label="Email address"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                formik.touched.email && Boolean(formik.errors.email)
              }
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />

            <TextField
              fullWidth
              id="password"
              name="password"
              placeholder="Enter your password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.touched.password &&
                Boolean(formik.errors.password)
              }
              helperText={
                formik.touched.password && formik.errors.password
              }
              margin="normal"
            />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <FormControlLabel control={<Checkbox name="remember_me" sx={{ color: '#DFDFDF' }}
                onChange={formik.handleChange}
                {...label} />} label="Remember me" />

              <Link href="/forgot-password" underline="none">Forget Password?</Link>

            </Stack>

            <Box className="buttons_section">
              <Button
                disabled={isLoading}
                fullWidth
                variant="contained"
                size="large"
                type="submit"
                endIcon={<ArrowForwardIcon />}
                sx={{ marginTop: '40px', color: '#102691', backgroundImage: 'linear-gradient(267deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)' }}
              >
                Login

              </Button>
              {error && error.status !== "NOT_VERIFIED" && error.status !== "SUCCESS" && (
                <Alert sx={{ marginTop: "10px" }} severity="error">Error: {error.message}</Alert>
              )}

              {error && error.status === "SUCCESS" && (
                <Alert sx={{ marginTop: "10px" }} severity="success">{error.message}</Alert>
              )}

              {error && error.status === "NOT_VERIFIED" && (
                <Box sx={{ marginTop: "10px" }}>
                  <Alert severity="error">Error: {error.message}</Alert>
                  <Button disabled={isLoading} sx={{ color: '#102691', marginTop: "10px" }} onClick={() => { verifyEmail() }}>Resend email</Button>
                </Box>
              )}

              <p>or</p>

              <Grid container rowSpacing={1} columnSpacing={1} >
                {/* <Grid item xs={6} sm={4} md={3} >

                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#0270E8", '&.MuiButton-root:hover':{bgcolor: '#2f80ec'}  }}
                    startIcon={<FacebookIcon />}
                    fullWidth
                    href={process.env.REACT_APP_API_URL + "/auth/facebook"}
                  >

                    <span style={{ color: "#FFFFFF" }}>Facebook</span>
                  </Button>

                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                  <Button
                    variant="contained"
                    sx={{ backgroundColor: "#0A66C2" , '&.MuiButton-root:hover':{bgcolor: '#2f76c9'} }}
                    startIcon={<LinkedInIcon />}
                    fullWidth
                    href={process.env.REACT_APP_API_URL + "/auth/linkedin"}
                  >

                    <span style={{ color: "#FFFFFF" }}>LinkedIn</span>
                  </Button>
                </Grid>
                <Grid item xs={6} sm={4} md={3}>
                  <Button fullWidth variant="contained" sx={{ backgroundColor: "#000" , '&.MuiButton-root:hover':{bgcolor: '#161616'}}} startIcon={<SvgIcon >
                    <svg width="23px" height="24px" viewBox="0 0 23 23" version="1.1">
                      <g id="surface1">
                        <path d="M 13.6875 9.734375 L 22.25 0 L 20.222656 0 L 12.785156 8.453125 L 6.847656 0 L 0 0 L 8.980469 12.78125 L 0 22.988281 L 2.027344 22.988281 L 9.878906 14.0625 L 16.152344 22.988281 L 23 22.988281 Z M 10.910156 12.894531 L 10 11.621094 L 2.761719 1.492188 L 5.875 1.492188 L 11.71875 9.667969 L 12.628906 10.9375 L 20.222656 21.5625 L 17.105469 21.5625 Z M 10.910156 12.894531 " />
                      </g>
                    </svg>
                  </SvgIcon>} href={process.env.REACT_APP_API_URL + "/auth/twitter"}><span style={{ color: "#FFFFFF" }} >Twitter</span></Button>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12}>
                  <Button
                    className="social-google"
                    variant="contained"
                    startIcon={<GoogleIcon />}
                    sx={{
                      backgroundColor: "#E62B32",
                      color: "#FFFFFF",
                      '&.MuiButton-root:hover':{bgcolor: '#ec4b48'}
                    }}
                    fullWidth
                    href={process.env.REACT_APP_API_URL + "/auth/google"}
                  >

                    <span style={{ color: "#FFFFFF" }}>Google</span>
                  </Button>
                </Grid>
              </Grid>
            </Box>


          </form>
          <Divider sx={{ marginTop: '50px', marginBottom: '30px' }} />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginBottom: '100px' }}>

            <Typography variant="body1">New User ?</Typography>
            <Link href="/signup" sx={{ fontSize: '1.6rem' }}>Sign Up</Link>

          </Stack>
        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={6}>
          <Box
            component="img" sx={{ width: '90%', }} src={heroimg} alt="Hero"
          />

        </Grid>

      </Grid>
    </Container>

  );
};

export default Login;
