export const PFIFormConstants = {
    "personal_details": {
        "audio_mp3_path": "/audio/personal_details.mp3",
        "audio_ogg_path": "/audio/personal_details.ogg",
        "details": {
            "college": {
                "default": "No",
            },
            "own_home": {
                "default": "Yes",
            },
            "add_properties": {
                "default": "No",
            },
            "business": {
                "default": "No",
            },
            "major_pur": {
                "default": "No",
            },
            "income": {
                "default": 10000,
            },
            "investment": {
                "default": 10000,
            },
        }
    },
    "nw": {
        "audio_mp3_path": "/audio/net_worth.mp3",
        "audio_ogg_path": "/audio/net_worth.ogg",
        "lifestyle_assets": {
            "primary_home": {
                "default": 200000,
                "min": 0,
                "max": 10000000,
                "tooltip": "One of the websites to get an estimate of home value is http://www.zillow.com"
            },
            "car_and_others": {
                "default": 50000,
                "min": 0,
                "max": 100000,
                "tooltip": ""
            },
            "personal_valuables": {
                "default": 100000,
                "min": 0,
                "max": 4000000,
            },
            "any_other": {
                "default": 50000,
                "min": 0,
                "max": 10000000,
                "tooltip": "This may include anticipated inheritance (near term), your portion of properties shared with others, etc."
            },
            "any_other_desc": {
                "default": "",
                "tooltip": ""
            },

        },
        "savings_and_nvestments": {
            "total": {
                "default": 100000,
                "min": 0,
                "max": 20000000,
                "tooltip": "This is total savings and investments."
            },
            "cash": {
                "default": 100000,
                "min": 0,
                "max": 20000000,
                "tooltip": "This includes savings in checking, savings, CD, and money market accounts."
            },
            "retirement_accounts": {
                "default": 500000,
                "min": 0,
                "max": 20000000,
                "tooltip": "These accounts include 401(k), 403(b), and different types of IRA accounts."
            },
            "brokerage_accounts": {
                "default": 500000,
                "min": 0,
                "max": 100000000,
                "tooltip": "This may include brokerage accounts for after tax retirement savings, college savings, savings for major purchases, investment component of insurance policies, etc."
            },
        },
        "liabilities": {
            "is_mortgage_primary_home": {
                "default": "No",
            },
            "interest_rate":{
                "default": 5,
                "min": 0,
                "max": 20,
            },
            "years_left":{
                "default": 5,
                "min": 0,
                "max": 30,
            },
            "mortgage_type": {
                "default": "Adjustable",
            },
            "years_total":{
                "default": '15'
            },
            "primary_home": {
                "default": 500000,
                "min": 0,
                "max": 5000000,
            },
            "car_and_others": {
                "default": 50000,
                "min": 0,
                "max": 1000000,
            },
            "credit_card_loan": {
                "default": 50000,
                "min": 0,
                "max": 500000,
            },
            "any_other": {
                "default": 0,
                "min": 0,
                "max": 1000000,
                "tooltip": "This may include anticipated inheritance (near term), your portion of properties shared with others, etc."
            },
            "is_other_loan": {
                "default": "No",
            },
            "any_other_desc": {
                "default": "",
                "tooltip": ""
            },
        },
    },
    "cash_management": {
        "audio_mp3_path": "/audio/cash_management.mp3",
        "audio_ogg_path": "/audio/cash_management.ogg",
        "income": {
            "salary_bonus": {
                "default": 49999,
                "min": 0,
                "max": 20000000,
                "tooltip": "For couples salary/bonus information may be combined.",
                "period": {
                    "default": "Annual",
                    "options": ["Monthly", "Annual"]
                }
            },
            "salary_period": {
                "default": 'Annual',
                "options": ["Monthly", "Annual"]
            },

            "rental": {
                "default": 0,
                "min": 0,
                "max": 1000000,
                "tooltip": "",
                "period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                }
            },
            "rental_period": {
                "default": "Monthly",
                "options": ["Monthly", "Annual"]
            },
            "any_other_period": {
                "default": "Monthly",
                "options": ["Monthly", "Annual"]
            },
            "any_other": {
                "default": 0,
                "min": 0,
                "max": 100000,
                "tooltip": "This may include Social Security benefits, pensions, income from a trust, etc."
            },
            "any_other_desc": {
                "default": '',
                "tooltip": ""
            },            

        },
        "expenses": {
            "property_related": {
                "home_mortgage": {
                    "default": 10000,
                    "min": 0,
                    "max": 100000,
                    "tooltip": "This includes principal and interest payments.",
                    "period": {
                        "default": "Monthly",
                        "options": ["Monthly", "Annual"]
                    }
    
                },
                "home_mortgage_period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },
                "tax": {
                    "default": 0,
                    "min": 0,
                    "max": 100000,
                },
                "loan": {
                    "default": 5000,
                    "min": 0,
                    "max": 50000,
                    "period": {
                        "default": "Monthly",
                        "options": ["Monthly", "Annual"]
                    }
    
                },
                "loan_period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },
                "any_other": {
                    "default": 2000,
                    "min": 0,
                    "max": 10000,
                    "period": {
                        "default": "Monthly",
                        "options": ["Monthly", "Annual"]
                    }

                },
                "any_other_period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },
                "make_timely_payment": {
                    "default": "Yes",
                    "options": ["Yes", "Most Times", "Sometimes", "No"]
                },
    
            },
            "living": {
                "life": {
                    "default": 12000,
                    "min": 0,
                    "max": 5000000,
                    "period": {
                        "default": "Monthly",
                        "options": ["Monthly", "Annual"]
                    },

                },
                "life_period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },
                "health_insurance": {
                    "default": 2000,
                    "min": 0,
                    "max": 5000,
                    "period": {
                        "default": "Monthly",
                        "options": ["Monthly", "Annual"]
                    },

                },
                "health_insurance_period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },
                "travel": {
                    "default": 0,
                    "min": 0,
                    "max": 50000,
                    "period": {
                        "default": "Monthly",
                        "options": ["Monthly", "Annual"]
                    },

                },
                "travel_period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },

            },
            "other": {
                "taxes": {
                    "default": 100000,
                    "min": 0,
                    "max": 1000000,
                },
                "any_other": {
                    "default": 0,
                    "min": 0,
                    "max": 100000,
                    "tooltip": "These are miscellaneous expenses e.g. club memberships, subscriptions, cash donations, birthday/holiday gifts, etc."
                },
                "any_other_desc": {
                    "default": '',
                    "tooltip": ""
                },

            },
        }
    },
    "rt": {
        "audio_mp3_path": "/audio/retirement.mp3",
        "audio_ogg_path": "/audio/retirement.ogg",
        "years_to_retire": {
            "default": 10,
            "min": 0,
            "max": 40,
            "tooltip": "Couples should base the 'Number of years left to retire' and 'Expected duration of retirement' based on the first to retire."
        },
        "retirement_duration": {
            "default": 25,
            "min": 0,
            "max": 50,
        },
        "living_expenses": {
            "housing": {
                "default": 5000,
                "min": 0,
                "max": 400000,
                "tooltip": "This is the estimate of your housing expenses including property taxes if you were to retire today. For renters, this would be the monthly rent plus any associated monthly fees.",
                "period": {
                    "default": "Monthly",
                    "options": ["Monthly", "Annual"]
                },

            },
            "housing_period": {
                "default": "Monthly",
                "options": ["Monthly", "Annual"]
            },
            "healthcare_expenses": {
                "default": 0,
                    "min": 0,
                    "max": 1000000,
            },
            "life_expenses": {
                "default": 0,
                    "min": 0,
                    "max": 1000000,
            },
            "t_e_expenses":{
                "default": 0,
                    "min": 0,
                    "max": 1000000,
            },
            "other_expenses":{
                "default": 0,
                "min": 0,
                "max": 1000000,
            }
        },
        "liabilities": {
            "mortgage": {
                "default": 1000000,
                "min": 0,
                "max": 5000000,
            },
            "loans": {
                "default": 800000,
                "min": 0,
                "max": 4000000,
            },
            "legacy": {
                "default": 1000000,
                "min": 0,
                "max": 10000000,
                "tooltip": "Amount to be left for loved ones at the time of your death. This amount is not in today's dollars."
            },

        },
        "savings": {
            "retirement_accounts":{
                "default": 100000,
                "min": 0,
                "max": 10000000,
            },
            "employer_contribution": {
                "default": 1000,
                "min": 0,
                "max": 40000,
            },
            "your_contribution": {
                "default": 1000,
                "min": 0,
                "max": 40000,
            },
        },
        "income": {
            "pension": {
                "default": 0,
                "min": 0,
                "max": 40000,
            },
            "social_sec": {
                "default": 1000,
                "min": 0,
                "max": 10000,
            },
        },
        "economic_factors": {
            "inflation_rate": {
                "default": 3,
                "min": 0,
                "max": 20,
                "tooltip": "The historical annual average rate of inflation since the 1920s has been 3%."
            },
            "annual_return": {
                "default": 7,
                "min": 0,
                "max": 20,
                "tooltip": "For a long term investment (10 years or more), an aggressive investor might choose 10%, a moderate investor might choose 8%, and a conservative investor might choose 6%."
            },
        },

    },
    "ce": {
        "audio_mp3_path": "/audio/dependent_protection.mp3",
        "audio_ogg_path": "/audio/dependent_protection.ogg",
        "num_children": {
            "default": 1,
            "min": 0,
            "max": 10,
            "tooltip": ""
        },
        "cur_cost_of_college": {
            "default": 5000,
            "min": 0,
            "max": 50000,
            "tooltip": ""
        },
        "exp_tuition_inflation": {
            "default": 1,
            "min": 0,
            "max": 7,
            "tooltip": ""
        },

    },

    "mp": {
        "audio_mp3_path": "/audio/major_purchases.mp3",
        "audio_ogg_path": "/audio/major_purchases.ogg",
        "number": {
            "default": 0,
            "min": 0,
            "max": 10,
        },
        "years_purchase": {
            "default": 5,
            "min": 0,
            "max": 50,
        },
        "amount_needed": {
            "default": 100000,
            "min": 0,
            "max": 500000,
        },
        "amount_saved": {
            "default": 20000,
            "min": 0,
            "max": 100000,
        },
        "monthly_savings": {
            "default": 500,
            "min": 0,
            "max": 5000,
        },
        "annual_return": {
            "default": 2,
            "min": 0,
            "max": 20,
            "tooltip": "For a long term investment (10 years or more), an aggressive investor might choose 10%, a moderate investor might choose 8%, and a conservative investor might choose 6%."
        },

    },
    "dependent_protection": {
        "audio_mp3_path": "/audio/dependent_protection.mp3",
        "audio_ogg_path": "/audio/dependent_protection.ogg",
        "insurance": {
            "onetime_expenses": {
                "default": 500000,
                "min": 0,
                "max": 4000000,
            },
            "years_to_support": {
                "default": 20,
                "min": 0,
                "max": 40,
                "tooltip": "Normally it could be ( 55 - your age) years. It is assumed that by the time you are 55, your kids will not be dependent on you."
            },
            "average_annual_return":{
                "default": 0,
                "min":0,
                "max": 1000000
            },
            "annual_inflation_rate":{
                "default":1,
                "min":0,
                "max":7
            },
            "self_life_insurance_type": {
                "default": "Whole",
                "options": ["Whole", "Term"]
            },

            "self_life_insurance": {
                "default": 1000000,
                "min": 0,
                "max": 10000000,
            },
            "employer_life_insurance": {
                "default": 250000,
                "min": 0,
                "max": 1000000,
            },
            "have_homeowners_insurance": {
                "default": "Yes",
                "options": ["Yes", "No"]
            },
            "have_health_insurance": {
                "default": "Yes",
                "options": ["Yes", "No"]
            },
            "longterm_disability_insurance": {
                "default": "Yes",
                "options": ["Yes", "No"],
                "tooltip": "Long Term Disability insurance typically starts afer 6 months in disability and provides 70 - 80% wage replacement until retirement age."
            },
            "shortterm_disability_insurance": {
                "default": "No",
                "options": ["Yes", "No"],
                "tooltip": "Short Term Disability insurance typically starts after 10-14 days in disability and provides 80 - 100% wage replacement for 3 to 6 months."
            },
            "umbrella_policy": {
                "default": "No",
                "options": ["Yes", "No"],
                "tooltip": "An umbrella policy protects you in the event someone gets injured at or by your property files a large liability claim against you."
            },
            "poa": {
                "default": "Yes",
                "options": ["Yes", "No"],
                "tooltip": "This allows someone else to act on your behalf in legal and business matters in case you are ever incapacitated."
            },
            "revocable_trust": {
                "default": "Yes",
                "options": ["Yes", "No"],
                "tooltip": "This allows you to transfer your property to a beneficiary while also retaining the right to change the beneficiary at any time."
            },
            "lt_care_ins": {
                "default": "Yes",
                "options": ["Yes", "No"],
                "tooltip": "This insurance provides coverage for health care expenses not covered by Medicare during retirement."
            },
            "will": {
                "default": "Yes",
                "options": ["Yes", "No"],
                "tooltip": "This document allows you to establish an orderly transfer of your property and caretaking of your minor children per your desire in case of your sudden death."
            },
            "living_will": {
                "default": "Yes",
                "options": ["Yes", "No"],
                "tooltip": "A Living Will is a legal document that describes whether or not you want life sustaining treatment in case you become terminally ill."
            },

        }
    },
    "financial_health": {
        "audio_mp3_path": "/audio/financial_health.mp3",
        "audio_ogg_path": "/audio/financial_health.ogg",
        "sample_report": "/pdf/sample_report.pdf"
    },

}