import React, { useEffect, useState } from "react";
import "./Home.css";
import HeroSection from "../../components/hero/HeroSection";
import Personalfinancial from "../../components/personalfinancial/Personalfinancial";
import OpenModal from "../../components/modal/OpenModal";
import FooterImg from "../../components/footerimg/FooterImg";
import loadingImg from "../../assets/images/loader.gif";
import circle from "../../assets/images/hero-circle.png";
import { Container, Box } from "@mui/material";
import { Link } from "react-router-dom";

import heroImage from "../../assets/images/hero-img.png";

const Home = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <section className="Home">
        {loading && (
          <div className="loader">
            <img src={loadingImg} alt="loading" />
          </div>
        )}
        <HeroSection title="A complete financial health checkup!" heroImage={heroImage} description="Check your proficiency in managing your personal finances. Personal Financial Scores (PFScores) is a comprehensive financial benchmark for individuals and families." />
        {/* <Container maxWidth="lg" className="hero-section">
        <Box className="row hero--second--row">
          <Box className="col hero--second--img">
            <img src={circle} className="circle" alt="" />
          </Box>
          <Box className="col hero--sec--right ">
            <h4 className="sub-tit">
              Just 15 minutes and you will have the result.
            </h4>
            <p>
              However, you need not complete it in one go as your information is
              automatically saved for future use.
            </p>
            <button className="show-detail">
              <Link to={"/"}>Show details</Link>
            </button>
          </Box>
        </Box>
        </Container> */}
        <Personalfinancial />
        <FooterImg />
        {/*<OpenModal />*/}
      </section>
    </>
  );
};

export default Home;
