import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
// createHttpLink
// import { setContext } from '@apollo/client/link/context'

const base_uri = process.env.REACT_APP_API_URL + '/v1/';
console.log(`base_uri: ${base_uri}`);
const httpLink = new HttpLink({ uri: base_uri });
const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from local storage.
  const token = localStorage.getItem("token");

  // Use the setContext method to set the HTTP headers.
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });

  // Call the next link in the middleware chain.
  return forward(operation);
});

const gqlWebClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default gqlWebClient;
