import { useContext, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyPersonalDetails, addOrUpdatePersonalDetails } from '../apiServices/pfscoreForms/personalDetails';

import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

export const usePersonalDetails= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    // const datafromreducer = useSelector((state) => state.PersonalDetailsReducer);
    // const funcitonsformreducer = useDispatch();
  

    const loadPersonalDetails = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyPersonalDetails()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadPersonalDetails Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('personalDetails', response.getMyPersonalDetails);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("Feteched Initial Data for personal details!!!");
            // funcitonsformreducer();
            // funcitonsformreducer({
            //     type: 'ALL',
            //     payload: response.getMyPersonalDetails,
            //   });
            // console.log(`response.getMyPersonalDetails: ${response.getMyPersonalDetails}`);
        }
    }

    const savePersonalDetails = async (updateData) => {
        setIsLoading(true);
        setError(null);
        // age, maritalStatus, planToSaveForCollege, ownHome, ownAdditionalProperties, ownBusiness, planForMajorPurchase, annualHouseholdIncome, valueOfInvestments
        const response = await addOrUpdatePersonalDetails(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("savePersonalDetails Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('personalDetails', response.addOrUpdatePersonalDetails);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved personal details!!!");
        }
        return isSuccess
    }

    return {loadPersonalDetails, savePersonalDetails, isLoading, error}
}