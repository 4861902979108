import React from 'react'
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default function BackLink(props) {
    const { sx } = props;
    const navigate = useNavigate();
    const StyledButton = styled(Button)({
        textTransform: "capitalize",
        textDecoration: "underline",
        fontSize: "1.6rem",
        lineHeight: "24px",
        padding: 0,
        minWidth: "auto",
        marginTop: 80,
        marginBottom: 80
    })
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: { xs: 'center', sm: 'left' },
            }}
        >
            <StyledButton
                onClick={() => navigate(-1)}
                color="inherit"
                sx={sx}
            >
                Back
            </StyledButton>
        </Box>
    )
}
