import "./App.css";
import { useEffect } from "react";
// import Header from "./layout/header/Header";
// import Header2 from "./layout/header/Header2";
import Header3 from "./layout/header/Header3";

import Footer2 from "./layout/footer/Footer2";
import Routing from "./routes/Routing";
import { useLocation } from "react-router-dom";
// useSelector,
import { useDispatch } from "react-redux";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';

function App() {
  const location = useLocation();
  const reducerFun = useDispatch();
  useEffect(() => {
    const handleWindowResize = () => {
      reducerFun({
        type: "screenWidth",
        payload: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });
  useEffect(() => {
    const searchParams = new  URLSearchParams(location.search)
    const searchQuerySource = searchParams.get('utm_source');
    const searchQueryMedium = searchParams.get('utm_medium')
    const searchQueryCampaign = searchParams.get('utm_campaign')
    const searchQueryKeyword = searchParams.get('utm_keyword') 
    const searchQueryReferrer  = document.referrer    
    if(searchQuerySource){
      localStorage.setItem('utm_source', searchQuerySource)
    }
    if(searchQueryMedium){
      localStorage.setItem('utm_medium', searchQueryMedium)
    }
    if(searchQueryCampaign){
      localStorage.setItem('utm_campaign', searchQueryCampaign)
    }
    if(searchQueryKeyword){
      localStorage.setItem('utm_keyword', searchQueryKeyword)
    }
    if(searchQueryReferrer){
      localStorage.setItem('referrer',searchQueryReferrer)
    }

  }, [location.search])

  // const datafromreducer = useSelector((state) => state.ScoreReducer);
  // const debugInfo = datafromreducer.debugInfo;
  // const app_environ = process.env.REACT_APP_ENV;

  let theme = createTheme({
    typography: {
      htmlFontSize: 10,
      fontFamily: [
        'Inter',
        'sans-serif'
      ].join(','),
      h1:{
        fontSize: '5.6rem',       
        fontWeight: 500,
        color: '#102691',
        lineHeight: '6.6rem',
        marginBottom: 32        
      },
      h2:{
        fontSize: '4.0rem',       
        fontWeight: 600,
        background: 'linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%)',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        lineHeight: 'normal',
        marginBottom: 32        
      },
      h3:{
        fontSize: '2.3rem',
        fontWeight: 500,
        color: '#00072C',
        lineHeight: 'normal',
        marginBottom: 16,
        marginTop: 40
      },
      h4:{
        fontSize: '1.8rem',
        fontWeight: 600,
        color: '#000',
        lineHeight: '28px'
      },
      h6:{
        fontSize: '1.6rem',
        fontWeight: 600,
        color: '#00072C',
        lineHeight: '24px'
      },
      body1:{
        fontSize: '1.6rem',
        fontWeight: 400,
        color: '#000',
        lineHeight: '2.6rem',
      },
      button: {
        textTransform: 'none'
      }
      // body2:{
      //   fontSize: '1.6rem',
      //   fontWeight: 400,
      //   background: 'linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%)',
      //   WebkitBackgroundClip: 'text',
      //   WebkitTextFillColor: 'transparent',
      //   lineHeight: '2.6rem',
      // }
    },
  });
  theme = createTheme(theme, {
    typography: {     
      h1:{       
        [theme.breakpoints.down('lg')]: {
          fontSize: '5.0rem',
          lineHeight: '6.0rem',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '3.6rem',
          lineHeight: '4.6rem',
        },              
      },
      h2:{
        [theme.breakpoints.down('lg')]: {
          fontSize: '3.0rem',
        },
      }
    },
    components:{
      MuiTextField: {
        styleOverrides: {
          root: {
            '& label': {
              color: '#00072C',
            },
            '& label.Mui-focused': {
              color: '#929292',
            },
          },
        },
      },
      MuiOutlinedInput:{
        styleOverrides:{
          notchedOutline: {
            borderColor: '#D9D9D9',
          },
          root:{
            borderRadius: 10,
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: '#D9D9D9',
              borderWidth: 1
            },
          }
        }
      },
      MuiFormControlLabel:{
        styleOverrides:{
         
            label:{
              color: '#00072C',
              fontSize: '1.4rem'
            }
          
         
        }
      },
      MuiLink:{
        styleOverrides:{
          root:{
            fontFamily:'Inter,sans-serif',
            fontSize: '1.4rem',
            color: '#00072C',
            fontWeight: 400
          }
        }
      },
      MuiButton: {
        defaultProps: {
          disableElevation: true,
          size: 'large'
        },
        styleOverrides:{
          root:{
            paddingTop:'15px', 
            borderRadius:'10px', 
            paddingBottom:'15px',
            [theme.breakpoints.down('lg')]: {
              paddingTop:'12px', 
              paddingBottom:'12px',
            },           
          }
        }
      }
    }
  });

  return (
   <ThemeProvider theme={theme}>
    <div className="App">
      {/* {location.pathname === "/signup" ||
      location.pathname === "/login" ? null : (
        <Header3 />
      )} */}
      <Header3 />
      <main className="main--content">
        <Routing />
        {/* {app_environ === "qa" ? (
          <>
            <h2>DEBUG INFO:</h2>
            <p>{debugInfo}</p>
          </>
        ) : (
          ""
        )} */}
      </main>
      {/* {location.pathname === "/signup" ||
      location.pathname === "/login" ? null : (
        <Footer2 />
      )} */}
      <Footer2 />
    </div>
    </ThemeProvider>
  );
}

export default App;
