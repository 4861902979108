import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyScore = async function () {
  console.log("Inside getMyScore()");
  const { data } = await gqlWebClient.query({
    query: gql`
      {
        getMyScore {
          code
          message
          http_status
          data {
            personalDetails {
              id
            }
            netWorth {
              id
            }
            cashManagement {
              id
            }
            retirement {
              id
            }
            education {
              id
            }
            majorPurchases {
              id
            }
            dependentProtection {
              id
            }

            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors
        }
      }
    `,
    fetchPolicy: "no-cache" 
  });
  console.log("getMyScore API Response is: ", data);
  return data;
};

export const getMyScorePdfUrl = async function () {
  console.log("Inside getMyScorePdfUrl()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyScorePdfUrl {
          downloadURL
          filename
        }
      }
    `,
  });
  console.log("getMyScorePdfUrl API Response is: ", data);
  return data;
};
