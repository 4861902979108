import React from "react";
import { Container, Box, Button, Typography } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePassword } from '../../../hooks/usePassword';
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import heroimg from "../../../assets/images/login.png";

const ForgotPassword = () => {
  const { forgotPassword, isLoading, error, isSuccess } = usePassword();
  // const [isRecoveryEmailSent, setIsRecoveryEmailSent] = useState(false);


  const validationSchema = Yup.object({
    email: Yup.string('Enter your email')
      .email('Invalid email address')
      .max(255)
      .required('Email is required.'),

  })

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      const authResponse = await forgotPassword(values['email'])
      if (authResponse) {
        console.log('authResponse: ', authResponse);
      } else {
        console.log("Recovering forgot password failed!");
      }
    },
  });

  return (

    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Grid container   >
        <Grid item xs={12} md={6}>
          <Typography variant="h1">Recover Password</Typography>

          <form onSubmit={formik.handleSubmit}>
            <Box className="sigup_form">


              <TextField
                fullWidth
                id="email"
                name="email"
                placeholder="Enter your email address"
                label="Email"
                type="email"
                variant="outlined"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                margin="normal"
              />


              <Box>
                {
                  isSuccess ?
                    <Typography variant="body1">Email sent with the instructions to reset your password. Follow the instructions in the email to recover your password.</Typography>
                    : ''
                }
                {
                  error ?
                    <Typography variant="body1">Error: {error}.</Typography>
                    : ''
                }

              </Box>
              <Box className="buttons_section">
                <Button className="sign_up_button" disabled={isLoading ? true : false} type="submit" fullWidth endIcon={<ArrowForwardIcon />}
                  sx={{ marginTop: '40px', color: '#102691', backgroundImage: 'linear-gradient(267deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)' }}>
                  Submit
                </Button>

              </Box>

            </Box>
          </form>
          <Divider sx={{ marginTop: '50px', marginBottom: '30px' }} />
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginBottom: '10px' }}>

            <Typography variant="body1">Remembered your password?</Typography>
            <Link href="/login" sx={{ fontSize: '1.6rem' }}>SignIn</Link>

          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginBottom: '100px' }}>

            <Typography variant="body1">Didn't have an account?</Typography>
            <Link href="/signup" sx={{ fontSize: '1.6rem' }}>SignUp</Link>

          </Stack>


        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={6}>
          <Box
            component="img" sx={{ width: '90%', }} src={heroimg} alt="Hero"
          />

        </Grid>

      </Grid>
    </Container>


  );
};

export default ForgotPassword;
