import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyRetirement = async function () {
  console.log("Inside getMyRetirement()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyRetirement {
          code
          message
          http_status
          data {
            id
            number_of_years_left_to_retire
            expected_duration_of_retirement
            housing_expenses_period
            housing_expenses_amount
            healthcare_expenses
            life_expenses
            te_expenses
            other_expenses
            expected_mortgage_at_retirement
            other_expected_loans_at_retirement
            legacy_you_want_to_leave
            all_retirement_accounts_value
            average_monthly_contribution_by_employer
            monthly_contribution
            estimated_monthly_pension_benefit
            estimated_monthly_soc_sec_benefit
            expected_avg_inflation_rate
            expected_avg_annual_return_retirement_balance

            added_on
          }
          score {
            overAllScore
            debtScore
            savingsScore
            riskScore
          }
          errors {
            number_of_years_left_to_retire
            expected_duration_of_retirement
            housing_expenses_period
            housing_expenses_amount
            healthcare_expenses
            life_expenses
            te_expenses
            other_expenses
            expected_mortgage_at_retirement
            other_expected_loans_at_retirement
            legacy_you_want_to_leave
            all_retirement_accounts_value
            average_monthly_contribution_by_employer
            monthly_contribution
            estimated_monthly_pension_benefit
            estimated_monthly_soc_sec_benefit
            expected_avg_inflation_rate
            expected_avg_annual_return_retirement_balance
          }
        }
      }
    `,
    fetchPolicy: "no-cache"
  });
  console.log("getMyRetirement API Response is: ", data);
  return data;
};

export const addOrUpdateMyRetirement = async function (
  numberOfYearsLeftToRetire,
  expectedDurationOfRetirement,
  housingExpensesPeriod,
  housingExpensesAmount,
  healthcareExpenses,
  lifeExpenses,
  teExpenses,
  otherExpenses,
  expectedMortgageAtRetirement,
  otherExpectedLoansAtRetirement,
  legacyYouWantToLeave,
  allRetirementAccountsValue,
  averageMonthlyContributionByEmployer,
  monthlyContribution,
  estimatedMonthlyPensionBenefit,
  estimatedMonthlySocSecBenefit,
  expectedAvgInflationRate,
  expectedAvgAnnualReturnRetirementBalance
) {
  console.log(`numberOfYearsLeftToRetire: ${numberOfYearsLeftToRetire}`);
  console.log(`expectedDurationOfRetirement: ${expectedDurationOfRetirement}`);
  console.log(`housingExpensesPeriod: ${housingExpensesPeriod}`);
  console.log(`housingExpensesAmount: ${housingExpensesAmount}`);
  console.log(`expectedMortgageAtRetirement: ${expectedMortgageAtRetirement}`);
  console.log(
    `otherExpectedLoansAtRetirement: ${otherExpectedLoansAtRetirement}`
  );
  console.log(`legacyYouWantToLeave: ${legacyYouWantToLeave}`);
  console.log(
    `averageMonthlyContributionByEmployer: ${averageMonthlyContributionByEmployer}`
  );
  console.log(`monthlyContribution: ${monthlyContribution}`);
  console.log(
    `estimatedMonthlyPensionBenefit: ${estimatedMonthlyPensionBenefit}`
  );
  console.log(
    `estimatedMonthlySocSecBenefit: ${estimatedMonthlySocSecBenefit}`
  );
  console.log(`expectedAvgInflationRate: ${expectedAvgInflationRate}`);
  console.log(
    `expectedAvgAnnualReturnRetirementBalance: ${expectedAvgAnnualReturnRetirementBalance}`
  );

  console.log("Inside addOrUpdateMyRetirement()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addOrUpdateMyRetirement(
        $numberOfYearsLeftToRetire: Int!
        $expectedDurationOfRetirement: Int!
        $housingExpensesPeriod: Period!
        $housingExpensesAmount: Int!
        $healthcareExpenses: Int!
        $lifeExpenses: Int!
        $teExpenses: Int!
        $otherExpenses: Int!
        $expectedMortgageAtRetirement: Int!
        $otherExpectedLoansAtRetirement: Int!
        $legacyYouWantToLeave: Int!
        $allRetirementAccountsValue: Int!
        $averageMonthlyContributionByEmployer: Int!
        $monthlyContribution: Int!
        $estimatedMonthlyPensionBenefit: Int!
        $estimatedMonthlySocSecBenefit: Int!
        $expectedAvgInflationRate: Int!
        $expectedAvgAnnualReturnRetirementBalance: Int!
      ) {
        addOrUpdateMyRetirement(
          data: {
            number_of_years_left_to_retire: $numberOfYearsLeftToRetire
            expected_duration_of_retirement: $expectedDurationOfRetirement
            housing_expenses_period: $housingExpensesPeriod
            housing_expenses_amount: $housingExpensesAmount
            healthcare_expenses: $healthcareExpenses
            life_expenses: $lifeExpenses
            te_expenses: $teExpenses
            other_expenses: $otherExpenses
            expected_mortgage_at_retirement: $expectedMortgageAtRetirement
            other_expected_loans_at_retirement: $otherExpectedLoansAtRetirement
            legacy_you_want_to_leave: $legacyYouWantToLeave
            all_retirement_accounts_value: $allRetirementAccountsValue
            average_monthly_contribution_by_employer: $averageMonthlyContributionByEmployer
            monthly_contribution: $monthlyContribution
            estimated_monthly_pension_benefit: $estimatedMonthlyPensionBenefit
            estimated_monthly_soc_sec_benefit: $estimatedMonthlySocSecBenefit
            expected_avg_inflation_rate: $expectedAvgInflationRate
            expected_avg_annual_return_retirement_balance: $expectedAvgAnnualReturnRetirementBalance
          }
        ) {
          code
          message
          http_status
          data {
            id

            number_of_years_left_to_retire
            expected_duration_of_retirement
            housing_expenses_period
            housing_expenses_amount
            healthcare_expenses
            life_expenses
            te_expenses
            other_expenses
            expected_mortgage_at_retirement
            other_expected_loans_at_retirement
            legacy_you_want_to_leave
            all_retirement_accounts_value
            average_monthly_contribution_by_employer
            monthly_contribution
            estimated_monthly_pension_benefit
            estimated_monthly_soc_sec_benefit
            expected_avg_inflation_rate
            expected_avg_annual_return_retirement_balance

            added_on
          }
          score {
            overAllScore
            debtScore
            savingsScore
            riskScore
          }
          errors {
            number_of_years_left_to_retire
            expected_duration_of_retirement
            housing_expenses_period
            housing_expenses_amount
            healthcare_expenses
            life_expenses
            te_expenses
            other_expenses
            expected_mortgage_at_retirement
            other_expected_loans_at_retirement
            legacy_you_want_to_leave
            all_retirement_accounts_value
            average_monthly_contribution_by_employer
            monthly_contribution
            estimated_monthly_pension_benefit
            estimated_monthly_soc_sec_benefit
            expected_avg_inflation_rate
            expected_avg_annual_return_retirement_balance
          }
        }
      }
    `,
    variables: {
      numberOfYearsLeftToRetire: numberOfYearsLeftToRetire,
      expectedDurationOfRetirement: expectedDurationOfRetirement,
      housingExpensesPeriod: housingExpensesPeriod,
      housingExpensesAmount: housingExpensesAmount,
      healthcareExpenses: healthcareExpenses,
      lifeExpenses: lifeExpenses,
      teExpenses: teExpenses,
      otherExpenses: otherExpenses,
      expectedMortgageAtRetirement: expectedMortgageAtRetirement,
      otherExpectedLoansAtRetirement: otherExpectedLoansAtRetirement,
      legacyYouWantToLeave: legacyYouWantToLeave,
      allRetirementAccountsValue: allRetirementAccountsValue,
      averageMonthlyContributionByEmployer: averageMonthlyContributionByEmployer,
      monthlyContribution: monthlyContribution,
      estimatedMonthlyPensionBenefit: estimatedMonthlyPensionBenefit,
      estimatedMonthlySocSecBenefit: estimatedMonthlySocSecBenefit,
      expectedAvgInflationRate: expectedAvgInflationRate,
      expectedAvgAnnualReturnRetirementBalance:
        expectedAvgAnnualReturnRetirementBalance,
    },
  });

  console.log("addOrUpdateMyRetirement API Response is: ", data);
  return data;
};
