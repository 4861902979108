import React from "react";
import Box from "@mui/material/Box";
import "./NumInput.css";
const NumInput = (props) => {
    const { placeholder, name, onChange, value, onPlusClick, onMinusClick, displayCurrency,displayPercentage, step = 1 } = props;
    return (
        <>
            <Box className={displayCurrency ? "number currencyNumber" : (displayPercentage ? "number currencyNumber": "number" ) } sx={{margin:{xs:"32px auto 0 auto", sm:"0px"}}}>
                <span className="minus" onClick={onMinusClick} >
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" style={{ marginTop: "13px" }}>
                        <path d="M12.25 5.6875H1.75C1.26684 5.6875 0.875 6.07934 0.875 6.5625V7.4375C0.875 7.92066 1.26684 8.3125 1.75 8.3125H12.25C12.7332 8.3125 13.125 7.92066 13.125 7.4375V6.5625C13.125 6.07934 12.7332 5.6875 12.25 5.6875Z" fill="white" />
                    </svg>
                </span>
                {displayCurrency ?
                    <span className="currency" ><p className="currencySign">$</p></span>
                    : <></>
                }
                {displayPercentage ?
                    <span className="currency" ><p className="currencySign">%</p></span>
                    : <></>
                }

                <input
                    placeholder={placeholder}
                    type="number"
                    value={value}
                    onChange={onChange}
                    autoFocus={false}
                    disableunderline={"true"}
                    name={name}
                    fullwidth={"true"}
                    step={step}
                />
                <span className="plus" onClick={onPlusClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none" style={{ marginTop: "15.5px" }}>
  <path d="M10.2143 4.32143H6.67857V0.785714C6.67857 0.351853 6.32672 0 5.89286 0H5.10714C4.67328 0 4.32143 0.351853 4.32143 0.785714V4.32143H0.785714C0.351853 4.32143 0 4.67328 0 5.10714V5.89286C0 6.32672 0.351853 6.67857 0.785714 6.67857H4.32143V10.2143C4.32143 10.6481 4.67328 11 5.10714 11H5.89286C6.32672 11 6.67857 10.6481 6.67857 10.2143V6.67857H10.2143C10.6481 6.67857 11 6.32672 11 5.89286V5.10714C11 4.67328 10.6481 4.32143 10.2143 4.32143Z" fill="white"/>
</svg>
                </span>
            </Box>
        </>
    );
};

export default NumInput;