import { Box } from "@mui/material";
import React from "react";
import FooterImg from "../../../components/footerimg/FooterImg";

import VerticalLinearStepper from "../../../components/steppers/Steppers";
import "./ScoringDetails.css";
import { getStepNo }  from "../../../constants/ScoringDetailsSteps"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

const ScoringDetails = () => {
  let { step } = useParams();
  const stepNo = getStepNo(step);

  return (
    <>
      <div className="form_container">
        <Box className="main_form_wrapper">
          <VerticalLinearStepper step={stepNo} />
        </Box>
        <FooterImg />
      </div>
    </>
  );
};

export default ScoringDetails;