import React from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "../../pages/authPages/signup/SignUp.css";
import "../../assets/css/style.css";
import signUpLogo from "../../assets/images/signupLogo.png";
import pills from "../../assets/images/pills.png";
import email from "../../assets/images/email.png";
import phone from "../../assets/images/phone.png";
import fax from "../../assets/images/fax.png";

import { SiteConstants } from '../../constants/SiteConstants';

const AuthRightSection = (props) => {
    //   const { title1 } = props;
    return (
        <>

            <Box className="right_section_signUp ">
                <Box className="prscrore_sign_ip">
                    <Link to="/">
                        <img src={signUpLogo} alt="logo" />
                    </Link>
                    <img src={pills} alt="pills" className="pills" />
                </Box>
                {/* <Box className="tech_support_section">
                    <Box className="tech_heading ">
                        <Box className="tech_inner_values">
                            <span className="dots__"></span>
                            <h4>Techinical Support</h4>
                        </Box>
                        <p>For fast service,use online chat.</p>
                    </Box>
                    <Box className="contact__signup">
                        <Box className="email">
                            <Box className="email_img">
                                <img src={email} alt="email" className="" />
                            </Box>
                            <Box className="email_texts">
                                <h6>Email:</h6>
                                <h2>{SiteConstants.technical_support.email}</h2>
                            </Box>
                        </Box>
                        <Box className="email">
                            <Box className="email_img">
                                <img src={phone} alt="email" className="" />
                            </Box>
                            <Box className="email_texts">
                                <h6>phone:</h6>
                                <h2>{SiteConstants.technical_support.phone}</h2>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="contact__signup2 ">
                        <Box className="email">
                            <Box className="email_img">
                                <img src={fax} alt="email" className="" />
                            </Box>
                            <Box className="email_texts">
                                <h6>Fax:</h6>
                                <h2>{SiteConstants.technical_support.fax}</h2>
                            </Box>
                        </Box>
                    </Box>
                </Box> */}
            </Box>

        </>
    );
};

export default AuthRightSection;
