;
  
 const userInitialState = {
    first_name:  '',
    last_name:  '',
    phone:  '',
    zip:  '',
    birth_year:  '0', 
    gender:  'No Selected',
    marital_status:  'No Selected',
    education_level:  'No Selected'
 }
export const UserDetailsReducer = (state = userInitialState, action) => {
    
    switch (action.type) {
        case "FIRST_NAME":
            return {
                ...state,
                first_name: action.payload,
            };
        case "LAST_NAME":
            return {
                ...state,
                last_name: action.payload,
            };
        case "PHONE":
            return {
                ...state,
                phone: action.payload,
            };
        case "ZIP":
            return {
                ...state,
                zip: action.payload,
            };
        case "BIRTH_YEAR":
            return {
                ...state,
                birth_year: action.payload,
            };
        case "GENDER":
            return {
                ...state,
                gender: action.payload,
            };
        case "MARITAL_STATUS":
            return {
                ...state,
                marital_status: action.payload,
            };
        case "EDUCATION_LEVEL":
            return {
                ...state,
                education_level: action.payload,
            };
        case "SET_ALL_USER_DETAILS":
            return {
                ...state,
                first_name: (state.first_name = action.payload.first_name),
                last_name: (state.last_name = action.payload.last_name),
                phone: (state.phone = action.payload.phone),
                zip: (state.zip = action.payload.zip),
                birth_year: (state.birth_year = action.payload.birth_year),
                gender: (state.gender = action.payload.gender),
                marital_status: (state.marital_status = action.payload.marital_status),
                education_level: (state.education_level = action.payload.education_level)
            };
        default:
            return state;
    }
};