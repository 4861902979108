import { useState } from "react";
import { forgotPasswordService, resetForgotPasswordService, changePasswordService } from '../apiServices/auth';

export const usePassword = () => {

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);

    const forgotPassword = async (email) => {
        setIsLoading(true);
        setError(null);
        const response = await forgotPasswordService(email)
        
        console.log("response is:", response);
        let success = true;
        if (response.http_status!==200 && "errors" in response) {
            success = false;
            setError(response.message)
        } else {
            success = true;
        }

        if (!success) {
            setIsLoading(false);
            setIsSuccess(false);
            console.log("Request Forgot Password Errors: ", response['errors'])
        } else {
            console.log("Password recovery email sent!!!");
            setIsLoading(false)
            setIsSuccess(true);
            setError(null)
        }

        return success;
    }

    const resetForgotPassword = async (password, confirmPassword, passwordRecoveryToken) => {
        setIsLoading(true);
        setError(null);
        const response = await resetForgotPasswordService(password, confirmPassword, passwordRecoveryToken)
        
        console.log("response is:", response);
        let success = true;
        if (response.http_status!==200 && "errors" in response) {
            success = false;
            setError(response.message)
        } else {
            success = true;
        }

        if (!success) {
            setIsLoading(false);
            setIsSuccess(false);
            console.log("Reset Forgot Password Errors: ", response['errors'])
        } else {
            console.log("New password set successfully!!!");
            setIsLoading(false)
            setIsSuccess(true);
            setError(null)
        }

        return success;
    }

    const changePassword = async (oldPassword, newPassword, confirmNewPassword) => {
        setIsLoading(true);
        setError(null);
        const response = await changePasswordService(oldPassword, newPassword, confirmNewPassword)
        
        console.log("response is:", response);
        let success = true;
        if (response.http_status!==200 && "errors" in response) {
            success = false;
            setError(response.message)
        } else {
            success = true;
        }

        if (!success) {
            setIsLoading(false);
            setIsSuccess(false);
            console.log("Change Password Errors: ", response['errors'])
        } else {
            console.log("Password changed successfully!!!");
            setIsLoading(false)
            setIsSuccess(true);
            setError(null)
        }

        return success;
    }

    return {resetForgotPassword, forgotPassword, changePassword, isLoading, error, isSuccess}
}