import { Container, Box } from "@mui/material";

import React from "react";
import "./HeroSection.css";
import { Link } from "react-router-dom";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import heroCircle from "../../assets/images/hero---circle.png";
import { useAuthContext } from "../../hooks/useAuthContext"; 

const HeroSection = (props) => {
  const {title, description, heroImage, showAboutLink = true} =  props;
  const { state } = useAuthContext();
  return (
    <>
      <Container maxWidth="lg" className="hero-section">
        <Box className="row hero--first--row">
          <Box className="column hero--section--left">
            <div className="responsive--circle">
              <img src={heroCircle} alt="" className="hero-circle" />
            </div>
            <h3 className="title">
              {title}
            </h3>
            <p>
             {description}
            </p>

            <Box className="hero--sec--btns">
              <button className="btn l">
                {state.user &&(
                  <Link to="/scoring-details/personal-details">
                    Try It Now
                    <ArrowForwardIcon />
                  </Link>
                )}
                {!state.user &&(
                  <Link to="/signup">
                    Try It Now
                  <ArrowForwardIcon />
                </Link>
                )}
              </button>
            { showAboutLink && <button className="btn about-pfi">
                <Link to="/about-us">About PFScores</Link>
              </button>}
              <Box className="about--login--responsive">
                <button className="btn login--btn">
                  <Link to="/login">Login</Link>
                </button>
              </Box>
            </Box>
          </Box>
          <Box className="column hero--section--right">
            <img src={heroImage} alt="hero" />
          </Box>
        </Box>

       
      </Container>
    </>
  );
};

export default HeroSection;
