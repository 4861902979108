const dpInitialState = {
    // Insurance : Your Protection
    ypOnetimeExp: 50000,
    ypYearsToSupport: 20,
    ypAvgAnnualReturn: 7,
    ypAvgInflation: 2,
    ypInsuranceSelfType: "Whole",
    ypInsuranceSelf: 100000,
    ypInsuranceEmployer: 250000,

    ypHaveHomeownersInsurance: "No",
    ypHaveHealthInsurance: "No",
    ypHaveLtDisabilityInsurance: "No",
    ypHaveStDisabilityInsurance: "No",
    ypHaveUmbrellaPolicy: "No",
    ypHavePowerOfAttorney: "No",
    ypHaveRevocableTrust: "No",
    ypHaveLtCareInsurance: "No",
    ypHaveWill: "No",
    ypHaveLivingWill: "No"

};

export const DependentProtectionReducer = (state = dpInitialState, action) => {
    switch (action.type) {

        // Insurance : Your Protection
        case "YP_ONETIME_EXP":
            return {
                ...state,
                ypOnetimeExp: (state.ypOnetimeExp = action.payload),
            };
        case "YP_YEARS_TO_SUPPORT":
            return {
                ...state,
                ypYearsToSupport: (state.ypYearsToSupport = action.payload),
            };
        case "YP_AVG_ANNUAL_RETURN":
            return {
                ...state,
                ypAvgAnnualReturn: (state.ypAvgAnnualReturn = action.payload),
            };
        case "YP_AVG_INFLATION":
            return {
                ...state,
                ypAvgInflation: (state.ypAvgInflation = action.payload),
            };
            case "YP_INSURANCE_SELF_TYPE":
            return {
                ...state,
                ypInsuranceSelfType: (state.ypInsuranceSelfType = action.payload),
            };
        case "YP_INSURANCE_SELF":
            return {
                ...state,
                ypInsuranceSelf: (state.ypInsuranceSelf = action.payload),
            };
        case "YP_INSURANCE_EMPLOYER":
            return {
                ...state,
                ypInsuranceEmployer: (state.ypInsuranceEmployer = action.payload),
            };
        case "YP_HAVE_HOMEOWNERS_INSURANCE":
            return {
                ...state,
                ypHaveHomeownersInsurance: (state.ypHaveHomeownersInsurance = action.payload),
            };
        case "YP_HAVE_HEALTH_INSURANCE":
                return {
                    ...state,
                    ypHaveHealthInsurance: (state.ypHaveHealthInsurance = action.payload),
                };
        case "YP_HAVE_LT_DISABILITY_INSURANCE":
            return {
                ...state,
                ypHaveLtDisabilityInsurance: (state.ypHaveLtDisabilityInsurance = action.payload),
            };
        case "YP_HAVE_ST_DISABILITY_INSURANCE":
            return {
                ...state,
                ypHaveStDisabilityInsurance: (state.ypHaveStDisabilityInsurance = action.payload),
            };
        case "YP_HAVE_UMBRELLA_POLICY":
            return {
                ...state,
                ypHaveUmbrellaPolicy: (state.ypHaveUmbrellaPolicy = action.payload),
            };
        case "YP_HAVE_POWER_OF_ATTORNEY":
            return {
                ...state,
                ypHavePowerOfAttorney: (state.ypHavePowerOfAttorney = action.payload),
            };
        case "YP_HAVE_REVOCABLE_TRUST":
            return {
                ...state,
                ypHaveRevocableTrust: (state.ypHaveRevocableTrust = action.payload),
            };
        case "YP_HAVE_LT_CARE_INSURANCE":
            return {
                ...state,
                ypHaveLtCareInsurance: (state.ypHaveLtCareInsurance = action.payload),
            };
        case "YP_HAVE_WILL":
            return {
                ...state,
                ypHaveWill: (state.ypHaveWill = action.payload),
            };
        case "YP_HAVE_LIVING_WILL":
            return {
                ...state,
                ypHaveLivingWill: (state.ypHaveLivingWill = action.payload),
            };
    
        case "ALL_DP":
            return {
                ...state,

                ypOnetimeExp: (state.ypOnetimeExp = action.payload.ypOnetimeExp),
                ypYearsToSupport: (state.ypYearsToSupport = action.payload.ypYearsToSupport),
                ypAvgAnnualReturn: (state.ypAvgAnnualReturn = action.payload.ypAvgAnnualReturn),
                ypAvgInflation: (state.ypAvgInflation = action.payload.ypAvgInflation),
                ypInsuranceSelfType: (state.ypInsuranceSelfType = action.payload.ypInsuranceSelfType),
                ypInsuranceSelf: (state.ypInsuranceSelf = action.payload.ypInsuranceSelf),
                ypInsuranceEmployer: (state.ypInsuranceEmployer = action.payload.ypInsuranceEmployer),
                ypHaveHomeownersInsurance: (state.ypHaveHomeownersInsurance = action.payload.ypHaveHomeownersInsurance),
                ypHaveHealthInsurance: (state.ypHaveHealthInsurance = action.payload.ypHaveHealthInsurance),
                ypHaveLtDisabilityInsurance: (state.ypHaveLtDisabilityInsurance = action.payload.ypHaveLtDisabilityInsurance),
                ypHaveStDisabilityInsurance: (state.ypHaveStDisabilityInsurance = action.payload.ypHaveStDisabilityInsurance),
                ypHaveUmbrellaPolicy: (state.ypHaveUmbrellaPolicy = action.payload.ypHaveUmbrellaPolicy),
                ypHavePowerOfAttorney: (state.ypHavePowerOfAttorney = action.payload.ypHavePowerOfAttorney),
                ypHaveRevocableTrust: (state.ypHaveRevocableTrust = action.payload.ypHaveRevocableTrust),
                ypHaveLtCareInsurance: (state.ypHaveLtCareInsurance = action.payload.ypHaveLtCareInsurance),
                ypHaveWill: (state.ypHaveWill = action.payload.ypHaveWill),
                ypHaveLivingWill: (state.ypHaveLivingWill = action.payload.ypHaveLivingWill)
            };
    
        default:
            return state;
    }
};
