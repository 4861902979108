// CashManagmentReducer
const cmInitialState = {
    // Income
    iSalary: 0,
    iSalaryPeriod: "Monthly",
    iRental: 0,
    iRentalPeriod: "Monthly",
    iAnyOther: 0,
    iAnyOtherPeriod: "Monthly",
    iAnyOtherDesc: '',

    // Expenses - propery related
    prHomeMortgage: 0,
    prHomeMortgagePeriod: "Monthly",
    prPropertyTax: 0,
    prLoan: 0,
    prLoanPeriod: 'Monthly',
    prOther: 0,
    prOtherPeriod: "Monthly",
    prMakeTimelyPayment: "Yes",

    // Living Expenses
    leLife: 0,
    leLifePeriod: "Monthly",
    leHealthInsurance: 0,
    leHealthInsurancePeriod: "Monthly",
    leTravel: 0,
    leTravelPeriod: "Monthly",

    // Other Expenses
    oeEstimatedTaxesAnnual: 0,
    oeMonthlyOther: 0,
    oeMonthlyOtherDesc: ''
};

export const CashManagmentReducer = (state = cmInitialState, action) => {
    switch (action.type) {
        // Income
        case "I_SALARY":
            return {
                ...state,
                iSalary: (state.iSalary = action.payload),
            };
        case "I_SALARY_PERIOD":
            return {
                ...state,
                iSalaryPeriod: (state.iSalaryPeriod = action.payload),
            };
        case "I_RENTAL":
            return {
                ...state,
                iRental: (state.iRental = action.payload),
            };
        case "I_RENTAL_PERIOD":
            return {
                ...state,
                iRentalPeriod: (state.iRentalPeriod = action.payload),
            };
    
        case "I_ANY_OTHER":
            return {
                ...state,
                iAnyOther: (state.iAnyOther = action.payload),
            };

        case "I_ANY_OTHER_PERIOD":
            return {
                ...state,
                iAnyOtherPeriod: (state.iAnyOtherPeriod = action.payload),
            };

        case "I_ANY_OTHER_DESC":
            return {
                ...state,
                iAnyOtherDesc: (state.iAnyOtherDesc = action.payload),
            };
    
        // Expenses - propery related
        case "PR_HOME_MORTGAGE":
            return {
                ...state,
                prHomeMortgage: (state.prHomeMortgage = action.payload),
            };
        case "PR_HOME_MORTGAGE_PERIOD":
            return {
                ...state,
                prHomeMortgagePeriod: (state.prHomeMortgagePeriod = action.payload),
            };
        case "PR_PROPERTY_TAXES":
            return {
                ...state,
                prPropertyTax: (state.prPropertyTax = action.payload),
            };

        case "PR_LOAN":
            return {
                ...state,
                prLoan: (state.prLoan = action.payload),
            };
        case "PR_LOAN_PERIOD":
            return {
                ...state,
                prLoanPeriod: (state.prLoanPeriod = action.payload),
            };
    
        case "PR_OTHER":
            return {
                ...state,
                prOther: (state.prOther = action.payload),
            };
        case "PR_OTHER_PERIOD":
            return {
                ...state,
                prOtherPeriod: (state.prOtherPeriod = action.payload),
            };

        case "PR_MAKE_TIMELY_PAYMENT":
            return {
                ...state,
                prMakeTimelyPayment: (state.prMakeTimelyPayment = action.payload),
            };
    
        // living expense
        case "LE_LIFE":
            return {
                ...state,
                leLife: (state.leLife = action.payload),
            };
        case "LE_LIFE_PERIOD":
            return {
                ...state,
                leLifePeriod: (state.leLifePeriod = action.payload),
            };
    
        case "LE_HEALTH_INSURANCE":
            return {
                ...state,
                leHealthInsurance: (state.leHealthInsurance = action.payload),
            };
        
        case "LE_HEALTH_INSURANCE_PERIOD":
            return {
                ...state,
                leHealthInsurancePeriod: (state.leHealthInsurancePeriod = action.payload),
            };


        case "LE_TRAVEL":
            return {
                ...state,
                leTravel: (state.leTravel = action.payload),
            };

        case "LE_TRAVEL_PERIOD":
            return {
                ...state,
                leTravelPeriod: (state.leTravelPeriod = action.payload),
            };

        //  OTHE EXPENSIVE
        case "OE_ESTIMATED_TAXES":
            return {
                ...state,
                oeEstimatedTaxesAnnual: (state.oeEstimatedTaxesAnnual = action.payload),
            };
        case "OE_MONTHLY_OTHER":
            return {
                ...state,
                oeMonthlyOther: (state.oeMonthlyOther = action.payload),
            };

        case "OE_MONTHLY_OTHER_DESC":
            return {
                ...state,
                oeMonthlyOtherDesc: (state.oeMonthlyOtherDesc = action.payload),
            };
    
        case "ALL_CM":
            const return_state = {
                ...state,

                iSalary: (state.iSalary = action.payload.iSalary),
                iSalaryPeriod: (state.iSalaryPeriod = action.payload.iSalaryPeriod),
                iRental: (state.iRental = action.payload.iRental),
                iRentalPeriod: (state.iRentalPeriod = action.payload.iRentalPeriod),
                iAnyOther: (state.iAnyOther = action.payload.iAnyOther),
                iAnyOtherPeriod: (state.iAnyOtherPeriod = action.payload.iAnyOtherPeriod),
                iAnyOtherDesc: (state.iAnyOtherDesc = action.payload.iAnyOtherDesc),
                
                prHomeMortgage: (state.prHomeMortgage= action.payload.prHomeMortgage),
                prHomeMortgagePeriod: (state.prHomeMortgagePeriod= action.payload.prHomeMortgagePeriod),
                prPropertyTax: (state.prPropertyTax= action.payload.prPropertyTax),
                prLoan: (state.prLoan= action.payload.prLoan),
                prLoanPeriod: (state.prLoanPeriod= action.payload.prLoanPeriod),
                prOther: (state.prOther= action.payload.prOther),
                prOtherPeriod: (state.prOtherPeriod= action.payload.prOtherPeriod),
                prMakeTimelyPayment: (state.prMakeTimelyPayment= action.payload.prMakeTimelyPayment),
                
                leLife: (state.leLife = action.payload.leLife),
                leLifePeriod: (state.leLifePeriod = action.payload.leLifePeriod),
                leHealthInsurance: (state.leHealthInsurance = action.payload.leHealthInsurance),
                leHealthInsurancePeriod: (state.leHealthInsurancePeriod = action.payload.leHealthInsurancePeriod),
                leTravel: (state.leTravel = action.payload.leTravel),
                leTravelPeriod: (state.leTravelPeriod = action.payload.leTravelPeriod),
                
                oeEstimatedTaxesAnnual: (state.oeEstimatedTaxesAnnual = action.payload.oeEstimatedTaxesAnnual),
                oeMonthlyOther: (state.oeMonthlyOther = action.payload.oeMonthlyOther),
                oeMonthlyOtherDesc: (state.oeMonthlyOtherDesc = action.payload.oeMonthlyOtherDesc),
                
            };
            console.log('return_state =================>');
            console.log(return_state);
            return return_state;
    

        default:
            return state;
    }
};

