import React from "react";
import { Container, Box, Button, Alert, Grid, Typography, Link } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from "formik";
import * as Yup from "yup";
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSignup } from "../../../hooks/useSignup";
import { AuthConstants } from "../../../constants/AuthConstants";
import BackLink from '../../../components/backLink/BackLink'
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import heroimg from "../../../assets/images/login.png";
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

const SignUp = () => {
  const { signup, isLoading, error } = useSignup();
  const navigate = useNavigate();
  const BirthYearOptions = AuthConstants.BirthYearOptions.getBirthYearOptions(
    16,
    110
  );
  const GenderOptions = AuthConstants.GenderOptions;
  const MaritalStatusOptions = AuthConstants.MaritalStatusOptions;
  const EducationLevelOptions = AuthConstants.EducationLevelOptions;

  const label = { inputProps: { "aria-label": "Checkbox" } };
  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Invalid email address")
      .max(255)
      .required("Email is required."),
    first_name: Yup.string("Enter your first name")
      .min(1, "Invalid first name")
      .matches(AuthConstants.FirstNameRegex, "Please enter valid first name")
      .max(40)
      .required("First name is required."),
    last_name: Yup.string("Enter your last name")
      .min(1, "Invalid last name")
      .matches(AuthConstants.LastNameRegex, "Please enter valid last name")
      .max(40)
      .required("Last name is required."),
    phone: Yup.string("Enter your phone")
      .matches(AuthConstants.PhoneRegex, "Phone number is not valid")
      .min(2, "Invalid phone number field")
      .max(16, "Invalid phone number field")
      .required("Cell is required"),
    zip: Yup.string("Enter Zip code")
      .min(2, "Invalid Zip code field")
      .max(10, "Invalid Zip code field")
      .required("Zip code  is required."),
    // secAnsOne: Yup.string('Enter answer to your first security question')
    //   .min(1, 'Answer should not be at least 1 character long')
    //   .max(255, 'Answer should not be more than 255 characters long')
    //   .when('secQuestionOne', (secQuestionOne, field) =>
    //     secQuestionOne ? field.required('As you have selected a security question, answer to your security question is required') : field,
    //   ),
    // secQuestionTwo: Yup.number('Enter your second security question')
    //   .min(0, 'Invalid first security question')
    //   .max(SecQuestionOneOptions.length, 'Invalid first security question'),

    // secAnsTwo: Yup.string('Enter answer to your second security question')
    //   .min(1, 'Answer should not be at least 1 character long')
    //   .max(255, 'Answer should not be more than 255 characters long')
    //   .when('secQuestionTwo', (secQuestionTwo, field) =>
    //     secQuestionTwo ? field.required('As you have selected a security question, answer to your security question is required') : field,
    //   ),
    birth_year: Yup.string("Enter year of birth").required("Year of birth is required"),
    gender: Yup.string("Select your Gender").required("Gender is required"),
    marital_status: Yup.string("Select your marital status").required("Material status is required"),
    education_level: Yup.string("Select your education level").required("Education is required"),

    password: Yup.string("Enter your password")
      .min(8, "Password must be at least 8 characters")
      .max(255, "Password length must be smaller than 255 characters")
      .required("Password is required."),
    confirmPassword: Yup.string("Enter your password again")
      .label("Confirm Password")
      .required("Confirm password is required.")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      zip: "",
      birth_year: "",
      gender: "",
      marital_status: "",
      education_level: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const utm_source = localStorage.getItem('utm_source');
      const utm_medium = localStorage.getItem('utm_medium')
      const utm_campaign = localStorage.getItem('utm_campaign')
      const utm_keyword = localStorage.getItem('utm_keyword')
      const referrer = localStorage.getItem('referrer')
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      const authResponse = await signup(
        values["first_name"],
        values["last_name"],
        values["email"],
        values["password"],
        values["confirmPassword"],
        values["zip"],
        values["phone"],
        values["birth_year"],
        values["gender"],
        values["marital_status"],
        values["education_level"],
        utm_source,
        utm_medium,
        utm_campaign,
        utm_keyword,
        referrer
      );
      if (authResponse) {
        console.log("authResponse: ", authResponse);
        navigate("/activation-notification");
      } else {
        console.log("Show Errors - Alert");
      }
    },
  });

  return (

    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Grid container   >
        <Grid item xs={12} md={6}>

          <Typography variant="h1">Create an account</Typography>

          <form onSubmit={formik.handleSubmit}>


            <TextField
              fullWidth
              id="first_name"
              name="first_name"
              placeholder="Enter your first name"
              label="First Name"
              type="text"
              variant="outlined"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              error={
                formik.touched.first_name &&
                Boolean(formik.errors.first_name)
              }
              helperText={
                formik.touched.first_name && formik.errors.first_name
              }
              margin="normal"
            />



            <TextField
              fullWidth
              id="last_name"
              name="last_name"
              placeholder="Enter your last name"
              label="Last Name"
              type="text"
              variant="outlined"
              value={formik.values.last_name}
              onChange={formik.handleChange}
              error={
                formik.touched.last_name &&
                Boolean(formik.errors.last_name)
              }
              helperText={
                formik.touched.last_name && formik.errors.last_name
              }
              margin="normal"
            />

            <TextField
              fullWidth
              id="email"
              name="email"
              placeholder="Enter your email address"
              label="Email"
              type="email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={
                formik.touched.email && Boolean(formik.errors.email)
              }
              helperText={formik.touched.email && formik.errors.email}
              margin="normal"
            />

            <TextField
              fullWidth
              id="password"
              name="password"
              placeholder="Enter your password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={
                formik.touched.password &&
                Boolean(formik.errors.password)
              }
              helperText={
                formik.touched.password && formik.errors.password
              }
              margin="normal"
            />

            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Enter your password again"
              label="Confirm Password"
              type="password"
              variant="outlined"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword &&
                formik.errors.confirmPassword
              }
              margin="normal"
            />


            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  id="phone"
                  name="phone"
                  placeholder="Enter your cell number"
                  label="Cell"
                  type="text"
                  variant="outlined"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phone && Boolean(formik.errors.phone)
                  }
                  helperText={
                    formik.touched.phone && formik.errors.phone
                  }
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  id="zip"
                  name="zip"
                  placeholder="Enter your Zip code"
                  label="Zip"
                  type="text"
                  variant="outlined"
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.zip && Boolean(formik.errors.zip)
                  }
                  helperText={formik.touched.zip && formik.errors.zip}
                  margin="normal"
                />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  select
                  id="birth_year"
                  name="birth_year"
                  placeholder="Select your year of birth"
                  label="Year of Birth"
                  // type="text"
                  variant="outlined"
                  value={formik.values.birth_year}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.birth_year &&
                    Boolean(formik.errors.birth_year)
                  }
                  helperText={
                    formik.touched.birth_year && formik.errors.birth_year
                  }
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"0"}>
                    No Selected
                  </MenuItem>
                  {BirthYearOptions.map((option) => (
                    <MenuItem key={option} value={`${option}`}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem selected key={"20001"} value={"20001"}>
                    {"20001"}
                  </MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  select
                  id="gender"
                  name="gender"
                  placeholder="Select your gender"
                  label="Gender"
                  // type="text"
                  variant="outlined"
                  value={formik.values.gender}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.gender &&
                    Boolean(formik.errors.gender)
                  }
                  helperText={
                    formik.touched.gender && formik.errors.gender
                  }
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"No Selected"}>
                    No Selected
                  </MenuItem>
                  {GenderOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  select
                  id="marital_status"
                  name="marital_status"
                  placeholder="Select your marital status"
                  label="Marital Status"
                  // type="text"
                  variant="outlined"
                  value={formik.values.marital_status}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.marital_status &&
                    Boolean(formik.errors.marital_status)
                  }
                  helperText={
                    formik.touched.marital_status &&
                    formik.errors.marital_status
                  }
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"No Selected"}>
                    No Selected
                  </MenuItem>
                  {MaritalStatusOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                  fullWidth
                  select
                  id="education_level"
                  name="education_level"
                  placeholder="Select your education level"
                  label="Education Level"
                  // type="text"
                  variant="outlined"
                  value={formik.values.education_level}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.education_level &&
                    Boolean(formik.errors.education_level)
                  }
                  helperText={
                    formik.touched.education_level &&
                    formik.errors.education_level
                  }
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"No Selected"}>
                    No Selected
                  </MenuItem>
                  {EducationLevelOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Box className="buttons_section">
              <Button
                disabled={isLoading}
                fullWidth
                className="sign_up_button"
                type="submit"
                endIcon={<ArrowForwardIcon />}
                sx={{ marginTop: '40px', color: '#102691', backgroundImage: 'linear-gradient(267deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)' }}
              >
                Save and create an account
              </Button>
              {error && (
                <pre>
                  {error && (
                    <Alert severity="error">Error: {error}</Alert>
                  )}
                </pre>
              )}
            </Box>


          </form>
          <Divider sx={{ marginTop: '50px', marginBottom: '30px' }} />
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ marginBottom: '100px' }}>

            <Typography variant="body1">See Our</Typography>
            <Link href="/privacy-policy" sx={{ fontSize: '1.6rem' }}>Privacy Policy</Link>

          </Stack>
        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={6}>
          <Box
            component="img" sx={{ width: '90%', }} src={heroimg} alt="Hero"
          />

        </Grid>

      </Grid>
    </Container>

  );
};

export default SignUp;
