import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Grid, Modal } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./OpenModal.css";
import modalBgImg from "../../assets/images/modal/rectangle-background.png";
import modalHealthImg from "../../assets/images/modal/health-img.png";
import cancelIconImg from "../../assets/images/modal/cancel-Icon.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  //   bgcolor: "background.paper",
  border: "0px !important",
  "&:hover": {
    border: "0px !important",
  },
  "&:focus-visible": {
    border: "0px !important",
  },
  borderRadius: "5px",
  //   boxShadow: 24,
  p: 4,
  backgroundImage: `url(${modalBgImg})`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "220%",
  overflow: "hidden",
};

export default function OpenModal() {
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        // sx={backgroundStyle}
      >
        <Box sx={style}>
          {/* <Button> */}
          <img
            src={cancelIconImg}
            style={{
              top: "-25px",
              left: "460px",
              position: "relative",
              height: "10px",
              width: "10px",
            }}
            onClick={handleClose}
            alt="Close"
          />
          {/* </Button> */}
          <Grid container spacing={2} justifyContent="center">
            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              
            </Grid> */}
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Typography
                id="modal-modal-title"
                className="openmodal--sec--header"
                variant="h4"
                component="h2"
              >
                PFScores
              </Typography>
              <Typography
                id="modal-modal-title"
                className="openmodal--sec--subheader"
                variant="body1"
                component="h2"
              >
                Financial Health, Simplified
              </Typography>
              <Typography
                id="modal-modal-description"
                variant="body2"
                sx={{ mt: 2 }}
                className="openmodal--sec--body"
              >
                PFScores is a <strong> free, secure, and comprehensive </strong>{" "}
                online benchmarking app that gives individuals and their
                families a complete financial health checkup. PFScores is easy
                to use and takes only about fifteen minutes to complete.
              </Typography>
              <Box className="openmodal--sec--btns">
                <button className="btn l">
                  <Link to="/signup">
                    Try It Now
                    <ArrowForwardIcon />
                  </Link>
                </button>
              </Box>
              {/* <Grid item>
                <Button size="small" variant="contained">
                  Try It Now
                </Button>
              </Grid> */}
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <img
                src={modalHealthImg}
                style={{ overflow: "hidden" }}
                width={"150%"}
                alt="PFScores"
              />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
