import React from "react";
import "./KeysFiguresActions.css";
import { CircleProgress } from "react-gradient-progress";
import { Box, Typography } from "@mui/material";

const ScoreCard = (props) => {
  const {
    percentage,
  } = props;
  return (
    <>
      {/* <Box className="key_figures_wrapper">
        <Box className="key_heading">
          <Typography variant="body1" sx={{color:'#102691', fontWeight: 600, textAlign: 'center'}}>Your Score</Typography>
        </Box>
        <CircleProgress
          percentage={percentage}
          strokeWidth={25}
          secondaryColor=" #ECF5FF"
          primaryColor={["#3479FB", "#4EEEB3"]}
          width={250}
          hidePercentageText={false}
        />
      </Box> */}
    </>
  );
};

export default ScoreCard;
