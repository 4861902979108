const rInitialState = {

    yearsToRetire: 5,
    retirementDuration: 25,
    healthcareExpenses: 0,
    lifeExpenses: 0,
    teExpenses: 0,
    otherExpenses: 0,

    // Living expenses during retirement (in today's $s)
    leHousing: 5000,
    leHousingPeriod: "Monthly",

    // Expected liabilities during retirement
    elMortgage: 0,
    elLoans: 0,
    elLegacy: 0,

    // Retirement savings
    rsRetirementAc: 10000,
    rsContributionByEmployer: 10000,
    rsContributionByYou: 10000,

    // Income from Social Security and pensions
    isspPension: 0,
    isspSocSec: 1000,

    // Economic and market factors
    emfInflationRate: 3,
    emfAnnualReturn: 7,



};

export const RetirementReducer = (state = rInitialState, action) => {
    switch (action.type) {

        case "YEARS_TO_RETIRE":
            return {
                ...state,
                yearsToRetire: (state.yearsToRetire = action.payload),
            };
        case "RETIREMENT_DURATION":
            return {
                ...state,
                retirementDuration: (state.retirementDuration = action.payload),
            };

        // Living expenses during retirement (in today's $s)
        case "LE_HOUSING":
            return {
                ...state,
                leHousing: (state.leHousing = action.payload),
            };
        case "LE_HOUSING_PERIOD":
            return {
                ...state,
                leHousingPeriod: (state.leHousingPeriod = action.payload),
            };
        case "HEALTHCARE_EXPENSES":
            return {
                ...state,
                healthcareExpenses: (state.healthcareExpenses = action.payload),
            };
        case "LIFE_EXPENSES":
            return {
                ...state,
                lifeExpenses: (state.lifeExpenses = action.payload),
            };

        case "T_E_EXPENSES":
            return {
                ...state,
                teExpenses: (state.teExpenses = action.payload),
            };
        case "OTHER_EXPENSES":
            return {
                ...state,
                otherExpenses: (state.otherExpenses = action.payload),
            };
        // leHousingPeriod

        // Expected liabilities during retirement
        case "EL_MORTGAGE":
            return {
                ...state,
                elMortgage: (state.elMortgage = action.payload),
            };
        case "EL_LOANS":
            return {
                ...state,
                elLoans: (state.elLoans = action.payload),
            };
        case "EL_LEGACY":
            return {
                ...state,
                elLegacy: (state.elLegacy = action.payload),
            };

        // Retirement savings
        case "RS_RETIREMENT_AC":
            return {
                ...state,
                rsRetirementAc: (state.rsRetirementAc = action.payload),
            };
        case "RS_CONTRIBUTION_EMPLOYER":
            return {
                ...state,
                rsContributionByEmployer: (state.rsContributionByEmployer = action.payload),
            };
        case "RS_CONTRIBUTION_YOU":
            return {
                ...state,
                rsContributionByYou: (state.rsContributionByYou = action.payload),
            };

        // Income from Social Security and pensions
        case "ISSP_PENSION":
            return {
                ...state,
                isspPension: (state.isspPension = action.payload),
            };
        case "ISSP_SOC_SEC":
            return {
                ...state,
                isspSocSec: (state.isspSocSec = action.payload),
            };

        // Economic and market factors
        case "EMF_INFLATION_RATE":
            return {
                ...state,
                emfInflationRate: (state.emfInflationRate = action.payload),
            };
        case "EMF_ANNUAL_RETURN":
            return {
                ...state,
                emfAnnualReturn: (state.emfAnnualReturn = action.payload),
            };

        case "ALL_RETIREMENT":
            return {
                ...state,

                yearsToRetire: (state.yearsToRetire = action.payload.yearsToRetire),
                retirementDuration: (state.retirementDuration = action.payload.retirementDuration),
                leHousing: (state.leHousing = action.payload.leHousing),
                leHousingPeriod: (state.leHousingPeriod = action.payload.leHousingPeriod),
                healthcareExpenses: (state.healthcareExpenses = action.payload.healthcareExpenses),
                lifeExpenses: (state.lifeExpenses = action.payload.lifeExpenses),
                teExpenses: (state.teExpenses = action.payload.teExpenses),
                otherExpenses: (state.otherExpenses = action.payload.otherExpenses),
                elMortgage: (state.elMortgage = action.payload.elMortgage),
                elLoans: (state.elLoans = action.payload.elLoans),
                elLegacy: (state.elLegacy = action.payload.elLegacy),
                rsRetirementAc: (state.rsRetirementAc = action.payload.rsRetirementAc),
                rsContributionByEmployer: (state.rsContributionByEmployer = action.payload.rsContributionByEmployer),
                rsContributionByYou: (state.rsContributionByYou = action.payload.rsContributionByYou),
                isspPension: (state.isspPension = action.payload.isspPension),
                isspSocSec: (state.isspSocSec = action.payload.isspSocSec),
                emfInflationRate: (state.emfInflationRate = action.payload.emfInflationRate),
                emfAnnualReturn: (state.emfAnnualReturn = action.payload.emfAnnualReturn)
            };

        default:
            return state;
    }
};
