import { Container, Box, Button } from "@mui/material";
import React from "react";
import "./Header.css";
import "./Header3.css";

import headerlogo from "../../assets/images/header-logo.svg";
import { Link } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useSignout } from "../../hooks/useSignout";
import { useNavigate } from "react-router-dom";

import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PasswordIcon from "@mui/icons-material/Password";
import DeleteIcon from "@mui/icons-material/Delete";
import LogoutIcon from "@mui/icons-material/Logout";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function Header3() {
  const { state } = useAuthContext();
  const { signout } = useSignout();
  const navigate = useNavigate();

  console.log("Header - User from Context: ", state.user);
  const user = state.user;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (menuItem = "logout") => {
    setAnchorEl(null);
    switch (menuItem) {
      case "logout":
        signout();
        navigate("/login");
        break;
      case "delete-account":
        navigate("/account/confirm-delete");
        break;
        case "score":
          navigate("/scoring-details/personal-details");
          break;
      case "account":
        navigate("/account");
        break;
      case "change-password":
        navigate("/change-password");
        break;
      default:
        break;
    }
  };

  // onClick={() => handleSiteMenuClick('score', true)}
  const handleSiteMenuClick = (menuItem = "home", loggedIn = false) => {
    switch (menuItem) {
      case "home":
        navigate("/");
        break;
      case "about":
        navigate("/about-us");
        break;
      case "score":
        if (loggedIn) {
          navigate("/scoring-details/personal-details");
        } else {
          navigate("/login");
        }
        break;
      default:
        break;
    }
  };

  return (
    <header>
      <Container maxWidth="lg" className="header">
        <Box className="row">
          <Box className="header--left">
          <Link to="/" style={{
              display: "flex",
              textDecoration: "none",
              fontSize: "24px",
              color: "#102691",
              fontFamily: 'Inter',
              fontWeight: 300
            }}>
              <img src={headerlogo} alt="headerlogo" /> &#8482;
            </Link>
          </Box>
          {/* <Box>
            <Button
              variant="text"
              onClick={() => handleSiteMenuClick("home", false)}
            >
              Home
            </Button>
          </Box>
          <Box>
            {state.user && (
              <Button
                variant="text"
                onClick={() => handleSiteMenuClick("score", true)}
              >
                Score
              </Button>
            )}
            {!state.user && (
              <Button
                variant="text"
                onClick={() => handleSiteMenuClick("score", false)}
              >
                Score
              </Button>
            )}
          </Box>
          <Box>
            <Button
              variant="text"
              onClick={() => handleSiteMenuClick("about", false)}
            >
              About
            </Button>
          </Box> */}

          <Box className="header--right">
            {state.user && (
              <div>
                <Button
                  id="pfs-profile-menu-button"
                  aria-controls={open ? "pfs-profile-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  endIcon={<KeyboardArrowDownIcon />}
                >
                  {state.user.user.first_name}
                </Button>
                <StyledMenu
                  id="pfs-profile-menu"
                  MenuListProps={{
                    "aria-labelledby": "pfs-profile-menu-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                >
                   <MenuItem
                    onClick={() => handleMenuItemClick("score")}
                    disableRipple
                  >
                    <ManageAccountsIcon />
                    Go To App
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("account")}
                    disableRipple
                  >
                    <ManageAccountsIcon />
                    My Account
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("change-password")}
                    disableRipple
                  >
                    <PasswordIcon />
                    Change Password
                  </MenuItem>
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    onClick={() => handleMenuItemClick("logout")}
                    disableRipple
                  >
                    <LogoutIcon /> Logout
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleMenuItemClick("delete-account")}
                    disableRipple
                  >
                    <DeleteIcon />
                    Delete Account
                  </MenuItem>
                </StyledMenu>
              </div>
            )}
            {!state.user && (
              <button className="login--btn">
                <Link to="/login">Login</Link>
              </button>
            )}
          </Box>
        </Box>
      </Container>
      {/* <div className="fade_rule"></div> */}
    </header>
  );
}
