// MajorPurchases
import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyMajorPurchases = async function () {
  console.log("Inside getMyMajorPurchases()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyMajorPurchases {
          code
          message
          http_status
          data {
            id

            name_of_purchase
            num_years_left_for_purchase
            amount_needed_as_full_down_payment
            amount_saved_so_far_for_purchase
            current_monthly_savings_for_purchase
            exp_avg_annual_return_on_savings

            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors
        }
      }
    `,
    fetchPolicy: "no-cache" 
  });
  console.log("getMyMajorPurchases API Response is: ", data);
  return data;
};

export const updateMyMajorPurchases = async function (
  id,
  nameOfPurchase,
  numYearsLeftForPurchase,
  amountNeededAsFullDownPayment,
  amountSavedSoFarForPurchase,
  currentMonthlySavingsForPurchase,
  expAvgAnnualReturnOnSavings
) {
  console.log(`id: ${id}`);
  console.log(`nameOfPurchase: ${nameOfPurchase}`);
  console.log(`numYearsLeftForPurchase: ${numYearsLeftForPurchase}`);
  console.log(
    `amountNeededAsFullDownPayment: ${amountNeededAsFullDownPayment}`
  );
  console.log(`amountSavedSoFarForPurchase: ${amountSavedSoFarForPurchase}`);
  console.log(
    `currentMonthlySavingsForPurchase: ${currentMonthlySavingsForPurchase}`
  );
  console.log(`expAvgAnnualReturnOnSavings: ${expAvgAnnualReturnOnSavings}`);

  console.log("Inside updateMyMajorPurchases()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation updateMyMajorPurchase(
        $id: String!
        $nameOfPurchase: String!
        $numYearsLeftForPurchase: Int!
        $amountNeededAsFullDownPayment: Int!
        $amountSavedSoFarForPurchase: Int!
        $currentMonthlySavingsForPurchase: Int!
        $expAvgAnnualReturnOnSavings: Int!
      ) {
        updateMyMajorPurchase(
          data: {
            id: $id
            name_of_purchase: $nameOfPurchase
            num_years_left_for_purchase: $numYearsLeftForPurchase
            amount_needed_as_full_down_payment: $amountNeededAsFullDownPayment
            amount_saved_so_far_for_purchase: $amountSavedSoFarForPurchase
            current_monthly_savings_for_purchase: $currentMonthlySavingsForPurchase
            exp_avg_annual_return_on_savings: $expAvgAnnualReturnOnSavings
          }
        ) {
          code
          message
          http_status
          data {
            id

            name_of_purchase
            num_years_left_for_purchase
            amount_needed_as_full_down_payment
            amount_saved_so_far_for_purchase
            current_monthly_savings_for_purchase
            exp_avg_annual_return_on_savings

            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            name_of_purchase
            num_years_left_for_purchase
            amount_needed_as_full_down_payment
            amount_saved_so_far_for_purchase
            current_monthly_savings_for_purchase
            exp_avg_annual_return_on_savings
          }
        }
      }
    `,
    variables: {
      id: id,
      nameOfPurchase: nameOfPurchase,
      numYearsLeftForPurchase: numYearsLeftForPurchase,
      amountNeededAsFullDownPayment: amountNeededAsFullDownPayment,
      amountSavedSoFarForPurchase: amountSavedSoFarForPurchase,
      currentMonthlySavingsForPurchase: currentMonthlySavingsForPurchase,
      expAvgAnnualReturnOnSavings: expAvgAnnualReturnOnSavings,
    },
  });

  console.log("updateMyMajorPurchases API Response is: ", data);
  return data;
};

export const addMyMajorPurchases = async function (
  nameOfPurchase,
  numYearsLeftForPurchase,
  amountNeededAsFullDownPayment,
  amountSavedSoFarForPurchase,
  currentMonthlySavingsForPurchase,
  expAvgAnnualReturnOnSavings
) {
  console.log(`nameOfPurchase: ${nameOfPurchase}`);
  console.log(`numYearsLeftForPurchase: ${numYearsLeftForPurchase}`);
  console.log(
    `amountNeededAsFullDownPayment: ${amountNeededAsFullDownPayment}`
  );
  console.log(`amountSavedSoFarForPurchase: ${amountSavedSoFarForPurchase}`);
  console.log(
    `currentMonthlySavingsForPurchase: ${currentMonthlySavingsForPurchase}`
  );
  console.log(`expAvgAnnualReturnOnSavings: ${expAvgAnnualReturnOnSavings}`);

  console.log("Inside addMyMajorPurchases()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addMyMajorPurchase(
        $nameOfPurchase: String!
        $numYearsLeftForPurchase: Int!
        $amountNeededAsFullDownPayment: Int!
        $amountSavedSoFarForPurchase: Int!
        $currentMonthlySavingsForPurchase: Int!
        $expAvgAnnualReturnOnSavings: Int!
      ) {
        addMyMajorPurchase(
          data: {
            name_of_purchase: $nameOfPurchase
            num_years_left_for_purchase: $numYearsLeftForPurchase
            amount_needed_as_full_down_payment: $amountNeededAsFullDownPayment
            amount_saved_so_far_for_purchase: $amountSavedSoFarForPurchase
            current_monthly_savings_for_purchase: $currentMonthlySavingsForPurchase
            exp_avg_annual_return_on_savings: $expAvgAnnualReturnOnSavings
          }
        ) {
          code
          message
          http_status
          data {
            id

            name_of_purchase
            num_years_left_for_purchase
            amount_needed_as_full_down_payment
            amount_saved_so_far_for_purchase
            current_monthly_savings_for_purchase
            exp_avg_annual_return_on_savings

            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            name_of_purchase
            num_years_left_for_purchase
            amount_needed_as_full_down_payment
            amount_saved_so_far_for_purchase
            current_monthly_savings_for_purchase
            exp_avg_annual_return_on_savings
          }
        }
      }
    `,
    variables: {
      nameOfPurchase: nameOfPurchase,
      numYearsLeftForPurchase: numYearsLeftForPurchase,
      amountNeededAsFullDownPayment: amountNeededAsFullDownPayment,
      amountSavedSoFarForPurchase: amountSavedSoFarForPurchase,
      currentMonthlySavingsForPurchase: currentMonthlySavingsForPurchase,
      expAvgAnnualReturnOnSavings: expAvgAnnualReturnOnSavings,
    },
  });

  console.log("addMyMajorPurchases API Response is: ", data);
  return data;
};

export const removeMyMajorPurchases = async function (id) {
  console.log("Inside removeMyMajorPurchases()");
  console.log(`id: ${id}`);
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation removeMyMajorPurchase($id: String!) {
        removeMyMajorPurchase(data: { id: $id }) {
          code
          message
          http_status
          data {
            id
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            id
          }
        }
      }
    `,
    variables: {
      id: id,
    },
  });

  console.log("removeMyMajorPurchases API Response is: ", data);
  return data;
};
