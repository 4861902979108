import React from "react";
import { Container, Box, Button, Typography, Stack, Link } from "@mui/material";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";

import Grid from "@mui/material/Grid";
import StarRateIcon from "@mui/icons-material/StarRate";
import AbsButton from "../absButton/AbsButton";
import Divider from "@mui/material/Divider";
import { BackDropEffect } from "../../components/backDropEffect/BackDropEffect";
import BackLink from "../../components/backLink/BackLink";
import generatePDF from "react-to-pdf";
import headerLogo from "../../assets/images/header-logo.svg";

function IndividualScoreDisplayComponent(props) {
  return (
    <Grid container spacing={2} sx={{ marginTop: "30px" }}>
      <Grid item xs={12} md={2}>
        <Box
          sx={{
            borderRadius: "10px",
            width: "86px",
            height: "60px",
            border: "2px solid transparent",
            background:
              "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
          }}
        >
          <Typography
            sx={{
              color: "#FFC700",
              fontSize: "28px",
              fontWeight: 700,
              textAlign: "center",
              marginTop: "17px",
            }}
          >
            {props.score}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#A0A0A0",
            fontSize: "12px",
            fontWeight: 400,
            textAlign: "center",
            width: "86px",
          }}
        >
          Score of {props.maxScore}
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            background:
              "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline",
          }}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "14px",
            fontWeight: 300,
            lineHeight: "2.2rem",
            marginTop: "10px",
          }}
        >
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
}

const getTargetElement = () => {
  let scorePanel = document.getElementById("score-panel");
  return scorePanel;
};

function ScoreDisplayComponent(props) {
  function generateReport() {
    document.getElementById("report-panel").style.display = "flex";
    document.getElementById("score-panel").style.width = "1200px";
    generatePDF(getTargetElement, { filename: "report.pdf" });
    document.getElementById("score-panel").style.width = "auto";
    document.getElementById("report-panel").style.display = "none";
  }

  return (
    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Box>
        <DetailsFormHeading
          title_text={"Your Score"}
          subtitle_text={"This is not advice. Click on our Disclaimer below."}
        />
      </Box>

      <Box       
      id="score-panel"
        sx={{
          backgroundImage: "linear-gradient(180deg, #000 0%, #000B40 100%)",
          borderRadius: "10px",
          padding: "60px",
          marginTop: "40px",
        }}
      >
       
          <Stack id="report-panel" sx={{ marginBottom: "60px", display:"none" }} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <img src={headerLogo} alt="header_logo" />
            <Typography sx={{ color: "white" }}>This is not financial advice. See our full Disclaimer Policy {process.env.REACT_APP_PUBLIC_URL + "disclaimer"}</Typography>

          </Stack>

          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px solid transparent",

                  height: "170px",
                  background:
                    "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "10px",
                    width: "140px",
                    height: "60px",
                    margin: "-32px auto 0 auto",
                    border: "2px solid transparent",
                    background:
                      "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#FFC700",
                      fontSize: "35px",
                      fontWeight: 700,
                      textAlign: "center",
                      marginTop: "17px",
                    }}
                  >
                    {props.overAllScore}
                  </Typography>
                </Box>
                <Stack
                  direction="row"
                  sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "28px",
                  }}
                >
                  {props.overAllScore < 301 && props.overAllScore > 0 && (
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    />
                  )}
                  {props.overAllScore < 401 && props.overAllScore > 300 && (
                    <>
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                    </>
                  )}

                  {props.overAllScore < 551 && props.overAllScore > 400 && (
                    <>
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                    </>
                  )}

                  {props.overAllScore < 651 && props.overAllScore > 550 && (
                    <>
                      {" "}
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                    </>
                  )}

                  {props.overAllScore > 650 && (
                    <>
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                      <StarRateIcon sx={{ color: "#FFC700" }} />
                    </>
                  )}
                </Stack>
                <Typography
                  sx={{
                    color: "white",
                    textAlign: "center",
                    fontSize: "22px",
                    marginTop: "28px",
                  }}
                >
                  {props.overAllScore > 650 ? "Outstanding!" : ""}
                  {props.overAllScore < 651 && props.overAllScore > 550
                    ? "Good!"
                    : ""}
                  {props.overAllScore < 551 && props.overAllScore > 400
                    ? "Average!"
                    : ""}
                  {props.overAllScore < 401 && props.overAllScore > 300
                    ? "Fair!"
                    : ""}
                  {props.overAllScore < 301 && props.overAllScore > 0
                    ? "Needs improvement"
                    : ""}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={1}></Grid>
            <Grid item xs={12} md={7}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "30px",
                  fontWeight: 500,
                  marginBottom: "30px",
                  lineHeight: "normal",
                }}
              >
                Your Personal Financial Health Score
              </Typography>
              <AbsButton
                title="Print/Save Report"
                type="submit"
                onClick={generateReport}
              />

              <Typography
                sx={{
                  color: "#D9D9D9",
                  fontSize: "16px",
                  fontWeight: 400,
                  marginTop: "30px",
                }}
              >
                If you like PFScores:
              </Typography>
              <Button
                variant="outlined"
                size="large"
                href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpfscores.com%2F"
                target="_blank"
                sx={{
                  lineHeight: 1,
                  marginRight: "15px",
                  marginTop: "15px",
                  borderRadius: "5px",
                  border: "1px solid transparent",
                  fontSize: "14px",
                  color: "#2C70FC",
                  background:
                    "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%) border-box",
                }}
              >
                Please Tell a friend
              </Button>
              <Button
                variant="outlined"
                size="large"
                href="/feedback"
                sx={{
                  lineHeight: 1,
                  marginRight: "15px",
                  marginTop: "15px",
                  borderRadius: "5px",
                  border: "1px solid transparent",
                  fontSize: "14px",
                  color: "#2C70FC",
                  background:
                    "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%) border-box",
                }}
              >
                Provide Feedback
              </Button>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "40px" }}>
            <Grid item xs={0} md={4}>
              <Divider
                sx={{
                  width: "25%",
                  height: "1px",
                  display: { xs: "none" },
                  background:
                    "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "22px",
                  fontWeight: 400,
                  textAlign: "center",
                }}
              >
                How your score was calculated
              </Typography>
            </Grid>
            <Grid item xs={0} md={4}>
              <Divider
                sx={{
                  width: "25%",
                  height: "1px",
                  display: { xs: "none" },
                  background:
                    "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
                }}
              />
            </Grid>
          </Grid>

          <IndividualScoreDisplayComponent
            score={props.debtScore}
            maxScore={350}
            title="Debt Management"
            description={
              "Debt management is how well you are managing your short-term and long-term debt. Short-term debt refers to payments you need to make over the course of the year. Your debt management score is " +
              props.debtScore +
              " against a maximum possible score of 350."
            }
          />
          <IndividualScoreDisplayComponent
            score={props.savingsScore}
            maxScore={200}
            title="Savings Discipline Score"
            description={
              "Savings discipline represents how well you are saving to achieve your future financial goals such as retirement, purchasing a home, saving for collage, etc. Your savings discipline score is " +
              props.savingsScore +
              " against a maximum possible score of 200."
            }
          />
          <IndividualScoreDisplayComponent
            score={props.riskScore}
            maxScore={200}
            title="Risk Management Score"
            description={
              "Risk management is how well you are protecting and your family against risks by having insurance or eliminating risk. Your risk management score is " +
              props.riskScore +
              " against a maximum possible score of 200."
            }
          />
          <Box></Box>
        
      </Box>
    </Container>
  );
}

export default ScoreDisplayComponent;
