import { styled } from "@mui/material/styles";

export const BackDropEffect = styled('div')(({theme})=>({
    position: "absolute",
    width: 'calc(50% - 200px)',
    height: 689,
    top: 0,
    right: 0,
    background: "#bfffe8",
    filter: "blur(454px)",
    zIndex: -99,
    [theme.breakpoints.down('lg')]: {
        width: '50%'
      }
  }));