import React from "react";
import "./Personalfinancial.css";
import { Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import pfindexcircle from "../../assets/images/p-f-index-circle.png";
import PersonalBoxSlider from "./PersonalBoxSlider/PersonalBoxSlider";

import imgcircleforresponseive from "../../assets/images/imgcircleforresponseive.png";
const Personalfinancial = () => {
  return (
    <section className="personal--financiall--pages">

      <Container className="Personalfinancial">
        {/*<Box className="row index----">
          <Box className="p--col left ">
            <h2 className="per--title">
              Personal Financial Scores® ( PFScores )
            </h2>
            <Box className="specil--btn">
              <button>Personal Financial</button>
              <button>Financial health</button>
              <button>Only 15 minutes</button>
            </Box>
            <p>
              Personal Financial Scores® (PFScores) is a comprehensive financial
              benchmark for individuals and families.
            </p>
            <p>
              In a few easy screens, it takes a 360&deg; view of your finances
              and allows you to measure and monitor your financial health via
              your personalized PFScores (similar to a credit score).
            </p>
            <p>
              We recommend using PFScores in two ways: as a tool to see the
              impact of a major decision on your finances and for periodic
              reviews to make sure you are on track with your finances.
            </p>{" "}
            <p>
              Typically, a person can complete this in about 15 minutes.
              However, you need not complete it in one go as your information is
              automatically saved for future use.
            </p>
            <Box className="perosal--sec--btns">
              <button className="btn">
                <Link to="/signup">
                  Try It Now <ArrowForwardIcon />
                </Link>
              </button>
            </Box>
          </Box>
          <Box className="p--col right">
            <img
              src={pfindexcircle}
              alt="pfindexcircle"
              className="pfindexcircle"
            />
            <img
              src={imgcircleforresponseive}
              alt=""
              className="imgcircleforresponseive"
            />
          </Box>

        </Box>*/}
      </Container>

      <Container className="how--its--work">
        <Box className="row personal--fin--box">
          <Box className="per--fin--box--col--left">
            <h5 className="titl--work">How it works</h5>
            <p>
              In a few easy steps, you securely enter your data. PFScores takes
              a comprehensive, 360&deg; view of your savings discipline and debt
              and risk management. PFScores uses this data to generate a
              personalized financial health score.
            </p>
            <Link to="/signup" className="per--try--to--free">
              Try It For Free
            </Link>
          </Box>

          <Box className="per--fin--box--col--right">
            <PersonalBoxSlider />
          </Box>
          <Box className="try--for--free--responsive">
            <button className="login--btn">
              <Link to="/signup"> Try It For Free</Link>
            </button>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Personalfinancial;
