import { rootReducer } from "./rootReducer";
import { PersonalDetailsReducer } from "./PersonalDetailsReducer";
import { NetWorthReducer } from "./NetWorthReducer";
import { CashManagmentReducer } from "./CashManagmentReducer";
import { RetirementReducer } from "./RetirementReducer";
import { CollegeEducationReducer } from "./CollegeEducationReducer";
import { MajorPurchasesReducer } from "./MajorPurchasesReducer";
import { DependentProtectionReducer } from "./DependentProtectionReducer";
import { ScoreReducer } from "./ScoreReducer";
import { SiteConfigReducer } from "./SiteConfigReducer";
import { UserDetailsReducer } from "./userReducer";

import { combineReducers } from "redux";

export const CombineReducers = combineReducers({
  rootReducer,
  PersonalDetailsReducer,
  NetWorthReducer,
  CashManagmentReducer,
  RetirementReducer,
  CollegeEducationReducer,
  MajorPurchasesReducer,
  DependentProtectionReducer,
  ScoreReducer,
  SiteConfigReducer,
  UserDetailsReducer,
});
