export const SiteConstants = {
    "general_inquiries": {
        "email": "support@pfscores.com",
        "phone": "(781) 366-0060",
        "fax": "(866) 373-0895"
    },
    "technical_support": {
        "email": "marketing@pfscores.com",
        "phone": "(781) 366-0060",
        "fax": "(866) 373-0895"
    },
    "address": {
        "google_map_location": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31452.33962138849!2d-118.3562569365935!3d34.04335922408717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2b93cca9c7ab1%3A0x9b191d3aac3e37!2sLos%20Angeles%20County%20Museum%20of%20Art!5e0!3m2!1sen!2s!4v1672294522597!5m2!1sen!2s",
        "address_text": "United State"
    },
    "social_links": {
        "facebook": "https://www.facebook.com/pfscores",
        "linkedIn": "https://www.linkedin.com/pfscoreinc",
        "instagram": "https://www.instagram.com/pfscores"
    },
    "name": {
        "trademark": "PFScores",
        "display": "PFScores",
        "business": "PFScores Inc",
        "long_name": "Personal Financial Scores",
        
    }
}

