import React from "react";
import { Container, Box, Button, Alert } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { usePassword } from '../../../hooks/usePassword';
import { AuthConstants } from '../../../constants/AuthConstants';
import AuthRightSection from '../../../components/authRightSection/AuthRightSection';
import AuthRightSectionMobile from '../../../components/authRightSection/AuthRightSectionMobile';
import { useSearchParams } from 'react-router-dom'
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import heroimg from "../../../assets/images/login.png";

// http://localhost:3003/reset-forgot-password?token=36612a48-4734-4865-b2db-e2db5b45286b

const ResetForgotPassword = () => {
  // setSearchParams
  const [searchParams] = useSearchParams();
  const { resetForgotPassword, isLoading, error, isSuccess } = usePassword();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Checkbox" } };
  const validationSchema = Yup.object({
    password: Yup.string('Enter your password')
      .min(8, 'Password must be at least 8 characters')
      .max(255, 'Password length must be smaller than 255 characters')
      .required('Password is required.'),
    confirmPassword: Yup.string('Enter your password again')
      .label('Confirm Password')
      .required('Confirm password is required.')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    passwordRecoveryToken: Yup.string('url')
      .required('Invalid URL'),

  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      passwordRecoveryToken: searchParams.get('token')
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(values);
      const authResponse = await resetForgotPassword(values['password'], values['confirmPassword'], values['passwordRecoveryToken'])
      if (authResponse) {
        console.log('authResponse: ', authResponse);
        navigate("/login");
      }
    },
  });

  return (

    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Grid container   >
        <Grid item xs={12} md={6}>
          <Typography variant="h1">Set Your Forgot Password</Typography>

          <form onSubmit={formik.handleSubmit}>
          
              <Box sx={{ display: 'none' }}>
                <TextField
                  fullWidth
                  disabled={true}
                  id="passwordRecoveryToken"
                  name="passwordRecoveryToken"
                  label="passwordRecoveryToken"
                  type="password"
                  variant="outlined"
                  value={formik.values.passwordRecoveryToken}
                  onChange={formik.handleChange}
                  error={formik.touched.passwordRecoveryToken && Boolean(formik.errors.passwordRecoveryToken)}
                  helperText={formik.touched.passwordRecoveryToken && formik.errors.passwordRecoveryToken}
                  margin="normal"
                />
              </Box>


              <TextField
                fullWidth
                id="password"
                name="password"
                placeholder="Enter your password"
                label="Password"
                type="password"
                variant="outlined"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={formik.touched.password && Boolean(formik.errors.password)}
                helperText={formik.touched.password && formik.errors.password}
                margin="normal"
              />

              <TextField
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                placeholder="Enter your password again"
                label="Confirm Password"
                type="password"
                variant="outlined"
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                margin="normal"
              />


              <Box className="buttons_section">
                <Button className="sign_up_button" type="submit" fullWidth endIcon={<ArrowForwardIcon />}
                  sx={{ marginTop: '40px', color: '#102691', backgroundImage: 'linear-gradient(267deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)' }}>
                  Set My Password
                </Button>
              </Box>
            

            
          </form>

          <Divider sx={{ marginTop: '50px', marginBottom: '30px' }} />
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginBottom: '10px' }}>

            <Typography variant="body1">Remembered your password?</Typography>
            <Link href="/login" sx={{ fontSize: '1.6rem' }}>SignIn</Link>

          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginBottom: '100px' }}>

            <Typography variant="body1">Didn't have an account?</Typography>
            <Link href="/signup" sx={{ fontSize: '1.6rem' }}>SignUp</Link>

          </Stack>
        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={6}>
          <Box
            component="img" sx={{ width: '90%', }} src={heroimg} alt="Hero"
          />

        </Grid>

      </Grid>
    </Container>


  );
};

export default ResetForgotPassword;
