import gqlWebClient from '../../apolloClient';
import { gql, useMutation, useQuery } from "@apollo/client";

/**
 * forgotPasswordService
 * @param {string} email 
 * @returns 
 */
export const forgotPasswordService = async function (email) {
    console.log("Inside forgotPasswordService()");
    const { data } = await gqlWebClient.mutate({
        mutation: gql`
          mutation requestForgotPassword($email: String!) {
            requestForgotPassword(data: { email: $email}) {
              code,
              message,
              http_status,
              data,
              errors { email }
            }
          }
        `,
        variables: {
          email: email
        }
    });
  
    console.log(data);
    return data;
}

/**
 * resetForgotPasswordService
 * @param {string} password 
 * @param {string} confirmPassword 
 * @param {string} passwordRecoveryToken 
 * @returns 
 */
export const resetForgotPasswordService = async function (password, confirmPassword, passwordRecoveryToken) {
    console.log("Inside resetForgotPasswordService()");
    const { data } = await gqlWebClient.mutate({
        mutation: gql`
          mutation resetForgotPassword($password: String!, $confirmPassword: String!, $passwordRecoveryToken: String!) {
            resetForgotPassword(data: { password: $password, confirmPassword: $confirmPassword, passwordRecoveryToken: $passwordRecoveryToken}) {
              code,
              message,
              http_status,
              data,
              errors { password, confirmPassword, passwordRecoveryToken }
            }
        }
        `,
        variables: {
          password: password,
          confirmPassword: confirmPassword,
          passwordRecoveryToken: passwordRecoveryToken
        }
    });
  
    console.log(data);
    return data;
}

/**
 * changePasswordService
 * @param {string} oldPassword 
 * @param {string} newPassword
 * @param {string} confirmNewPassword
 * @returns 
 */
export const changePasswordService = async function (oldPassword, newPassword, confirmNewPassword) {
    console.log("Inside changePasswordService()");
    const { data } = await gqlWebClient.mutate({
        mutation: gql`
          mutation changePassword($oldPassword: String!, $newPassword: String!, $confirmNewPassword: String!) {
            changePassword(data: { oldPassword: $oldPassword, newPassword: $newPassword, confirmNewPassword: $confirmNewPassword}) {
              code,
              message,
              http_status,
              data {
                token,
                user { id, first_name, last_name, phone, email, zip }
              },
              errors { oldPassword, newPassword, confirmNewPassword }
            }
          }
          `,
        variables: {
            oldPassword: oldPassword, 
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        }
    });

    console.log(data);
    return data;
}

export const GQL_LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(data: { email: $email, password: $password}) {
        token,
        user { id, email, first_name, last_name }
    }
  }
`;
