import React from 'react'
import { useAuthContext } from "../../../hooks/useAuthContext";
import { useSearchParams, useNavigate } from "react-router-dom";

export default function SocialLogin() {
    const navigate = useNavigate();
    const { dispatch } = useAuthContext();
    const [searchParams, setSearchParams] = useSearchParams();
    const token = searchParams.get("token");
    const first_name = searchParams.get("first_name");

    if (token) {
        const user = { first_name: first_name };
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        const payload = { token, user };
        console.log("payload", payload);
        dispatch({ type: "LOGIN", payload:  payload});
        navigate("/scoring-details/personal-details");
    } else {
        navigate("/login");
    }

    return (
        <div></div>
    )
}
