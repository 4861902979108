import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyDependentProtection = async function () {
  console.log("Inside getMyDependentProtection()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyDependentProtection {
          code
          message
          http_status
          data {
            id

            other_one_time_expenses
            exp_num_years_support_dependents
            exp_avg_annual_long_term_investment
            exp_annual_inflation_rate
            self_purchased_life_insurance_type
            self_purchased_life_insurance_amount
            employer_provided_life_insurance_amount
            have_homeowner_insurance
            have_health_insurance
            have_long_term_disability_ins
            have_short_term_disability_ins
            have_umbrella_policy
            have_power_of_attorney
            have_revocable_trust
            have_long_term_care_insurance
            have_will
            have_living_will

            added_on               
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            other_one_time_expenses
            exp_num_years_support_dependents
            exp_avg_annual_long_term_investment
            exp_annual_inflation_rate
            self_purchased_life_insurance_type
            self_purchased_life_insurance_amount
            employer_provided_life_insurance_amount
            have_homeowner_insurance
            have_health_insurance
            have_long_term_disability_ins
            have_short_term_disability_ins
            have_umbrella_policy
            have_power_of_attorney
            have_revocable_trust
            have_long_term_care_insurance
            have_will
            have_living_will
          }
        }
      }
    `,
    fetchPolicy: "no-cache"
  });
  console.log("getMyDependentProtection API Response is: ", data);
  return data;
};

export const addOrUpdateMyDependentProtection = async function (
  otherOneTimeExpenses,
  expNumYearsSupportDependents,
  expAnnualInflationRate,
  expAvgAnnualLongTermInvestment,
  selfPurchasedLifeInsuranceType,
  selfPurchasedLifeInsuranceAmount,
  employerProvidedLifeInsuranceAmount,
  haveHomeownerInsurance,
  haveHealthInsurance,
  haveLongTermDisabilityIns,
  haveShortTermDisabilityIns,
  haveUmbrellaPolicy,
  haveOwerOfAttorney,
  haveRevocableTrust,
  haveLongTermCareInsurance,
  haveWill,
  haveLivingWill
) {
  console.log(`otherOneTimeExpenses: ${otherOneTimeExpenses}`);
  console.log(`expNumYearsSupportDependents: ${expNumYearsSupportDependents}`);
  console.log(
    `selfPurchasedLifeInsuranceAmount: ${selfPurchasedLifeInsuranceAmount}`
  );
  console.log(
    `employerProvidedLifeInsuranceAmount: ${employerProvidedLifeInsuranceAmount}`
  );
  console.log(`haveHomeownerInsurance: ${haveHomeownerInsurance}`);
  console.log(`haveLongTermDisabilityIns: ${haveLongTermDisabilityIns}`);
  console.log(`haveShortTermDisabilityIns: ${haveShortTermDisabilityIns}`);
  console.log(`haveUmbrellaPolicy: ${haveUmbrellaPolicy}`);
  console.log(`haveOwerOfAttorney: ${haveOwerOfAttorney}`);
  console.log(`haveRevocableTrust: ${haveRevocableTrust}`);
  console.log(`haveLongTermCareInsurance: ${haveLongTermCareInsurance}`);
  console.log(`haveWill: ${haveWill}`);
  console.log(`haveLivingWill: ${haveLivingWill}`);

  console.log("Inside addOrUpdateMyDependentProtection()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addOrUpdateMyDependentProtection(
        $otherOneTimeExpenses: Int!
        $expNumYearsSupportDependents: Int!
        $expAvgAnnualLongTermInvestment: Int!
        $expAnnualInflationRate: Int!
        $selfPurchasedLifeInsuranceType: InsuranceType!
        $selfPurchasedLifeInsuranceAmount: Int!
        $employerProvidedLifeInsuranceAmount: Int!
        $haveHomeownerInsurance: Boolean!
        $haveHealthInsurance: Boolean!
        $haveLongTermDisabilityIns: Boolean!
        $haveShortTermDisabilityIns: Boolean!
        $haveUmbrellaPolicy: Boolean!
        $haveOwerOfAttorney: Boolean!
        $haveRevocableTrust: Boolean!
        $haveLongTermCareInsurance: Boolean!
        $haveWill: Boolean!
        $haveLivingWill: Boolean!
      ) {
        addOrUpdateMyDependentProtection(
          data: {
            other_one_time_expenses: $otherOneTimeExpenses
             exp_num_years_support_dependents: $expNumYearsSupportDependents
             exp_avg_annual_long_term_investment: $expAvgAnnualLongTermInvestment
             exp_annual_inflation_rate: $expAnnualInflationRate
             self_purchased_life_insurance_type: $selfPurchasedLifeInsuranceType
             self_purchased_life_insurance_amount: $selfPurchasedLifeInsuranceAmount
             employer_provided_life_insurance_amount: $employerProvidedLifeInsuranceAmount
             have_homeowner_insurance: $haveHomeownerInsurance
             have_health_insurance: $haveHealthInsurance
             have_long_term_disability_ins: $haveLongTermDisabilityIns
             have_short_term_disability_ins: $haveShortTermDisabilityIns
             have_umbrella_policy: $haveUmbrellaPolicy
             have_power_of_attorney: $haveOwerOfAttorney
             have_revocable_trust: $haveRevocableTrust
             have_long_term_care_insurance: $haveLongTermCareInsurance
             have_will: $haveWill
             have_living_will: $haveLivingWill             
          }
        ) {
          code
          message
          http_status
          data {
            id

            other_one_time_expenses
            exp_num_years_support_dependents
            exp_avg_annual_long_term_investment
            exp_annual_inflation_rate
            self_purchased_life_insurance_type
            self_purchased_life_insurance_amount
            employer_provided_life_insurance_amount
            have_homeowner_insurance
            have_health_insurance
            have_long_term_disability_ins
            have_short_term_disability_ins
            have_umbrella_policy
            have_power_of_attorney
            have_revocable_trust
            have_long_term_care_insurance
            have_will
            have_living_will
            added_on 
          }
          score {
            overAllScore
            debtScore
            savingsScore
            riskScore
          }
          errors {
            other_one_time_expenses
            exp_num_years_support_dependents
            exp_avg_annual_long_term_investment
            exp_annual_inflation_rate
            self_purchased_life_insurance_type
            self_purchased_life_insurance_amount
            employer_provided_life_insurance_amount
            have_homeowner_insurance
            have_health_insurance
            have_long_term_disability_ins
            have_short_term_disability_ins
            have_umbrella_policy
            have_power_of_attorney
            have_revocable_trust
            have_long_term_care_insurance
            have_will
            have_living_will
          }
        }
      }
    `,
    variables: {
      otherOneTimeExpenses: otherOneTimeExpenses,
      expNumYearsSupportDependents: expNumYearsSupportDependents,
      expAvgAnnualLongTermInvestment: expAvgAnnualLongTermInvestment,
      expAnnualInflationRate: expAnnualInflationRate,
      selfPurchasedLifeInsuranceType: selfPurchasedLifeInsuranceType,
      selfPurchasedLifeInsuranceAmount: selfPurchasedLifeInsuranceAmount,
      employerProvidedLifeInsuranceAmount: employerProvidedLifeInsuranceAmount,
      haveHomeownerInsurance: haveHomeownerInsurance,
      haveHealthInsurance: haveHealthInsurance,
      haveLongTermDisabilityIns: haveLongTermDisabilityIns,
      haveShortTermDisabilityIns: haveShortTermDisabilityIns,
      haveUmbrellaPolicy: haveUmbrellaPolicy,
      haveOwerOfAttorney: haveOwerOfAttorney,
      haveRevocableTrust: haveRevocableTrust,
      haveLongTermCareInsurance: haveLongTermCareInsurance,
      haveWill: haveWill,
      haveLivingWill: haveLivingWill,
    },
  });

  console.log("addOrUpdateMyDependentProtection API Response is: ", data);
  return data;
};
