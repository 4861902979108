import { Routes, Route } from "react-router-dom";

/* Home Page */
import { Home } from "../pages";

/* Auth Pages */
import { SignUp, ActivationNotification, Login, ForgotPassword, ResetForgotPassword, ChangePassword } from "../pages";

/* User Account Pages */
import { Account, DeleteAccount } from "../pages";

/* PFScores Pages */
import { FinancialHealth, ScoringDetails } from "../pages";

/* Site Related Pages */
import {  Faq, AboutUs, ContactUs, Disclaimer, PrivacyPolicy, Feedback } from "../pages";
import SocialLogin from "../pages/authPages/login/SocialLogin";

const Routing = () => {
  return (
    <Routes>
      {/* Home Page */}
      <Route path="/" element={<Home />} />
      
      {/* Auth Pages */}
      <Route path="/signup" element={<SignUp />} />
      <Route path="/login" element={<Login />} />
      <Route path="/login/social" element={<SocialLogin />} />
      <Route path="/activation-notification" element={<ActivationNotification />} />

      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-forgot-password" element={<ResetForgotPassword />} />
      <Route path="/change-password" element={<ChangePassword />} />

      {/* Account Pages */}
      <Route path="/account" element={<Account />} />
      <Route path="/account/confirm-delete" element={<DeleteAccount />} />

      {/* Scoring Pages */}
      <Route exact path="/scoring-details/:step" element={<ScoringDetails />} />
      <Route path="/financial-health" element={<FinancialHealth />} />

      {/* Site Pages */}
      <Route path="/faq" element={<Faq />} />
      <Route path="/disclaimer" element={<Disclaimer />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/feedback" element={<Feedback />} />

    </Routes>
  );
};

export default Routing;
