const siteConfigInitialState = {
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
};

export const SiteConfigReducer = (state = siteConfigInitialState, action) => {
  console.log(`siteConfigInitialState: ${siteConfigInitialState}`);
  switch (action.type) {
    case "screenWidth":
      return {
        ...state,
        screenWidth: (state.screenWidth = action.payload),
      };
    case "screenHeight":
      return {
        ...state,
        screenHeight: (state.screenHeight = action.payload),
      };

    default:
      return state;
  }
};
