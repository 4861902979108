import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyCollegeEducation = async function () {
  console.log("Inside getMyCollegeEducation()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyEducation {
          code
          message
          http_status
          data {
            id
            one_year_college_cost
            avg_annual_tuition_inflation
            children {
              id
              name_of_child
              age_of_child
              percent_of_cost_for_child
              amount_saved_so_far
              current_monthly_savings_for_child
              expected_annual_return_on_savings
              added_on
            }
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors
        }
      }
    `,
    fetchPolicy: "no-cache"
  });
  console.log("getMyCollegeEducation API Response is: ", data);
  return data;
};

export const updateMyCollegeEducation = async function (
  { id,
    one_year_college_cost,
    avg_annual_tuition_inflation,
    children }
) {

  const { data } = await gqlWebClient.mutate({
    mutation: gql`
    mutation addMyEducation($id: String!, $one_year_college_cost: Int!, $avg_annual_tuition_inflation: Int!, $children: [ChildCreateInput!]!) {
      updateMyEducation(data: {id: $id, one_year_college_cost: $one_year_college_cost, avg_annual_tuition_inflation: $avg_annual_tuition_inflation, children: $children}) {
        code
        message
        http_status
        data {
            id
            one_year_college_cost
            avg_annual_tuition_inflation
            children {
              id
              name_of_child
              age_of_child
              percent_of_cost_for_child
              amount_saved_so_far
              current_monthly_savings_for_child
              expected_annual_return_on_savings
              added_on
          }
        }
        score {
          overAllScore
          savingsScore
          debtScore
          riskScore
        }
        errors {
          one_year_college_cost
        }
      }
    }    
    `,
    variables: {
      id: id.toString(),
      one_year_college_cost: one_year_college_cost,
      avg_annual_tuition_inflation: avg_annual_tuition_inflation,
      children: children
    },
  });

  console.log("updateMyCollegeEducation API Response is: ", data);
  return data;
};

export const addMyCollegeEducation = async function (
  { one_year_college_cost,
    avg_annual_tuition_inflation,
    children }
) {

  console.log("Inside addMyCollegeEducation()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
    mutation addMyEducation($one_year_college_cost: Int!, $avg_annual_tuition_inflation: Int!, $children: [ChildCreateInput!]!) {
      addMyEducation(data: {one_year_college_cost: $one_year_college_cost, avg_annual_tuition_inflation: $avg_annual_tuition_inflation, children: $children}) {
        code
        message
        http_status
        data {
            id
            one_year_college_cost
            avg_annual_tuition_inflation
            children {
              id
              name_of_child
              age_of_child
              percent_of_cost_for_child
              amount_saved_so_far
              current_monthly_savings_for_child
              expected_annual_return_on_savings
              added_on
          }
        }
        score {
          overAllScore
          savingsScore
          debtScore
          riskScore
        }
        errors {
          one_year_college_cost
        }
      }
    }
    `,
    variables: {
      one_year_college_cost: one_year_college_cost,
      avg_annual_tuition_inflation: avg_annual_tuition_inflation,
      children: children
    },
  });

  console.log("addMyCollegeEducation API Response is: ", data);
  return data;
};

export const removeMyCollegeEducation = async function (id) {
  console.log("Inside removeMyCollegeEducation()");
  console.log(`id: ${id}`);
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation removeMyEducation($id: String!) {
        removeMyEducation(data: { id: $id }) {
          code
          message
          http_status
          data {
            id
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            id
          }
        }
      }
    `,
    variables: {
      id: id,
    },
  });

  console.log("removeMyCollegeEducation API Response is: ", data);
  return data;
};
