import * as React from 'react';
import "./ContactUs.css";
import { Container, Box, Checkbox, Button, Alert } from "@mui/material";

const ContactUs = () => {

  return (
    <>
      <section className="aboutUs">
        <Container maxWidth="lg">
          <span className='aboutUs'>
            <h1>Contact Us</h1>
          </span>
        </Container>
        <Container maxWidth="lg">
          <span>
          <h4>Contact Us: Page content goes here....</h4>
          </span>
        </Container>
      </section>
    </>
  );
}

export default ContactUs;