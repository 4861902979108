import React from 'react'
import { Box, Grid, } from "@mui/material";
import "./Card.css"

function Card(props) {
    const {img, personName, designation,content} = props;
  return (
    <Box className='card' >
    <Box className='pic' width={168} height={168} >
        <img src={img} alt='Keith profile' />
    </Box>
    <Box className='content-container'>
        <p className='name'>{personName}</p>
        <p className='designation'>{designation}</p>
    </Box>
    <p className='content'>{content}</p>

</Box>
  )
}

export default Card
