import React from "react";
import "../signup/SignUp.css";
import "../../../assets/css/style.css";
import { Container, Box, Checkbox, Button, Alert } from "@mui/material";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import AuthRightSection from '../../../components/authRightSection/AuthRightSection';
import AuthRightSectionMobile from '../../../components/authRightSection/AuthRightSectionMobile';
import { useAuthContext } from "../../../hooks/useAuthContext"

const ActivationNotification = () => {
  const { state } = useAuthContext()
    const notificationData = {
        "siteFromEmail": "donotreply@pfscores.com",
        "siteName": "PFScores"
    }
  const navigate = useNavigate();
  return (
    <>
      <section className="sign_up_main_wrapepr">
        <Container maxWidth="lg">
          <Box className="row">
            <Box className="left_section_sign">
              <Box className="signup__wrapper_left_section">
                <Box className="signup_heading">
                  <h2>Account Activation Notification</h2>
                </Box>
                <Box className="sigup_form">
                    <p>Dear <strong>{state.createdUser.user.first_name}</strong>,</p>
                    <p>Thank you for signing up. We have sent an email to your <strong>{state.createdUser.user.email}</strong> email address. 
                    This email was sent from the email address <strong>{notificationData.siteFromEmail}</strong> . 
                    Does your email configuration allow you to receive automated emails from <strong>{notificationData.siteName}</strong>? 
                    Certain junk and spam email filters may block the account activation email notification from your Inbox. 
                    Please activate your account by clicking on the account activation link in the email.
                    </p>
                    <p>Thank you and welcome!</p>
                </Box>
                <hr />
                <Box className="already_member">
                    <Box className="top_">
                    <p>Account already activated?</p>
                    <Link to="/login" >Log In</Link>
                    </Box>
                    <Box className="bottom_">
                    <p>Didn't receiver account activation Email?</p>
                    <Link to="#">Resend Email</Link>
                    </Box>
                </Box>
              </Box>
            </Box>
            <AuthRightSection />
          </Box>
        </Container>
        <AuthRightSectionMobile />
      </section>
    </>
  );
};

export default ActivationNotification;
