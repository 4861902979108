import React from "react";
import "../cashManagment/CashManagment.css";
import {
  Container,
  Box,
  Input,
  Tooltip,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
import PrevBUtton from "../prevButton/PrevBUtton";
import AbsButton from "../../components/absButton/AbsButton";
import { PFIFormConstants as PFIFC } from "../../constants/PFIFormConstants";
import { useDispatch, useSelector } from "react-redux";
import NumInput from "../numInput/NumInput";

import { useState } from "react";
import { useEffect } from "react";
import { useCollegeEducation } from "../../hooks/useCollegeEducation";
import { getMyCollegeEducation } from "../../apiServices/pfscoreForms/collegeEducation";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import { individualCeInitialState } from "../../store/reducer/CollegeEducationReducer";
import allActions from "../../store/actions";

const CollegeEducation = ({ next, pre }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const {
    addCollegeEducation,updateCollegeEducation
  } = useCollegeEducation();


  const datafromreducer = useSelector((state) => state.CollegeEducationReducer);
  const funcitonsformreducer = useDispatch();

  const _handleNextPage = async (e) => {
    e.preventDefault();
    const allChildren = datafromreducer.children;
    let children = [];
    
    for (let index = 0; index < allChildren.length; index++) {
      const childItem = allChildren[index];
     
        children.push(
          {name_of_child: childItem.name_of_child.toString(),
            age_of_child: parseInt(childItem.age_of_child),
            percent_of_cost_for_child:parseInt(childItem.percent_of_cost_for_child),
            amount_saved_so_far:parseInt(childItem.amount_saved_so_far),
            current_monthly_savings_for_child:parseInt(childItem.current_monthly_savings_for_child),
            expected_annual_return_on_savings:parseInt(childItem.expected_annual_return_on_savings),
            // total_monthly_contribution_required:parseInt(childItem.total_monthly_contribution_required) || 0,
            // additional_monthly_contribution_required:parseInt(childItem.additional_monthly_contribution_required) || 0,
          }
        );
      
    }


    setIsSaving(true);
   if (datafromreducer.id) {
    const updateResponse = await updateCollegeEducation({id:datafromreducer.id, one_year_college_cost:datafromreducer.one_year_college_cost,
      avg_annual_tuition_inflation:datafromreducer.avg_annual_tuition_inflation,
      children:children});
   }else{
      const updateResponse = await addCollegeEducation({one_year_college_cost:datafromreducer.one_year_college_cost,
        avg_annual_tuition_inflation:datafromreducer.avg_annual_tuition_inflation,
        children:children});
   }


    
    setIsSaving(false);

    next();
  };

  const _handleAddNew = async (e) => {
    console.log("inside _handleAddNew......");
    e.preventDefault();
    console.log(e);


    let defaultData = individualCeInitialState;
    defaultData.name_of_child = "Child " + (datafromreducer.children.length + 1);

    setIsSaving(true);

    const addData = [
      defaultData.name_of_child,
      parseInt(defaultData.age_of_child),
      parseInt(defaultData.percent_of_cost_for_child),
      parseInt(defaultData.amount_saved_so_far),
      parseInt(defaultData.current_monthly_savings_for_child),
      parseInt(defaultData.expected_annual_return_on_savings),
      // parseInt(defaultData.total_monthly_contribution_required),
      // parseInt(defaultData.additional_monthly_contribution_required)
    ];
    // const addResponse = await addCollegeEducation(addData);
    console.log("add nw education item Response is: ");
    //  console.log(addResponse);

    // if (addResponse && addResponse.id) {
    //   console.log("addCollegeEducation: API Success!!");
      // defaultData.cId = addResponse.id;
      funcitonsformreducer({
        type: "ADD",
        payload: {
          data: defaultData,
        },
      });
      funcitonsformreducer({
        type: "CE_NUM_CHILD",
        payload: {
          data: datafromreducer.ceNumChild + 1,
        },
      });
    // } else {
    //   // add operation failed - show error message to ui.
    //   console.log("addCollegeEducation: API Failed!!");
    // }

    setIsSaving(false);
  };

  const _handleRemove = async (e, itemIndex) => {
    e.preventDefault();

    if (datafromreducer.children.length === 0) {
      return;
    }

    if (itemIndex === undefined) {
      itemIndex = datafromreducer.children.length - 1;
    }

    const itemToRemove = datafromreducer.children[itemIndex];
    setIsSaving(true);

    if (itemToRemove && itemToRemove.cId) {
    } else {
      // only remove locally so no need to do anything here.
    }

    setIsSaving(false);
    console.log(`itemIndex is: ${itemIndex}`);
    funcitonsformreducer({
      type: "REMOVE",
      payload: {
        itemIndex: itemIndex,
        data: itemIndex,
      },
    });
    funcitonsformreducer({
      type: "CE_NUM_CHILD",
      payload: {
        data: datafromreducer.ceNumChild - 1,
      },
    });
  };

  const _handleprev = (e) => {
    e.preventDefault();
    pre();
  };

  const inputOnchange = (value, type, itemIndex) => {
    funcitonsformreducer({
      type: "UPDATE",
      payload: { variable: type, itemIndex: itemIndex, data: value },
    });
  };

  const handleNumChildChange = (value, type, itemIndex) => {
    const numPur = parseInt(value);
    let tempArr = [];
    for (let index = 0; index < numPur; index++) {
      tempArr.push(index + 1);
    }
    const purApp = tempArr;
    console.log(`numPur: ${numPur}`);
    console.log(`purApp: ${purApp}`);
    funcitonsformreducer({
      type: type,
      payload: {
        itemIndex: itemIndex,
        data: numPur,
      },
    });
    // funcitonsformreducer({
    //   type: "CE_EDUS",
    //   payload: purApp,
    // });


  };

  const getCurrentVal = (variable, itemIndex) => {

    if (itemIndex < 0) {
      return 0;
    }
    switch (variable) {
      // Savings for major purchases
      case "CE_NUM_CHILD":
        return datafromreducer.ceNumChild;
      case "CE_EDUS":
        return datafromreducer.children;
      case "CE_ONE_YEAR_COST":
        return datafromreducer.one_year_college_cost;
      case "CE_TUTION_INFLATION":
        return datafromreducer.avg_annual_tuition_inflation;

      // Individual Child Details
      case "CE_INDEX":
        return datafromreducer.children[itemIndex].cIndex;
      case "CE_ID":
        return datafromreducer.children[itemIndex].id;

      case "C_NAME":
        return datafromreducer.children[itemIndex].name_of_child;
      case "C_AGE":
        return datafromreducer.children[itemIndex].age_of_child;
      case "C_PERCENT_COST_INTENT":
        return datafromreducer.children[itemIndex].percent_of_cost_for_child;
      case "C_AMOUNT_SAVED":
        return datafromreducer.children[itemIndex].amount_saved_so_far;
      case "C_MONTHLY_SAVING":
        return datafromreducer.children[itemIndex].current_monthly_savings_for_child;
      case "C_EXPT_ANNUAL_RET":
        return datafromreducer.children[itemIndex].expected_annual_return_on_savings;

      // Calculated Values
      // case "C_EXPT_MONTHLY_CONTRIBUTION":
      //   return datafromreducer.children[itemIndex].total_monthly_contribution_required;
      // case "C_SAVING_NEEDED":
      //   return datafromreducer.children[itemIndex].additional_monthly_contribution_required;

      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 1, itemIndex) => {
    const currVal = getCurrentVal(variable, itemIndex);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: "UPDATE",
      payload: { itemIndex: itemIndex, data: finalVal, variable: variable },
    });
    // if(variable==="CE_NUM_CHILD") {
    //   handleNumChildChange(finalVal, variable)
    // }
  };

  const handleMinusClick = (variable, step = 1, itemIndex) => {
    const currVal = getCurrentVal(variable, itemIndex);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: "UPDATE",
      payload: { itemIndex: itemIndex, data: finalVal, variable: variable },
    });
    // if(variable==="CE_NUM_CHILD") {
    //   handleNumChildChange(finalVal, variable)
    // }
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      console.log("FETCHING INITIAL College education DATA!!!");
      // try {
      const response = await getMyCollegeEducation();
      const data = await response.getMyEducation.data;
      console.log(
        `College Educaion Values from backend API Response: ${JSON.stringify(
          data
        )}`
      );
      console.log(data);

      setIsLoaded(true);
      
    
      funcitonsformreducer({
        type: "INITIAL_SYNC",
        payload: { variable: "ALL", data: data },
      });

      funcitonsformreducer({
        type: "CE_NUM_CHILD",
        payload: {
          data: data.children.length,
        },
      });
      if (data.score && data.score.overAllScore) {
        funcitonsformreducer(
          allActions.scoreActions.UpdateScore({
            overAllScore: data.score.overAllScore,
            debtScore: data.score.debtScore,
            riskScore: data.score.riskScore,
            savingsScore: data.score.savingsScore,
            debugInfo: JSON.stringify(data.score),
          })
        );
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="cash_managment">
        <Container maxWidth="lg" className="cash_contain">
          <DetailsFormHeading
            title_text={"College Education"}
            subtitle_text={
              "The accuracy of your score is determined by the accuracy of your data."
            }
          />
          {/* 
          <PersonalFinancialIndex 
            audio_ogg_path={PFIFC.mp.audio_ogg_path} 
            audio_mp3_path={PFIFC.mp.audio_mp3_path} 
            />

          <Box className="row cash_manag">
            <Box className="cash_managment_sect">
              <h6>Step 4: College Education</h6>
            </Box>
          </Box>
        */}
          <Box className="cash_management_form">
            <form>
              {/* College Education */}
              {/* <Box className="row cash_manag">
                <Box className="cash_managment_sect">
                  <h6>College Education</h6>
                </Box>
              </Box> */}

              {/* Total Children */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                  <Typography variant="body1">Number of children yet to go to college</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("CE_NUM_CHILD")}
                      onChange={(e) =>
                        handleNumChildChange(e.target.value, "CE_NUM_CHILD")
                      }
                      onPlusClick={(e) => _handleAddNew(e)}
                      onMinusClick={(e) => _handleRemove(e)}
                      placeholder=""
                      displayCurrency={false}
                    />
                  </Box>
                </Box>
              </Box>
              {/* College Cost */}
              <Box
                className="mortage_wrapper web_view"
                
              >
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                  <Typography variant="body1">Total one year cost of college currently</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("CE_ONE_YEAR_COST")}
                      onChange={(e) =>
                        handleNumChildChange(e.target.value, "CE_ONE_YEAR_COST")
                      }
                      onPlusClick={() =>
                        handlePlusClick("CE_ONE_YEAR_COST", 1000)
                      }
                      onMinusClick={() =>
                        handleMinusClick("CE_ONE_YEAR_COST", 1000)
                      }
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
              </Box>

              {/* Expected Inflation */}
              <Box
                className="mortage_wrapper web_view"
               
              >
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                  <Typography variant="body1">Expected avg. annual tuition inflation</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("CE_TUTION_INFLATION")}
                      onChange={(e) =>
                        handleNumChildChange(
                          e.target.value,
                          "CE_TUTION_INFLATION"
                        )
                      }
                      onPlusClick={() =>
                        handlePlusClick("CE_TUTION_INFLATION", 1)
                      }
                      onMinusClick={() =>
                        handleMinusClick("CE_TUTION_INFLATION", 1)
                      }
                      placeholder=""
                      displayCurrency={false}
                    />
                  </Box>
                </Box>
              </Box>

              {datafromreducer.children.map((child, itemIndex) => (
                <Box key={itemIndex}>
                  <Box className="row cash_manag">
                    <Box className="cash_managment_sect">
                      <h6>
                        Child {itemIndex + 1}
                      </h6>
                    </Box>
                    <Box className="remove_button">
                      <Button
                        variant="contained"
                        data-item-key={itemIndex}
                        color="error"
                        size="small"
                        onClick={(e) => _handleRemove(e, itemIndex)}
                        sx={{height:"36px"}}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>

                  {/* Child Name */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                      <Typography variant="body1">Name of child</Typography>
                      </Box>
                      <Box className="right_section_cash">
                        {/* <Input /> */}
                        <TextField
                          value={getCurrentVal("C_NAME", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "C_NAME", itemIndex)
                          }
                          placeholder=""
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Child Age */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                      <Typography variant="body1">Age</Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("C_AGE", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "C_AGE", itemIndex)
                          }
                          onPlusClick={() =>
                            handlePlusClick("C_AGE", 1, itemIndex)
                          }
                          onMinusClick={() =>
                            handleMinusClick("C_AGE", 1, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Percentage of amount for this child */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                      <Typography variant="body1">
                          % of total cost you intend <br />
                          to pay for this child (%)
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal(
                            "C_PERCENT_COST_INTENT",
                            itemIndex
                          )}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "C_PERCENT_COST_INTENT",
                              itemIndex
                            )
                          }
                          onPlusClick={() =>
                            handlePlusClick(
                              "C_PERCENT_COST_INTENT",
                              1,
                              itemIndex
                            )
                          }
                          onMinusClick={() =>
                            handleMinusClick(
                              "C_PERCENT_COST_INTENT",
                              1,
                              itemIndex
                            )
                          }
                          placeholder=""
                          displayCurrency={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Amount saved for this child so far */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                      <Typography variant="body1">
                          Amount saved so far for <br />
                          this child's education
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("C_AMOUNT_SAVED", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "C_AMOUNT_SAVED",
                              itemIndex
                            )
                          }
                          onPlusClick={() =>
                            handlePlusClick("C_AMOUNT_SAVED", 100, itemIndex)
                          }
                          onMinusClick={() =>
                            handleMinusClick("C_AMOUNT_SAVED", 100, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* monthly saving for this child */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                      <Typography variant="body1">Current monthly saving for this child's education</Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("C_MONTHLY_SAVING", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "C_MONTHLY_SAVING",
                              itemIndex
                            )
                          }
                          onPlusClick={() =>
                            handlePlusClick("C_MONTHLY_SAVING", 100, itemIndex)
                          }
                          onMinusClick={() =>
                            handleMinusClick("C_MONTHLY_SAVING", 100, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Expected avg. annual return on these savings */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                      <Typography variant="body1">Expected avg. annual return on these savings (%) </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("C_EXPT_ANNUAL_RET", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "C_EXPT_ANNUAL_RET",
                              itemIndex
                            )
                          }
                          onPlusClick={() =>
                            handlePlusClick("C_EXPT_ANNUAL_RET", 1, itemIndex)
                          }
                          onMinusClick={() =>
                            handleMinusClick("C_EXPT_ANNUAL_RET", 1, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={false}
                        />
                      </Box>
                    </Box>
                  </Box>

                  {/* Monthly savings required */}
                  <Box
                    className="other_income web_view_mortage"
                    sx={{ display: "none" }}
                  >
                    <Box className="other_income_top">
                      <Box className="other_income_left">
                        <p>
                          Your expected monthly contribution for this
                          <br /> child should be approximately
                        </p>
                      </Box>
                      <p className="total_annual_value">
                        ${datafromreducer.total_monthly_contribution_required} (Todo -
                        calculation remaining)
                      </p>
                    </Box>
                  </Box>
                  {/* additional saving needed */}
                  <Box
                    className="other_income web_view_mortage"
                    sx={{ display: "none" }}
                  >
                    <Box className="other_income_top">
                      <Box className="other_income_left">
                        <p>
                          For this child you need to save monthly an additional
                        </p>
                      </Box>
                      <p className="total_annual_value">
                        ${datafromreducer.additional_monthly_contribution_required} (Todo - calculation
                        remaining)
                      </p>
                    </Box>
                  </Box>
                </Box>
              ))}

              {/* Buttons */}
              <br />
              <br />
              <Box className="buttons_section" sx={{ marginTop: "32px" }}>
                <PrevBUtton prev_name="Previous Page" onClick={_handleprev} />
                <PrevBUtton
                  prev_name="Add One More Child"
                  onClick={_handleAddNew}
                />
                <AbsButton title="Next Page" onClick={_handleNextPage} />
              </Box>
            </form>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default CollegeEducation;
