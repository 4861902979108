const nwInitialState = {
    // Lifestyle Assets
    laPrimaryHome: 1,
    laCarAndOthers: 1,
    laPersonalValuables: 1,
    laAnyOtherDesc: '',
    laAnyOther: 1,

    // Savings and Investments
    siTotal: 0,
    siCash: 0,
    siRetirementAc: 0,
    siBrokerageAc: 0,

    // Liabilities
    isMortgagePrimaryHome: 'No',
    mortgageInterestRate: 0,
    mortgageYearsLeft: 0,
    mortgageType: 'Adjustable',
    mortgageYearsTotal: '15',
    lMortgage: 0,
    lCarsOthers: 0,
    lCcDebtLoan: 0,
    lAnyOtherDesc: '',
    isOtherLoan: 'No',
    lAnyOther: 0,
};

export const NetWorthReducer = (state = nwInitialState, action) => {
    switch (action.type) {

        // Lifestyle Assets
        case "LA_PRIMARY_HOME":
            return {
                ...state,
                laPrimaryHome: (state.laPrimaryHome = action.payload),
            };
        case "LA_CAR_AND_OTHERS":
            return {
                ...state,
                laCarAndOthers: (state.laCarAndOthers = action.payload),
            };
        case "LA_PERSONAL_VALUABLES":
            return {
                ...state,
                laPersonalValuables: (state.laPersonalValuables = action.payload),
            };
        case "LA_ANY_OTHER_DESC":
            return {
                ...state,
                laAnyOtherDesc: (state.laAnyOtherDesc = action.payload),
            };

        case "LA_ANY_OTHER":
            return {
                ...state,
                laAnyOther: (state.laAnyOther = action.payload),
            };

        // Savings and Investments
        case "SI_TOTAL":
            return {
                ...state,
                siTotal: (state.siTotal = action.payload),
            };
        case "SI_CASH":
            return {
                ...state,
                siCash: (state.siCash = action.payload),
            };
        case "SI_RETIREMENT_AC":
            return {
                ...state,
                siRetirementAc: (state.siRetirementAc = action.payload),
            };
        case "SI_BROKERAGE_AC":
            return {
                ...state,
                siBrokerageAc: (state.siBrokerageAc = action.payload),
            };

        // Liabilities 
        case "IS_MORTGAGE_PRIMARY_HOME":
            return {
                ...state,
                isMortgagePrimaryHome: (state.isMortgagePrimaryHome = action.payload),
            };
        case "MORTGAGE_INTEREST_RATE":
            return {
                ...state,
                mortgageInterestRate: (state.mortgageInterestRate = action.payload),
            };
        case "MORTGAGE_YEARS_LEFT":
            return {
                ...state,
                mortgageYearsLeft: (state.mortgageYearsLeft = action.payload),
            };
        case "MORTGAGE_TYPE":
            return {
                ...state,
                mortgageType: (state.mortgageType = action.payload),
            };
        case "MORTGAGE_YEARS_TOTAL":
            return {
                ...state,
                mortgageYearsTotal: (state.mortgageYearsTotal = action.payload),
            };
        case "L_MORTGAGE":
            return {
                ...state,
                lMortgage: (state.lMortgage = action.payload),
            };
        case "L_CARS_OTHERS":
            return {
                ...state,
                lCarsOthers: (state.lCarsOthers = action.payload),
            };
        case "L_CC_DEBT_LOAN":
            return {
                ...state,
                lCcDebtLoan: (state.lCcDebtLoan = action.payload),
            };
        case "L_ANY_OTHER_DESC":
            return {
                ...state,
                lAnyOtherDesc: (state.lAnyOtherDesc = action.payload),
            };
        case "IS_OTHER_LOAN":
            return {
                ...state,
                isOtherLoan: (state.isOtherLoan = action.payload),
            };
        case "L_ANY_OTHER":
            return {
                ...state,
                lAnyOther: (state.lAnyOther = action.payload),
            };


        case "ALL_NW":
            return {
                ...state,

                laPrimaryHome: (state.laPrimaryHome = action.payload.laPrimaryHome),
                laCarAndOthers: (state.laCarAndOthers = action.payload.laCarAndOthers),
                laPersonalValuables: (state.laPersonalValuables = action.payload.laPersonalValuables),
                laAnyOtherDesc: (state.laAnyOtherDesc = action.payload.laAnyOtherDesc),
                laAnyOther: (state.laAnyOther = action.payload.laAnyOther),

                siTotal: (state.siTotal = action.payload.siTotal),
                siCash: (state.siCash = action.payload.siCash),
                siRetirementAc: (state.siRetirementAc = action.payload.siRetirementAc),
                siBrokerageAc: (state.siBrokerageAc = action.payload.siBrokerageAc),

                isMortgagePrimaryHome: (state.isMortgagePrimaryHome = action.payload.isMortgagePrimaryHome),
                mortgageInterestRate: (state.mortgageInterestRate = action.payload.mortgageInterestRate),
                mortgageYearsLeft: (state.mortgageYearsLeft = action.payload.mortgageYearsLeft),
                mortgageType: (state.mortgageType = action.payload.mortgageType),
                mortgageYearsTotal: (state.mortgageYearsTotal = action.payload.mortgageYearsTotal),
                lMortgage: (state.lMortgage = action.payload.lMortgage),
                lCarsOthers: (state.lCarsOthers = action.payload.lCarsOthers),
                lCcDebtLoan: (state.lCcDebtLoan = action.payload.lCcDebtLoan),
                lAnyOtherDesc: (state.lAnyOtherDesc = action.payload.lAnyOtherDesc),
                isOtherLoan: (state.isOtherLoan = action.payload.isOtherLoan),
                lAnyOther: (state.lAnyOther = action.payload.lAnyOther),
            };

        default:
            return state;
    }
};
