import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyPersonalDetails = async function () {
  console.log("Inside getMyPersonalDetails()");
  const { data, loading, error } = await gqlWebClient.query({
    query: gql`
      query {
        getMyPersonalDetails {
          code
          message
          http_status
          data {
            id
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
          }
        }
      }
    `,
    fetchPolicy: "no-cache" 
  });
  console.log("getMyPersonalDetails API Response is: ", data);
  console.log("getMyPersonalDetails API Response error is: ", error);

  return data;
};

export const addOrUpdatePersonalDetails = async function (
  planToSaveForCollege,
  ownHome,
  ownAdditionalProperties,
  ownBusiness,
  planForMajorPurchase,
  annualHouseholdIncome,
  valueOfInvestments
) {
  console.log(`planToSaveForCollege: ${planToSaveForCollege}`);
  console.log(`ownHome: ${ownHome}`);
  console.log(`ownAdditionalProperties: ${ownAdditionalProperties}`);
  console.log(`ownBusiness: ${ownBusiness}`);
  console.log(`planForMajorPurchase: ${planForMajorPurchase}`);
  console.log(`annualHouseholdIncome: ${annualHouseholdIncome}`);
  console.log(`valueOfInvestments: ${valueOfInvestments}`);

  console.log("Inside addOrUpdatePersonalDetails()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addOrUpdatePersonalDetails(
        $planToSaveForCollege: Boolean!
        $ownHome: Boolean!
        $ownAdditionalProperties: Boolean!
        $ownBusiness: Boolean!
        $planForMajorPurchase: Boolean!
        $annualHouseholdIncome: Int!
        $valueOfInvestments: Int!
      ) {
        addOrUpdatePersonalDetails(
          data: {
            plan_to_save_for_college: $planToSaveForCollege
            own_home: $ownHome
            own_additional_properties: $ownAdditionalProperties
            own_business: $ownBusiness
            plan_for_major_purchase: $planForMajorPurchase
            annual_household_income: $annualHouseholdIncome
            value_of_investments: $valueOfInvestments
          }
        ) {
          code
          message
          http_status
          data {
            id
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
          }
        }
      }
    `,
    variables: {
      planToSaveForCollege: planToSaveForCollege,
      ownHome: ownHome,
      ownAdditionalProperties: ownAdditionalProperties,
      ownBusiness: ownBusiness,
      planForMajorPurchase: planForMajorPurchase,
      annualHouseholdIncome: annualHouseholdIncome,
      valueOfInvestments: valueOfInvestments,
    },
  });

  console.log("addOrUpdatePersonalDetails API Response is: ", data);
  return data;
};
