import React, { useId } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const GradientRadioButtonUncheckedIcon = () => {
  const id = useId();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9.5" fill="white" stroke={"url(#" + id + ")"} />
      <defs>
        <linearGradient id={id} x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C70FC" stopOpacity="0.3" />
          <stop offset="1" stopColor="#5BA8F6" />
        </linearGradient>
      </defs>
    </svg>
  )
}

const GradientRadioButtonCheckedIcon = () => {
  const id = useId();
  const id1 = useId();
  return (

    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <circle cx="10" cy="10" r="9.5" fill="white" stroke={"url(#" + id + ")"} />
      <circle cx="10" cy="10" r="6" fill={"url(#" + id1 + ")"} />
      <defs>
        <linearGradient id={id} x1="0" y1="10" x2="20" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C70FC" stopOpacity="0.3" />
          <stop offset="1" stopColor="#5BA8F6" />
        </linearGradient>
        <linearGradient id={id1} x1="4" y1="10" x2="16" y2="10" gradientUnits="userSpaceOnUse">
          <stop stopColor="#26D878" />
          <stop offset="1" stopColor="#50EFB6" />
        </linearGradient>
      </defs>
    </svg>

  )
}

const RadioComponent = (props) => {
  const { label1, label2, value1, value2, name, options, handleRadioOption, defaultValue } = props;

  return (

    <RadioGroup
      row
      sx={{justifyContent: 'center', marginTop: {xs:'25px', sm:'auto'}}}
      aria-labelledby="demo-radio-buttons-group-label"
      // defaultValue={defaultValue? defaultValue: 'No'}
      value={defaultValue ? defaultValue : 'No'}
      name="radio-buttons-group"
      className="radio_wrapper"
    >
      <FormControlLabel
        value={value1}
        name={options}
        control={<Radio icon={<GradientRadioButtonUncheckedIcon />} checkedIcon={<GradientRadioButtonCheckedIcon />} />}
        label={label1}
        className="radio_option1"
        onChange={handleRadioOption}
        sx={{marginRight:"50px"}}
      />
      <FormControlLabel
        value={value2}
        name={options}
        control={<Radio icon={<GradientRadioButtonUncheckedIcon />} checkedIcon={<GradientRadioButtonCheckedIcon />} />}
        label={label2}
        onChange={handleRadioOption}
      />
    </RadioGroup>

  );
};

export default RadioComponent;
