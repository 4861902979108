import React from "react";
import { Container, Box, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import { usePassword } from '../../../hooks/usePassword';
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import heroimg from "../../../assets/images/login.png";

const ChangePassword = () => {
  const { changePassword, isLoading, error, isSuccess } = usePassword();

  const validationSchema = Yup.object({
    oldPassword: Yup.string('Enter your old password')
      .min(8, 'Password must be at least 8 characters')
      .max(255, 'Password length must be smaller than 255 characters')
      .required('Old password is required.'),
    newPassword: Yup.string('Enter your new password')
      .min(8, 'New password must be at least 8 characters')
      .max(255, 'New password length must be smaller than 255 characters')
      .required('New password is required.'),
    confirmNewPassword: Yup.string('Please confirm your new password')
      .min(8, 'Confirm new password must be at least 8 characters')
      .max(255, 'Confirm new password length must be smaller than 255 characters')
      .required('Confirm new password field is required.'),
  });

  const formikVar = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      console.log(values);
      const authResponse = await changePassword(values['oldPassword'], values['newPassword'], values['confirmNewPassword'])
      if (authResponse) {
        console.log('authResponse: ', authResponse);
        // Todo: Log out the user and redirect to login page
        // navigate("/scoring-details/personal-details");
      }
    },
  });

  return (

    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Grid container   >
        <Grid item xs={12} md={6}>
          <Typography variant="h1">Change Password</Typography>


          <form onSubmit={formikVar.handleSubmit}>
            <Box className="sigup_form">

             
                <TextField
                  fullWidth
                  id="oldPassword"
                  name="oldPassword"
                  placeholder="Old password"
                  label="Old password"
                  type="password"
                  variant="outlined"
                  value={formikVar.values.oldPassword}
                  onChange={formikVar.handleChange}
                  error={formikVar.touched.oldPassword && Boolean(formikVar.errors.oldPassword)}
                  helperText={formikVar.touched.oldPassword && formikVar.errors.oldPassword}
                  margin="normal"
                />
             
                <TextField
                  fullWidth
                  id="newPassword"
                  name="newPassword"
                  placeholder="New password"
                  label="New password"
                  type="password"
                  variant="outlined"
                  value={formikVar.values.newPassword}
                  onChange={formikVar.handleChange}
                  error={formikVar.touched.newPassword && Boolean(formikVar.errors.newPassword)}
                  helperText={formikVar.touched.newPassword && formikVar.errors.newPassword}
                  margin="normal"
                />
              
                <TextField
                  fullWidth
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Confirm New password"
                  label="Confirm New Password"
                  type="password"
                  variant="outlined"
                  value={formikVar.values.confirmNewPassword}
                  onChange={formikVar.handleChange}
                  error={formikVar.touched.confirmNewPassword && Boolean(formikVar.errors.confirmNewPassword)}
                  helperText={formikVar.touched.confirmNewPassword && formikVar.errors.confirmNewPassword}
                  margin="normal"
                />
              

              <Box className="buttons_section">
                <Button className="sign_up_button" type="submit" fullWidth endIcon={<ArrowForwardIcon />}
                  sx={{ marginTop: '40px', color: '#102691', backgroundImage: 'linear-gradient(267deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)' }}>
                  Save
                </Button>

              </Box>

            </Box>
          </form>
          <Divider sx={{ marginTop: '50px', marginBottom: '100px' }} />
        </Grid>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={6}>
          <Box
            component="img" sx={{ width: '90%', }} src={heroimg} alt="Hero"
          />

        </Grid>

      </Grid>
    </Container>


  );
};

export default ChangePassword;
