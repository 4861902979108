import React from "react";
import ReactDOM from "react-dom/client";
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
// import { onError } from "@apollo/client/link/error";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
// import { DataProvider } from "./content/rootReducer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContextProvider } from "./context/AuthContext";

import { Provider } from "react-redux";
import { store } from "./store/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const client = new ApolloClient({
  uri: 'http://localhost:3000/v1',
  cache: new InMemoryCache(),
});
//  'http://localhost:3000/v1',


root.render(
  <ApolloProvider client={client}>
      <React.StrictMode>
        <BrowserRouter>
          {/* <DataProvider> */}
          <Provider store={store}>
    <AuthContextProvider>
            <App />
    </AuthContextProvider>
          {/* </DataProvider> */}
          </Provider>
        </BrowserRouter>
      </React.StrictMode>
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
