import React from "react";
import "../../authPages/signup/SignUp.css";
import "../../../assets/css/style.css";
import { Container, Box, Checkbox, Button, Alert } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useDeleteAccount } from '../../../hooks/useDeleteAccount';
import { AuthConstants } from '../../../constants/AuthConstants';

const DeleteAccount = () => {
  const { deleteaccount, isLoading, error} = useDeleteAccount();
  const navigate = useNavigate();
  
  const label = { inputProps: { "aria-label": "Checkbox" } };
  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Invalid email address")
      .required("Email is required."),
    password: Yup.string("Enter your password")
      .min(4, "Password must be at least 8 characters")
      .required("Password is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      console.log(values);
      const authResponse = await deleteaccount( values['email'], values['password'])
      if (authResponse)
      {
        console.log('authResponse: ', authResponse);
        navigate("/");
      } else {
        console.log("Show Errors - Alert")
      }
    },
  });

  return (
    <>
      <section className="sign_up_main_wrapepr">
        <Container maxWidth="lg">
          <Box className="row">
            <Box className="left_section_sign">
              <Box className="signup__wrapper_left_section">
                <Box className="signup_heading">
                  <h2>Delete Account</h2>
                </Box>
                <form onSubmit={formik.handleSubmit}>
                  <Box className="sigup_form">
                    <Box>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        margin="normal"
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        id="password"
                        name="password"
                        placeholder="Enter your password"
                        label="Password"
                        type="password"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        margin="normal"
                      />
                    </Box>

                    <Box className="buttons_section">
                      <Button className="sign_up_button" type="submit">
                        Delete My Account{" "}
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Box>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default DeleteAccount;
