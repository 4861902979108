import React from "react";
import { Box } from "@mui/material";
import "./PersonalFinancialIndex.css";
const PersonalFinancialIndex = (props) => {
  return (
    <>
      <Box className="personal_column_">
        <h4>Personal Financial Score &#174; (PFScore)</h4>
        <Box className="audio_section">
          <audio controls>
            <source src={props.audio_ogg_path} type="audio/ogg" />
            <source src={props.audio_mp3_path} type="audio/mpeg" />
          </audio>
        </Box>
      </Box>
    </>
  );
};

export default PersonalFinancialIndex;
