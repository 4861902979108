import * as React from "react";
import { useRef, useState } from "react";
import "./Feedback.css";
import PropTypes from 'prop-types';
import { Container, Grid, Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Rating from "@mui/material/Rating";
import imgFaq from "../../../assets/images/faq-page.png";
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'
import { addFeedback } from '../../../apiServices/pfscoreForms/feedback'


export default function FeedbackPage() {

  async function handleClick() {
    setIsValidated(false)
    setIsError(false)
    if (likesRef.current.value === "" && dislikeRef.current.value === "" && commentRef.current.value === "" && selectedRating === 0) {
      setIsValidated(true)
      return
    }
    const response = await addFeedback("", "", [{
      "response": likesRef.current.value,
      "questionId": 1
    }, {
      "response": dislikeRef.current.value,
      "questionId": 2
    }, {
      "response": selectedRating.toString(),
      "questionId": 3
    }, {
      "response": commentRef.current.value,
      "questionId": 4
    }])

    if (response.addFeedback.http_status === 200) {
      likesRef.current.value = ""
      dislikeRef.current.value = ""
      commentRef.current.value = ""
      setSelectedRating(0)
      setIsSubmitted(true);
    }
    else {
      setIsError(true)
    }



  }

  function CustomSquareButton({ number, backgroundColor, selectedRating }) {

    console.log("selectedRating", selectedRating)
    return (
      <Button
        variant={number === selectedRating ? "outlined" : "contained"}
        sx={{
          height: { xs: 30, sm: 30, md: 40 },
          width: { xs: 30, sm: 60, md: 60 },
          minWidth: 'unset',
          backgroundColor: backgroundColor,
          color: "white",
          margin: { xs: "3px", sm: "3px" , md: "5px"},
          padding: 0,
          fontSize: { xs: '1rem', sm: '1rem', md: '1rem' },
          border: number === selectedRating ? "solid 2px grey" : "solid 2px transparent"
        }}

      >
        {number}
      </Button>
    )
  }

  const likesRef = useRef(null);
  const dislikeRef = useRef(null);
  const commentRef = useRef(null);
  const [selectedRating, setSelectedRating] = React.useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isValidated, setIsValidated] = useState(false)
  const customButtons = {
    1: {
      button: <CustomSquareButton number={1} backgroundColor="#7C0A02" selectedRating={selectedRating} />,
      label: 'Very Dissatisfied'
    },
    2: {
      button: <CustomSquareButton number={2} backgroundColor="#C21807" selectedRating={selectedRating} />,
      label: 'Very Dissatisfied'
    },
    3: {
      button: <CustomSquareButton number={3} backgroundColor="#fe1b1b" selectedRating={selectedRating} />,
      label: ' Dissatisfied'
    },
    4: {
      button: <CustomSquareButton number={4} backgroundColor="#FF4500" selectedRating={selectedRating} />,
      label: ' Dissatisfied'
    },
    5: {
      button: <CustomSquareButton number={5} backgroundColor="#FF8C00" selectedRating={selectedRating} />,
      label: ' Neutral'
    },
    6: {
      button: <CustomSquareButton number={6} backgroundColor="#FFA500" selectedRating={selectedRating} />,
      label: ' Neutral'
    },
    7: {
      button: <CustomSquareButton number={7} backgroundColor="#98FB98" selectedRating={selectedRating} />,
      label: ' Satisfied'
    },
    8: {
      button: <CustomSquareButton number={8} backgroundColor="#90EE90" selectedRating={selectedRating} />,
      label: ' Satisfied'
    },
    9: {
      button: <CustomSquareButton number={9} backgroundColor="#32CD32" selectedRating={selectedRating} />,
      label: 'Very Satisfied'
    },
    10: {
      button: <CustomSquareButton number={10} backgroundColor="#006400" selectedRating={selectedRating} />,
      label: 'Very Satisfied'
    },
  }

  function ButtonContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customButtons[value].button}</span>;
  }
  ButtonContainer.propTypes = {
    value: PropTypes.number.isRequired,
  }

  const handleRatingClick = (event, newValue) => {
    setSelectedRating(newValue)
    console.log(newValue)
  }

  return (


    <Container maxWidth="lg" className="feedback">
      <BackDropEffect />
      <BackLink />

      <Grid container spacing={2}>
        <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={4}>
          <img src={imgFaq} alt="FAQ" />
        </Grid>



        <Grid item xs={12} md={8} sx={{ marginBottom: "100px" }}>

          <Typography variant="h1">
            Feedback
          </Typography>

          <div >
            <Typography variant="subtitle1">
              What did you like about PFScores app?
            </Typography>
            <TextField
              multiline
              rows={3}
              variant="outlined"
              inputRef={likesRef}
              fullWidth
            />
          </div>

          <div>
            <Typography variant="subtitle1">
              What didn't you like about PFScores app?
            </Typography>
            <TextField
              multiline
              rows={3}
              variant="outlined"
              inputRef={dislikeRef}
              fullWidth
            />
          </div>
          <div>
            <Typography variant="subtitle1">
              How likely are you to recommend PFScores to a friend?
            </Typography>
            <Rating
              name="highlight-selected-only"
              defaultValue={6}
              max={10}
              IconContainerComponent={ButtonContainer}
              getLabelText={(value) => customButtons[value].label}
              onChange={handleRatingClick}
              highlightSelectedOnly
              />



              <div className="like" >
              <Typography variant="caption">Not likely at all</Typography>
              <Typography variant="caption">Neutral</Typography>
              <Typography variant="caption" >Extremely likely</Typography>
            </div>
          </div>
          <div>
            <Typography variant="subtitle1">Comments</Typography>
            <TextField
              multiline
              rows={6}
              variant="outlined"
              inputRef={commentRef}
              fullWidth
            />
          </div>
          <div>
            {isSubmitted && <Typography variant="body1">Thank you for your feedback</Typography>}
            {isValidated && <Typography variant="body1" sx={{ color: "red" }}>Please fill at least one field</Typography>}
            {isError && <Typography variant="body1" sx={{ color: "red" }}>There is an error submitting the feedback. Please try again later.</Typography>}
            <Button className="btn-submit" onClick={handleClick}>
              Submit
            </Button>
          </div>

        </Grid>
      </Grid>
    </Container>


  );
}
