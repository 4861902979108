import { useContext, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyNetWorth, addOrUpdateMyNetWorth } from '../apiServices/pfscoreForms/netWorth';

import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

export const useNetWorth= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const loadNetWorth = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyNetWorth()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadNetWorth Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('netWorth', response.getMyNetWorth.data);
            setIsLoading(false);
            console.log("Feteched Initial Data for NetWorth!!!");
        }
    }

    const saveNetWorth = async (updateData) => {
        setIsLoading(true);
        setError(null);
        // marketValuePrimaryHome, marketValueCarsMajorItems, marketValuePersonalValuables, anyOtherAssetsDescription, anyOtherAssetsAmount, cashSavings, currentValueRetirementAccounts, valueAllBrokerageAccounts, mortgageBalancePrimaryHome, loanBalanceCarsOtherMajorItems, creditCardDebtLoanBalance, anyOtherLoansDescription, anyOtherLoansAmount
        const response = await addOrUpdateMyNetWorth(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.addOrUpdateMyNetWorth.http_status!==200) {
            isSuccess = false;
            setError(response.addOrUpdateMyNetWorth.message);
        } else {
            isSuccess = true;
            setError(response.addOrUpdateMyNetWorth.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.addOrUpdateMyNetWorth.message);
            console.log("saveNetWorth Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('netWorth', response.addOrUpdateMyNetWorth.data);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved NetWorth details!!!");
        }
        return isSuccess
    }

    return {loadNetWorth, saveNetWorth, isLoading, error}
}