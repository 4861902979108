import * as React from "react";
import {
  Container,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel, { stepLabelClasses } from "@mui/material/StepLabel";
// import Button from "@mui/material/Button";

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import imgDisclosures from "../../../assets/images/disclosures-img.png";
import Divider from '@mui/material/Divider';
import { BackDropEffect } from '../../../components/backDropEffect/BackDropEffect'
import BackLink from '../../../components/backLink/BackLink'

const Disclaimer = () => {

  const steps = [
    {
      label: "01",
      description: "PFScores does not consider the effect of taxes on current retirement savings balances, contribution, and withdrawals during retirement. As a result, the required savings may be significantly different when taxes are accounted for.",
    },
    {
      label: "02",
      description: "It is assumed your current and future expenses will not fluctuate from now until retirement.",
    },
    {
      label: "03",
      description: "The investment rate of return remains the same throughout the plan period. Investment returns will fluctuate depending on the economic environment and market conditions.",
    },
    {
      label: "04",
      description: "The inflation rate remains the same throughout the plan period. Inflation rates vary from year to year.",
    },
    {
      label: "05",
      description: "The PFScores benchmark is not suitable for all ages. Users not working and who are in retirement may not get a good representation of their financial health.",
    },
  ];

  const ColorLibConnector = styled(StepConnector)(({ theme }) => ({
    marginLeft: 35,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 18,
    },
    [`&.${stepConnectorClasses.disabled}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(270deg, #3479FB -4.01%, #4EEEB3 89.75%)',
        borderWidth: 0,
      },
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(270deg, #3479FB -4.01%, #4EEEB3 89.75%)',
        borderWidth: 0,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient(270deg, #3479FB -4.01%, #4EEEB3 89.75%)',
        borderWidth: 0,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      width: 5,
      [theme.breakpoints.down("sm")]: {
        width: 3,
      }
    },
  }));

  const ColorLibStepIconRoot = styled('div')(({ theme }) => ({
    background: 'linear-gradient(white, white) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box',
    zIndex: 1,
    width: 70,
    height: 70,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    border: '4px solid transparent',
    [theme.breakpoints.down("lg")]: {
      width: 60,
      height: 60,
    },
    [theme.breakpoints.down("sm")]: {
      width: 36,
      height: 36,
    },
  }));

  function ColorLibStepIcon(props) {
    const { active, completed, className } = props;
    const GradientText = styled('span')(({ theme }) => ({
      background: 'linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      fontSize: '3.0rem',
      fontWeight: 600,
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      }
    }))

    const LabelConnector = styled(Box)(({ theme }) => ({
      height: 'calc(100% - 78px)', 
      width: '5px', 
      marginLeft: '35px', 
      backgroundImage: 'linear-gradient(270deg, #3479FB -4.01%, #4EEEB3 89.75%)',
      [theme.breakpoints.down("lg")]: {
        height: 'calc(100% - 68px)',
      },
      [theme.breakpoints.down("sm")]: {
        width: 3,
        marginLeft: '18px', 
        height: 'calc(100% - 44px)',
      },
    }))

    return (<>
      <ColorLibStepIconRoot ownerState={{ completed, active }} className={className}>
        <GradientText>
          0{props.icon}
        </GradientText>
      </ColorLibStepIconRoot>
      {!active && <LabelConnector/>}
    </>
    );
  }

  const StyledStepLabel = styled(StepLabel)({
    padding: 0,
    alignItems: "stretch",
    [`& .${stepLabelClasses.labelContainer}`]: {
      display: "inline-flex",
      alignItems: "center"
    },
    [`& .${stepLabelClasses.label}`]: {
      fontSize: '1.6rem',
      lineHeight: '28px',
      color: '#000',
      fontWeight: 400,
      marginLeft: "20px",
      [`& .${stepLabelClasses.completed}`]: {
        color: '#000',
        fontWeight: 400,
        
      },
    },
    [`& .${stepLabelClasses.iconContainer}`]: {
      flexDirection: "column"
    }
  })

  return (
    <>

      <Container sx={{ position: "relative" }} maxWidth="lg">
        <BackDropEffect />
        <BackLink />
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Typography variant="h1">Disclosures</Typography>
            <Typography variant="body1" sx={{marginBottom: "40px"}}>
              PFScores is a proprietary benchmark to measure the overall
              financial health of an individual or family. This benchmark
              is the property of PFScores. It considers a user’s income,
              expenses, savings discipline towards future financial goals,
              and financial risk management., to generate a personalized
              score. This benchmark is designed to be wealth level neutral
              and represents current state only. Information in this report is informational only and cannot be be construed as financial, investment, or tax advice or guidance.
            </Typography>
            <Typography variant="h2">
              This report is based on several assumptions.
            </Typography>
            <Stepper orientation="vertical" activeStep={4} connector={<ColorLibConnector />}>
              {steps.map((step, index) => (
                <Step key={index} >
                  <StyledStepLabel StepIconComponent={ColorLibStepIcon}>{step.description}</StyledStepLabel>

                </Step>
              ))}
            </Stepper>
            <Divider sx={{ marginTop: '50px', marginBottom: '30px' }} />
            <Typography variant="h4" sx={{ marginBottom: '100px' }}>
              The PFScores score and report is based on data you provided
              and, as such, may not be suitable for making financial
              decisions.
            </Typography>
          </Grid>
          <Grid item sx={{ display: { xs: 'none', md: 'block' } }} md={4}>
            <img src={imgDisclosures} alt="Disclosures" />
          </Grid>

        </Grid>


      </Container>

    </>
  );
};

export default Disclaimer;
