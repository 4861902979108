export const STEPS = {
    PERSONAL_DETAILS: 'personal-details',
    NET_WORTH: 'net-worth',
    CASH_MANAGEMENT: 'cash-management',
    RETIREMENT: 'retirement',
    COLLEGE_EDUCATION: 'college-education',
    MAJOR_PURCHASES: 'major-purchases',
    DEPENDENT_PROTECTION: 'dependent-protection',
    FINANCIAL_HEALTH: 'financial-health'
};

export const ALL_STEPS = [
    STEPS.PERSONAL_DETAILS,
    STEPS.NET_WORTH,
    STEPS.CASH_MANAGEMENT,
    STEPS.RETIREMENT,
    STEPS.COLLEGE_EDUCATION,
    STEPS.MAJOR_PURCHASES,
    STEPS.DEPENDENT_PROTECTION,
    STEPS.FINANCIAL_HEALTH
];

export const getStepNo = function (stepString) {
    switch (stepString) {
        case STEPS.PERSONAL_DETAILS:
            return 0;
            break;
        case STEPS.NET_WORTH:
            return 1;
            break;
        case STEPS.CASH_MANAGEMENT:
            return 2;
            break;
        case STEPS.RETIREMENT:
            return 3;
            break;
        case STEPS.COLLEGE_EDUCATION:
            return 4;
            break;
        case STEPS.MAJOR_PURCHASES:
            return 5;
            break;
        case STEPS.DEPENDENT_PROTECTION:
            return 6;
            break;
        case STEPS.FINANCIAL_HEALTH:
            return 7;
            break;
        default:
            return 0;
            break;
    }
};

export const getStepUrlSlug = function (stepNo) {
    switch (stepNo) {
        case 0:
            return STEPS.PERSONAL_DETAILS;
        case 1:
            return STEPS.NET_WORTH;
        case 2:
            return STEPS.CASH_MANAGEMENT;
        case 3:
            return STEPS.RETIREMENT;
        case 4:
            return STEPS.COLLEGE_EDUCATION;
        case 5:
            return STEPS.MAJOR_PURCHASES;
        case 6:
            return STEPS.DEPENDENT_PROTECTION;
        case STEPS.FINANCIAL_HEALTH:
            return STEPS.FINANCIAL_HEALTH;
        default:
            return STEPS.PERSONAL_DETAILS;
    }
};
