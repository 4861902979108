import React from "react";
import "./CashManagment.css";
import { Container, Box, Tooltip, Typography } from "@mui/material";
// import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
import RadioComponent from "../radioComponent/RadioComponent";
import RadioFourOptionsComponent from "../radioComponent/RadioFourOptionsComponent";
// import RangeSlider from "../rangeSlider/RangeSlider";
import question from "../../assets/images/question.png";
// import chart from "../../assets/images/chart.png";
// import mobchart from "../../assets/images/chartMob.png";
// import chart from "../../assets/images/annull--buble--desk.png";
// import mobchart from "../../assets/images/annull--buble--mobile.png";
import PrevBUtton from "../prevButton/PrevBUtton";
import AbsButton from "../../components/absButton/AbsButton";
import { useState } from "react";
// import RangeSliderGR from "../rangeSliderGR/RangeSliderGR";
import { useDispatch, useSelector } from "react-redux";
import { PFIFormConstants as PFIFC } from "../../constants/PFIFormConstants";
import NumInput from "../numInput/NumInput";

import { useEffect } from "react";
import { useCashManagement } from "../../hooks/useCashManagement";
import { getMyCashManagement } from "../../apiServices/pfscoreForms/cashManagement";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import allActions from "../../store/actions";

const CashManagment = ({ next, pre }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { saveCashManagement, isLoading, error } = useCashManagement();

  const CURR_CMP = "CashManagment";
  const datafromreducer = useSelector((state) => state.CashManagmentReducer);
  const funcitonsformreducer = useDispatch();

  const capitalizeUtil = function (word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  };

  //  dyanmic number with dolller sign funtion
  const currnyDyamic = (num) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(num);
  };

  const radioOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };
  const inputOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };

  const getCurrentVal = (variable) => {
    // console.log(`${CURR_CMP}:: Getting current value for: ${variable}`);
    switch (variable) {
      // Income
      case "I_SALARY":
        return datafromreducer.iSalary;
      case "I_SALARY_PERIOD":
        return datafromreducer.iSalaryPeriod;
      case "I_RENTAL":
        return datafromreducer.iRental;
      case "I_RENTAL_PERIOD":
        return datafromreducer.iRentalPeriod;
      case "I_ANY_OTHER":
        return datafromreducer.iAnyOther;
      case "I_ANY_OTHER_PERIOD":
        return datafromreducer.iAnyOtherPeriod;
      case "I_ANY_OTHER_DESC":
        return datafromreducer.iAnyOtherDesc;

      // Expenses - propery related
      case "PR_HOME_MORTGAGE":
        return datafromreducer.prHomeMortgage;
      case "PR_HOME_MORTGAGE_PERIOD":
        return datafromreducer.prHomeMortgagePeriod;
      case "PR_PROPERTY_TAXES":
        return datafromreducer.prPropertyTax;
      case "PR_LOAN":
        return datafromreducer.prLoan;
      case "PR_LOAN_PERIOD":
        return datafromreducer.prLoanPeriod;
      case "PR_OTHER":
        return datafromreducer.prOther;
      case "PR_OTHER_PERIOD":
        return datafromreducer.prOtherPeriod;
      case "PR_MAKE_TIMELY_PAYMENT":
        return datafromreducer.prMakeTimelyPayment;

      // living expense
      case "LE_LIFE":
        return datafromreducer.leLife;
      case "LE_LIFE_PERIOD":
        return datafromreducer.leLifePeriod;
      case "LE_HEALTH_INSURANCE":
        return datafromreducer.leHealthInsurance;
      case "LE_HEALTH_INSURANCE_PERIOD":
        return datafromreducer.leHealthInsurancePeriod;
      case "LE_TRAVEL":
        return datafromreducer.leTravel;
      case "LE_TRAVEL_PERIOD":
        return datafromreducer.leTravelPeriod;

      //  OTHE EXPENSIVE
      case "OE_ESTIMATED_TAXES":
        return datafromreducer.oeEstimatedTaxesAnnual;
      case "OE_MONTHLY_OTHER":
        return datafromreducer.oeMonthlyOther;
      case "OE_MONTHLY_OTHER_DESC":
        return datafromreducer.oeMonthlyOtherDesc;

      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  const handleMinusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  const _handleNextPage = async (e) => {
    e.preventDefault();

    const parseOptions = function (option) {
      switch (option) {
        case "Yes":
          return "YES";
          break;

        default:
          break;
      }
    };
    const updateDta = [
      datafromreducer.iSalaryPeriod.toUpperCase(),
      parseInt(datafromreducer.iSalary),
      datafromreducer.iRentalPeriod.toUpperCase(),
      parseInt(datafromreducer.iRental),
      datafromreducer.iAnyOtherPeriod.toUpperCase(),
      parseInt(datafromreducer.iAnyOther),
      datafromreducer.iAnyOtherDesc.toString(),
      datafromreducer.prHomeMortgagePeriod.toUpperCase(),
      parseInt(datafromreducer.prHomeMortgage),
      parseInt(datafromreducer.prPropertyTax),
      parseInt(datafromreducer.prOther),
      datafromreducer.leLifePeriod.toUpperCase(),
      parseInt(datafromreducer.leLife),
      datafromreducer.leHealthInsurancePeriod.toUpperCase(),
      parseInt(datafromreducer.leHealthInsurance),
      datafromreducer.leTravelPeriod.toUpperCase(),
      parseInt(datafromreducer.leTravel),
      parseInt(datafromreducer.oeEstimatedTaxesAnnual),
      datafromreducer.oeMonthlyOtherDesc.toString(),
      parseInt(datafromreducer.oeMonthlyOther),
    ];

    console.log("Update data is:");
    console.log(updateDta);

    setIsSaving(true);
    const response = await saveCashManagement(updateDta);
    setIsSaving(false);

    if (response) {
      console.log("Save CashManagement details Response: ", response);
      next();
    } else {
      console.log("Save CashManagement details: Errors - Alert");
    }
  };
  const _handleprev = (e) => {
    e.preventDefault();
    pre();
  };
  const handleCashManagement = (e) => {
    e.preventDefault();
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      // try {
      const response = await getMyCashManagement();
      const data = await response.getMyCashManagement;
      setIsLoaded(true);
      // Assign default Values
      let updateData = {
        iSalary: PFIFC.cash_management.income.salary_bonus.default,
        iSalaryPeriod: PFIFC.cash_management.income.salary_period.default,
        iRental: PFIFC.cash_management.income.rental.default,
        iRentalPeriod: PFIFC.cash_management.income.rental_period.default,
        iAnyOtherPeriod: PFIFC.cash_management.income.any_other_period.default,
        iAnyOther: PFIFC.cash_management.income.any_other.default,
        iAnyOtherDesc: PFIFC.cash_management.income.any_other_desc.default,

        prHomeMortgage:
          PFIFC.cash_management.expenses.property_related.home_mortgage.default,
        prHomeMortgagePeriod:
          PFIFC.cash_management.expenses.property_related.home_mortgage_period
            .default,
        prPropertyTax:
          PFIFC.cash_management.expenses.property_related.tax.default,
        prLoan: PFIFC.cash_management.expenses.property_related.loan.default,
        prLoanPeriod:
          PFIFC.cash_management.expenses.property_related.loan_period.default,
        prOther:
          PFIFC.cash_management.expenses.property_related.any_other.default,
        prOtherPeriod:
          PFIFC.cash_management.expenses.property_related.any_other_period
            .default,
        prMakeTimelyPayment:
          PFIFC.cash_management.expenses.property_related.make_timely_payment
            .default,

        leLife: PFIFC.cash_management.expenses.living.life.default,
        leLifePeriod: PFIFC.cash_management.expenses.living.life_period.default,
        leHealthInsurance:
          PFIFC.cash_management.expenses.living.health_insurance.default,
        leHealthInsurancePeriod:
          PFIFC.cash_management.expenses.living.health_insurance_period.default,
        leTravel: PFIFC.cash_management.expenses.living.travel.default,
        leTravelPeriod:
          PFIFC.cash_management.expenses.living.travel_period.default,

        oeEstimatedTaxesAnnual:
          PFIFC.cash_management.expenses.other.taxes.default,
        oeMonthlyOther: PFIFC.cash_management.expenses.other.any_other.default,
        oeMonthlyOtherDesc:
          PFIFC.cash_management.expenses.other.any_other_desc.default,
      };
      if (data.http_status === 200) {
        updateData = {
          iSalary: parseInt(data.data.salary_bonus_pretax_amount),
          iSalaryPeriod: capitalizeUtil(data.data.salary_bonus_pretax_period),
          iRental: parseInt(data.data.rental_income_amount),
          iAnyOtherPeriod: capitalizeUtil(
            data.data.any_other_income_period
          ),
          iRentalPeriod: capitalizeUtil(data.data.rental_income_period_period),
          iAnyOther: parseInt(data.data.any_other_income_amount),
          iAnyOtherDesc: data.data.any_other_income_description.toString(),

          prHomeMortgage: parseInt(data.data.home_mortgage_payment_amount),
          prHomeMortgagePeriod: capitalizeUtil(
            data.data.home_mortgage_payment_period
          ),
          prPropertyTax: parseInt(data.data.property_taxes_annual),
          prOther: parseInt(data.data.other_expenses_amount),
          

          leLife: parseInt(data.data.life_expenses_amount),
          leLifePeriod: capitalizeUtil(data.data.life_expenses_period),
          leHealthInsurance: parseInt(data.data.health_insurance_payment_amount),
          leHealthInsurancePeriod: capitalizeUtil(
            data.data.health_insurance_payment_period
          ),
          leTravel: parseInt(data.data.travel_entertainment_amount),
          leTravelPeriod: capitalizeUtil(data.data.travel_entertainment_period),

          oeEstimatedTaxesAnnual: parseInt(data.data.estimated_taxes_annual),
          oeMonthlyOther: parseInt(data.data.any_other_monthly_payments_amount),
          oeMonthlyOtherDesc: data.data.any_other_monthly_payments_desc.toString(),
        };
      }

      console.log(`Cash Management Initial update Data: ${updateData}`);
      console.log(updateData);

      funcitonsformreducer({
        type: "ALL_CM",
        payload: updateData,
      });
      if (data.score && data.score.overAllScore) {
        funcitonsformreducer(
          allActions.scoreActions.UpdateScore({
            overAllScore: data.score.overAllScore,
            debtScore: data.score.debtScore,
            riskScore: data.score.riskScore,
            savingsScore: data.score.savingsScore,
            debugInfo: JSON.stringify(data.score),
          })
        );
      }

      // } catch (err) {
      //   console.log("Initial Load Error:", err);
      //   setLoadingError(err.errors[0].message);
      // }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="cash_managment">
        <Container maxWidth="lg" className="cash_contain">
          <DetailsFormHeading
            title_text={"Cash Management"}
            subtitle_text={"Questions? See our FAQs below."}
          />
          <Box className="row cash_manag2">
            <Box className="cash_managment_sect">
              <Typography variant="h6">Income</Typography>
            </Box>
          </Box>
          <Box className="cash_management_form">
            <form onSubmit={handleCashManagement}>
              {/* income section web view start here */}

              <Box className="mortage_wrapper web_view_mortage web_view_mortage33">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Salary and bonus (pre-tax)
                      <Tooltip title="Salary and bonus (pre-tax)">
                        <span className="tooltip">
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <RadioComponent
                      label1="Monthly"
                      label2="Annual"
                      value1="Monthly"
                      value2="Annual"
                      defaultValue={datafromreducer.iSalaryPeriod}
                      // {getCurrentVal("I_SALARY_PERIOD")}
                      handleRadioOption={(e) =>
                        radioOnchange(e.target.value, "I_SALARY_PERIOD")
                      }
                    />
                  </Box>
                </Box>
                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("I_SALARY")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "I_SALARY")
                      }
                      onPlusClick={(e) => handlePlusClick("I_SALARY")}
                      onMinusClick={(e) => handleMinusClick("I_SALARY")}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="mortage_wrapper web_view_mortage">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Business income</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <Box className="radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal("I_RENTAL_PERIOD")}
                        // {PFIFC.cash_management.income.rental.period.default}
                        handleRadioOption={(e) =>
                          radioOnchange(e.target.value, "I_RENTAL_PERIOD")
                        }
                      />
                    </Box>
                    {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.INrental}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "RENTAL")
                          }
                        />
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("I_RENTAL")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "I_RENTAL")
                      }
                      onPlusClick={(e) => handlePlusClick("I_RENTAL")}
                      onMinusClick={(e) => handleMinusClick("I_RENTAL")}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="slider_range_cash">
                  <RangeSliderGR
                    max={PFIFC.cash_management.income.rental.max}
                    min={PFIFC.cash_management.income.rental.min}
                    val={datafromreducer.INrental}
                    handleOnChange={handleChangeRental}
                  />
                </Box> */}
                <Box className="radio_mobi">
                  {/* <RadioComponent
                    label1="Monthly"
                    label2="Annual"
                    value1="Monthly"
                    value2="Annual"
                    defaultValue={PFIFC.cash_management.income.rental.period.default}
                    handleRadioOption={(e) => setRentalOption(e.target.value)}
                  /> */}
                </Box>
              </Box>

              <Box className="mortage_wrapper web_view_mortage">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Any other income
                      <Tooltip
                        title={PFIFC.cash_management.income.any_other.tooltip}
                      >
                        <span className="tooltip">
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <Box className="radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal("I_ANY_OTHER_PERIOD")}
                        handleRadioOption={(e) =>
                          radioOnchange(e.target.value, "I_ANY_OTHER_PERIOD")
                        }
                      />
                    </Box>
                  </Box>
                  {/* <p className="total_annual_value">
                    Total annual value from these source
                  </p> */}
                </Box>

                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>

                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("I_ANY_OTHER")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "I_ANY_OTHER")
                      }
                      onPlusClick={(e) => handlePlusClick("I_ANY_OTHER")}
                      onMinusClick={(e) => handleMinusClick("I_ANY_OTHER")}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
              </Box>
              {/* <Box className="textarea_section">
                <textarea
                  minrows={5}
                  rows={4}
                  value={getCurrentVal("I_ANY_OTHER_DESC")}
                  onChange={(e) =>
                    inputOnchange(e.target.value, "I_ANY_OTHER_DESC")
                  }
                  className="text_area_section"
                  placeholder="Write Description here. This may include Social Security benefits, pensions, income from a trust, etc."
                />
              </Box> */}

              {/* income section web view ends here */}

              {/*property related expenses web view starts  here*/}

              <Box className="row cash_manag2 web_view_mortage">
                <Box className="cash_managment_sect">
                  <Typography variant="h6">
                    Property Related Expenses:
                  </Typography>
                </Box>
              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Home mortgage monthly payment.
                      <Tooltip
                        title={
                          PFIFC.cash_management.expenses.property_related
                            .home_mortgage.tooltip
                        }
                      >
                        <span>
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("PR_HOME_MORTGAGE")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "PR_HOME_MORTGAGE")
                      }
                      onPlusClick={(e) => handlePlusClick("PR_HOME_MORTGAGE")}
                      onMinusClick={(e) => handleMinusClick("PR_HOME_MORTGAGE")}
                      placeholder=""
                      displayCurrency={true}
                    />
                    {/* <Box className="web_view_mortage radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal("PR_HOME_MORTGAGE_PERIOD")}
                        // {PFIFC.cash_management.expenses.property_related.home_mortgage.period.default}
                        handleRadioOption={(e) =>
                          radioOnchange(
                            e.target.value,
                            "PR_HOME_MORTGAGE_PERIOD"
                          )
                        }
                      />
                    </Box> */}
                    {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.PhomeMortage}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "HOMEMORTGEAGEPAYMENT"
                            )
                          }
                        />
                      </Box>
                    </Box> */}
                  </Box>
                </Box>

                {/* <Box className="slider_range_cash">
                  <RangeSliderGR
                    max={1000000}
                    min={1000}
                    val={datafromreducer.PhomeMortage}
                    handleOnChange={hanldeChangeHomeMortgeAge}
                  />
                </Box> */}
                {/* <Box className="mobile_radio_view ">
                  <RadioComponent
                    label1="Monthly"
                    label2="Annual"
                    value1="Monthly"
                    value="Annual"
                    defaultValue={PFIFC.cash_management.expenses.property_related.home_mortgage.period.default}
                    handleRadioOption={(e) => setMortageOption1(e.target.value)}
                  />
                </Box> */}
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Annual property taxes</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={100}
                        min={0}
                        val={datafromreducer.PpropertyTax}
                        handleOnChange={hanldeChangePopertyTexs}
                      />
                    </Box> */}
                    {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.PpropertyTax}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "POPERTYTEXS")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("PR_PROPERTY_TAXES")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "PR_PROPERTY_TAXES")
                      }
                      onPlusClick={(e) => handlePlusClick("PR_PROPERTY_TAXES")}
                      onMinusClick={(e) =>
                        handleMinusClick("PR_PROPERTY_TAXES")
                      }
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={100}
                    min={0}
                    val={datafromreducer.PpropertyTax}
                    handleOnChange={hanldeChangePopertyTexs}
                  />
                </Box> */}
              </Box>
              {/* 
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <p className="mortage_para">
                      Home mortgage payment
                      <Tooltip title="Home Mortage">
                        <span>
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </p>
                  </Box>
                  <Box className="right_section_cash">
                    <Box className="web_view_mortage">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        handleRadioOption={(e) =>
                          setMortageOption2(e.target.value)
                        }
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={homeMort}
                          onChange={(e) => setHomeMort(e.target.value)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="slider_range_cash">
                  <RangeSlider
                    rangeValue={homeMort}
                    handleChange={handleHomeMOrtage}
                  />
                </Box>
                <Box className="mobile_radio_view">
                  <RadioComponent label1="Monthly" label2="Annual" />
                </Box>
              </Box> */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Annual home maintenance</Typography>
                  </Box>
                  {/* <Box className="right_section_cash"> */}
                  {/* <Box className="web_view_mortage radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal("PR_OTHER_PERIOD")}
                        handleRadioOption={(e) =>
                          radioOnchange(e.target.value, "PR_OTHER_PERIOD")
                        }
                      />
                    </Box> */}
                  {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.PhomeMaint}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "HOMEMAINT")
                          }
                          
                        />
                      </Box>
                    </Box> */}
                  {/* </Box> */}
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("PR_OTHER")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "PR_OTHER")
                      }
                      onPlusClick={(e) => handlePlusClick("PR_OTHER")}
                      onMinusClick={(e) => handleMinusClick("PR_OTHER")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

                {/* <Box className="slider_range_cash">
                  
                  <RangeSliderGR
                    max={4000}
                    min={0}
                    val={datafromreducer.PhomeMaint}
                    handleOnChange={hanldeChangeHomeMant}
                  />
                </Box> */}
                {/* <Box className="mobile_radio_view">
                  <RadioComponent label1="Monthly" label2="Annual" />
                </Box> */}
              </Box>

              {/*property related expenses    

              {/* living expenses starts here */}

              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">Living Expenses:</Typography>
                </Box>
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Annual expenses on food, clothes, etc</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("LE_LIFE")}
                      onChange={(e) => inputOnchange(e.target.value, "LE_LIFE")}
                      onPlusClick={(e) => handlePlusClick("LE_LIFE")}
                      onMinusClick={(e) => handleMinusClick("LE_LIFE")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                    {/* <Box className="web_view_mortageb radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal("LE_LIFE_PERIOD")}
                        handleRadioOption={(e) =>
                          radioOnchange(e.target.value, "LE_LIFE_PERIOD")
                        }
                      />
                    </Box> */}
                    {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.lifeExp}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "LIFEEXP")
                          }
                        />
                      </Box>
                    </Box> */}
                  </Box>
                </Box>

                {/* <Box className="slider_range_cash">
                  
                  <RangeSliderGR
                    max={PFIFC.cash_management.expenses.living.life.max}
                    min={PFIFC.cash_management.expenses.living.life.min}
                    val={datafromreducer.lifeExp}
                    handleOnChange={hanldeChangeLifeExp}
                  />
                </Box> */}
                {/* <Box className="mobile_radio_view">
                  <RadioComponent label1="Monthly" label2="Annual" />
                </Box> */}
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Health insurance payment
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <Box className="web_view_mortage radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal(
                          "LE_HEALTH_INSURANCE_PERIOD"
                        )}
                        // {PFIFC.cash_management.expenses.living.health_insurance.period.default}
                        handleRadioOption={(e) =>
                          radioOnchange(
                            e.target.value,
                            "LE_HEALTH_INSURANCE_PERIOD"
                          )
                        }
                      />
                    </Box>
                    {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.healthExp}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "HEALTHEXP")
                          }
                        />
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("LE_HEALTH_INSURANCE")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "LE_HEALTH_INSURANCE")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("LE_HEALTH_INSURANCE")
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("LE_HEALTH_INSURANCE")
                      }
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

                {/* <Box className="slider_range_cash">
                  <RangeSliderGR
                    max={PFIFC.cash_management.expenses.living.health_insurance.max}
                    min={PFIFC.cash_management.expenses.living.health_insurance.min}
                    val={datafromreducer.healthExp}
                    handleOnChange={hanldeChangeHealth}
                  />
                </Box> */}
                {/* <Box className="mobile_radio_view">
                  <RadioComponent label1="Monthly" label2="Annual" />
                </Box> */}
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Travel & entertainment
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <Box className="web_view_mortage radio_web">
                      <RadioComponent
                        label1="Monthly"
                        label2="Annual"
                        value1="Monthly"
                        value2="Annual"
                        defaultValue={getCurrentVal("LE_TRAVEL_PERIOD")}
                        // {PFIFC.cash_management.expenses.living.travel.period.default}
                        handleRadioOption={(e) =>
                          radioOnchange(e.target.value, "LE_TRAVEL_PERIOD")
                        }
                      />
                    </Box>
                    {/* <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.travelExp}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "TRAVELEXP")
                          }
                        />
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("LE_TRAVEL")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "LE_TRAVEL")
                      }
                      onPlusClick={(e) => handlePlusClick("LE_TRAVEL")}
                      onMinusClick={(e) => handleMinusClick("LE_TRAVEL")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

                {/* <Box className="slider_range_cash">
                  <RangeSliderGR
                    max={800}
                    min={0}
                    val={datafromreducer.travelExp}
                    handleOnChange={hanldeChangeTravel}
                  />
                </Box>
                <Box className="mobile_radio_view">
                  <RadioComponent label1="Monthly" label2="Annual" />
                </Box> */}
              </Box>

              {/* living expenses ends here */}

              {/* other expenses section starts here */}
              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">Other Expenses:</Typography>
                </Box>
              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Annual estimated federal, state, and local taxes
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage loan_range_slifder">
                      <RangeSliderGR
                        max={10000}
                        min={100}
                        val={datafromreducer.loanOther}
                        handleOnChange={hanldeChangeLoadOther}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.loanOther}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "LOANOTHER")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("OE_ESTIMATED_TAXES")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "OE_ESTIMATED_TAXES")
                      }
                      onPlusClick={(e) => handlePlusClick("OE_ESTIMATED_TAXES")}
                      onMinusClick={(e) =>
                        handleMinusClick("OE_ESTIMATED_TAXES")
                      }
                      placeholder=""
                      step={"100"}
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={100000}
                    min={1000}
                    val={datafromreducer.loanOther}
                    handleOnChange={hanldeChangeLoadOther}
                  />
                </Box> */}
              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Any other monthly payments
                      <Tooltip
                        title={
                          PFIFC.cash_management.expenses.other.any_other.tooltip
                        }
                      >
                        <span className="tooltip">
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage monthly_payments ">
                      <RangeSliderGR
                        max={10000}
                        min={0}
                        val={datafromreducer.monthlyOther}
                        handleOnChange={hanldeChangeMonthlyOther}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.monthlyOther}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "MONTHLYOTHER")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("OE_MONTHLY_OTHER")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "OE_MONTHLY_OTHER")
                      }
                      onPlusClick={(e) => handlePlusClick("OE_MONTHLY_OTHER")}
                      onMinusClick={(e) => handleMinusClick("OE_MONTHLY_OTHER")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={10000}
                    min={0}
                    val={datafromreducer.monthlyOther}
                    handleOnChange={hanldeChangeMonthlyOther}
                  />
                </Box> */}
              </Box>

              {/* <Box className="textarea_section">
                <textarea
                  minrows={5}
                  rows={4}
                  value={getCurrentVal("OE_MONTHLY_OTHER_DESC")}
                  onChange={(e) =>
                    inputOnchange(e.target.value, "OE_MONTHLY_OTHER_DESC")
                  }
                  className="text_area_section"
                  placeholder={
                    PFIFC.cash_management.expenses.other.any_other.tooltip
                  }
                />
              </Box> */}
              {/* other expenses section ends here */}

              {/* chart for annual expenses starts here */}
              {/* <Box className="row cash_manag">
                <Box className="cash_managment_sect">
                  <h6>Chart for Annual Expenses</h6>
                </Box>
              </Box> */}
              {/* <Box className="chart_img">
                <Box className="chart_tax">
                  <p>Taxes</p>
                  <p>{currnyDyamic(datafromreducer.PpropertyTax)}</p>
                </Box>
                <Box className="home_mortage_chart ">
                  <p>Home mortgage</p>
                  <p>{currnyDyamic(datafromreducer.PhomeMortage)}</p>
                </Box>
                <Box className="life_exp_chart ">
                  <p>Life Exp,(Food,clothes,utils...)</p>
                  <p>{currnyDyamic(datafromreducer.lifeExp)}</p>
                </Box>
                <Box className="auto_loan_chart ">
                  <p>Auto Loan</p>
                  <p> {currnyDyamic(datafromreducer.loanOther)}</p>
                </Box>
                <Box className="health_ins_chart ">
                  <p>Health ins.</p>
                  <p> {currnyDyamic(datafromreducer.healthExp)} </p>
                </Box>
                <Box className="homeunkeeping_chart ">
                  <p>Home unKeeping</p>
                  <p> {currnyDyamic(datafromreducer.monthlyOther)} </p>
                </Box>
                <img src={chart} alt="" className="img-fluid" />
              </Box> */}

              {/*  for mobile  */}
              {/* <Box className="chart_mobile">
                <Box className="homeunkeeping_chart ">
                  <p>Home unKeeping</p>
                  <p> {currnyDyamic(datafromreducer.monthlyOther)} </p>
                </Box>
                <Box className="home_mortage_chart ">
                  <p>Home mortgage</p>
                  <p>{currnyDyamic(datafromreducer.PhomeMortage)}</p>
                </Box>
                <Box className="life_exp_chart ">
                  <p>Life Exp,(Food,clothes,utils...)</p>
                  <p>{currnyDyamic(datafromreducer.lifeExp)}</p>
                </Box>
                <Box className="chart_taxs">
                  <p>Taxes</p>
                  <p>{currnyDyamic(datafromreducer.PpropertyTax)}</p>
                </Box>
                <Box className="auto_loan_charts ">
                  <p>Auto Loan</p>
                  <p> {currnyDyamic(datafromreducer.loanOther)}</p>
                </Box>
                <Box className="health_ins_charts ">
                  <p>Health ins.</p>
                  <p> {currnyDyamic(datafromreducer.healthExp)} </p>
                </Box>
                <img src={mobchart} alt="" />
              </Box> */}

              {/* chart for annual expenses ends here */}

              <Box className="buttons_section">
                <br />
              </Box>
              <Box className="buttons_section">
                <PrevBUtton prev_name="Previous Page" onClick={_handleprev} />
                <AbsButton title="Next Page" onClick={_handleNextPage} />
              </Box>
            </form>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default CashManagment;
