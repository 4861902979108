import React, { useState } from "react";
import PersonalDetails from "../personalDetails/PersonalDetails";
import NetWorth from "../netWorth/NetWorth";
import Retirement from "../retirement/Retirement";
import MajorPurchases from "../majorPurchases/MajorPurchases";
import DependentProtection from "../dependentProtection/DependentProtection";
import CashManagment from "../cashManagment/CashManagment";
import CollegeEducation from "../collegeEducation/CollegeEducation";
import "./Steppers.css";
// import { Stepper, Step, StepLabel, Container, Grid } from "@material-ui/core";
import { Stepper, Step, StepLabel, Container, Grid, Box, stepLabelClasses, StepConnector, stepConnectorClasses } from "@mui/material";
import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BackLink from '../../components/backLink/BackLink'
import { styled } from '@mui/material/styles';
import { getStepUrlSlug } from "../../constants/ScoringDetailsSteps";
// // Left Side Widgets
// // Personal Details Screen
// import LhsInfoBox from "../../components/lhsInfoBox/LhsInfoBox"
// // Net Worth Screen
// import NetWorthKeyFigures from "../../components/keysFiguresActions/NetWorthKeyFigures";
// // Cash Management Screen
// import CashManagementKeyFigures from "../../components/keysFiguresActions/CashManagementKeyFigures";
// import AnnualExpense from "../../components/annualExpense/AnnualExpense";

// // Retirement Screen
// import RetirementKeysFigures from "../../components/keysFiguresActions/RetirementKeysFigures";
// // College Education Screen
// import EducationKeyFigures from "../../components/keysFiguresActions/EducationKeyFigures";
// // Major Purchases Screen
// import PurchasesKeysFigures from "../../components/keysFiguresActions/PurchasesKeysFigures";
// // Dependent Protection Screen
// import ProtectionKeysFigures from "../../components/keysFiguresActions/ProtectionKeysFigures";

// ScoreCard - OverAll Score
import ScoreCard from "../../components/keysFiguresActions/ScoreCard";


const StyledConnector = styled(StepConnector)(({ theme }) => ({
  marginLeft: 4,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 3,
  },    
  [`&.${stepConnectorClasses.disabled}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#F1F3F4',
      borderWidth: 0,
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#4EEEB3',
      borderWidth: 0,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      background: '#4EEEB3',
      borderWidth: 0,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    width: 4
  },
}));

const StyledStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  background: 'transparent',
  width: 8,
  height: 8,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  border: '2px solid #F1F3F4', 
  ...(ownerState.active && {
    background: '#4EEEB3',
    border: '1px solid #FFF', 
    width: 10,
    height: 10,
    filter: 'drop-shadow(0px 0px 10px #4EEEB3)'
  }), 
  ...(ownerState.completed && {
    background: '#4EEEB3',
    border: '2px solid #4EEEB3', 
  }),    
  [theme.breakpoints.down("lg")]: {
    width: 6,
    height: 6,
    ...(ownerState.active && {
      width: 8,
      height: 8,
    }), 
  },
}));

function StyledStepIcon(props) {
  const { active, completed, className } = props;

  const LabelConnector = styled(Box)(({ theme, ownerState }) => ({
    height: 'calc(100% - 12px)', 
    width: '4px', 
    marginLeft: '4px', 
    background: '#F1F3F4',
    ...(ownerState.active && {
      background: '#F1F3F4',
    }), 
    ...(ownerState.completed && {
      background: '#4EEEB3',
    }),    
    [theme.breakpoints.down("lg")]: {
      height: 'calc(100% - 10px)',
      marginLeft: 3,
    },    
  }))

  return (<>
    
    <StyledStepIconRoot ownerState={{ completed, active }} className={className}/>
    <LabelConnector ownerState={{ completed, active }} />
  </>
  );
}

const StyledStepLabel = styled(StepLabel)({
  padding: 0,
  alignItems: "stretch",
  [`& .${stepLabelClasses.label}`]: {
    fontSize: '1.6rem',
    color: '#000',
    fontWeight: 400,
    marginLeft: "20px",
    [`& .${stepLabelClasses.completed}`]: {
      color: '#000',
      fontWeight: 400,
      
    },
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    flexDirection: "column"
  }
})

const Stepper01 = (props) => {
  const datafromreducer = useSelector((state) => state.ScoreReducer);
  const siteConfigreducer = useSelector((state) => state.SiteConfigReducer);
  console.log(
    `siteConfigreducer.screenWidth: ${siteConfigreducer.screenWidth}`
  );

  const { step = 0 } = props;
  const navigate = useNavigate();

  
  const handleCheck = (value) => {
    console.log("handleCheck Function called", value);
    const slug = getStepUrlSlug(value)
    navigate("/scoring-details/"+ slug);
  };

  const handleFinancialHealth = (value) => {
    console.log("handleFinancialHealth Function called", value);
    // setCheck(value);
    navigate("/financial-health");
  };

  console.log(`Stepper step: ${step} and type is ${typeof step}`);


  // console.log("datafromreducer.overAllScore: ", datafromreducer.overAllScore);
  // console.log("datafromreducer.overAllScorePercentage: ", datafromreducer.overAllScorePercentage);

  const percentage = datafromreducer.overAllScorePercentage;
  const debtScore = datafromreducer.debtScore;
  const overAllScore = datafromreducer.overAllScore;
  const riskScore = datafromreducer.riskScore;
  const savingsScore = datafromreducer.savingsScore;
  const debugInfo = datafromreducer.debugInfo;
  const screenWidth = siteConfigreducer.screenWidth;
  const flexDirection = screenWidth < 900 ? "column-reverse" : "inherit";
  console.log(`flexDirection: ${flexDirection}`);

  return (
    <>
      <Container maxWidth="lg" className="step_container">
        <BackLink/>
        <Grid container spacing={5} flexDirection={flexDirection}>
          {/* Main Co=ntent Column */}
          <Grid item lg={8} md={8} sm={12}>
            <div>
              {(() => {
                switch (step) {
                  case 0:
                    return <PersonalDetails next={() => handleCheck(1)} />;
                  case 1:
                    return (
                      <NetWorth
                        text="NetWorth"
                        next={() => handleCheck(2)}
                        pre={() => handleCheck(0)}
                      />
                    );
                  case 2:
                    return (
                      <CashManagment
                        next={() => handleCheck(3)}
                        pre={() => handleCheck(1)}
                      />
                    );
                  case 3:
                    return (
                      <Retirement
                        text="Retirement"
                        next={() => handleCheck(4)}
                        pre={() => handleCheck(2)}
                      />
                    );
                  case 4:
                    return (
                      <CollegeEducation
                        text="CollegeEducation"
                        next={() => handleCheck(5)}
                        pre={() => handleCheck(3)}
                      />
                    );

                  case 5:
                    return (
                      <MajorPurchases
                        text="Major Purchases"
                        next={() => handleCheck(6)}
                        pre={() => handleCheck(4)}
                      />
                    );
                  case 6:
                    return (
                      <DependentProtection
                        text="Dependent Protection"
                        next={() => handleFinancialHealth(7)}
                        pre={() => handleCheck(5)}
                      />
                    );
                  case 7:
                    return (
                      <PersonalFinancialIndex
                        text="Your Financial Health Score"
                        pre={() => handleCheck(6)}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </Grid>
          {/* Right Side Column */}
          <Grid item lg={4} md={4} sm={12}>
            {/* Left Side - Navigation Menu */}
            <Stepper
              orientation="vertical"
              activeStep={step}
              connector={<StyledConnector />}
            >
              <Step>
                <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(0)}>
                  Personal Details
                </StyledStepLabel>
              </Step>
              <Step>
              <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(1)}>
                  Net Worth
                </StyledStepLabel>
              </Step>
              <Step>
              <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(2)}>
                  Cash Management
                </StyledStepLabel>
              </Step>
              <Step>
              <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(3)}>
                  Retirement
                </StyledStepLabel>
              </Step>
              <Step>
              <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(4)}>
                  Education
                </StyledStepLabel>
              </Step>
              <Step>
              <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(5)}>
                  Major Purchases
                </StyledStepLabel>
              </Step>
              <Step>
              <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleCheck(6)}>
                  Dependent Protection
                </StyledStepLabel>
              </Step>
              <Step>
                <StyledStepLabel StepIconComponent={StyledStepIcon} onClick={() => handleFinancialHealth(7)}>
                  Your Financial Health Score
                </StyledStepLabel>
              </Step>
            </Stepper>
            {/* Left Side - Bottom Widgets */}
            <div>
              {(() => {
                switch (step) {
                  case 0:
                    // Personal Details
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  case 1:
                    // Net Worth
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  case 2:
                    // Cash Management
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  // <div>
                  //   <CashManagementKeyFigures />
                  //   <AnnualExpense />
                  // </div>
                  case 3:
                    // Retirement
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  case 4:
                    // Education
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  case 5:
                    // Major Purchases
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  case 6:
                    // Dependent Protection
                    return (
                      <ScoreCard
                        percentage={percentage}
                        overAllScore={overAllScore}
                        debtScore={debtScore}
                        riskScore={riskScore}
                        savingsScore={savingsScore}
                        debugInfo={debugInfo}
                      />
                    );
                  case 7:
                    // Financial Health / Score
                    return <div></div>;

                  default:
                    return null;
                }
              })()}
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Stepper01;
