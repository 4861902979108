import React from 'react'
import { Box, Container, Divider, Grid, } from "@mui/material";
import "./Team.css"
import keith from "../../assets/images/team/keith.jpg"
import james from "../../assets/images/team/james.jpg"
import shekhar from "../../assets/images/team/shekhar.jpg"
import Card from './Card';


export default function Team() {
    return (
        <Container maxWidth="lg" className="team-section">
            <Divider className='divider' />
            <h4 className='title'>Meet The Team</h4>

            <Grid container spacing={4} alignItems={'stretch'}>
                <Grid item lg={4} md={4} xs={12}>
                    <Card img={keith} personName="Keith Johnson" designation="Co-Founder & COO" content="Keith Johnson is a serial entrepreneur having worked in technology, financial publishing, gaming, and sports learning. Johnson has held the positions of CEO, COO, VP Sales, Marketing and Business Development, and Japan Country Manager. Johnson has managed international operations throughout Europe, Asia, Africa, and Australia." />

                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Card img={james} personName="James Testa" designation="Co-Founder & CEO" content="James Testa has an extensive background in e-commerce and digital advertising. James started his first business venture in college where he bootstrapped an e-commerce nutrition brand. With a successful Kickstarter campaign, he was able to scale the business from 0 to 6 figures in revenue in a few short years. From there, he launched a consulting/software company that helps other business owners generate leads, market to their customers, and boost revenue." />

                </Grid>
                <Grid item lg={4} md={4} xs={12}>
                    <Card img={shekhar} personName="Shekhar Yadav" designation="Co-Founder & CTO" content="Shekhar has more than 20 years designing, developing, and maintaining databases, application layers, APIs, web and mobile applications. He also has direct experience in FinTech." />

                </Grid>
            </Grid>
        </Container>
    )
}
