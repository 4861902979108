import { useAuthContext } from "./useAuthContext";

export const useSignout= () => {

    const {dispatch} = useAuthContext()
    const signout = async () => {
        // remove user from storage
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        dispatch({type: 'LOGOUT'});
    }

    return {signout}
}