import React from "react";
import { Box } from "@mui/material";
// import "./DetailsFormHeading.css";
import Typography from "@mui/material/Typography";

const DetailsFormHeading = (props) => {
  const { title_text, subtitle_text } = props;
  return (
    <>
    <Box className="details_form_heading">
        <Typography variant="h1" sx={{marginBottom:"6px"}}>{title_text}</Typography>
        <Typography variant="body1" sx={{background: 'linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent'}}>{subtitle_text}</Typography>
    </Box>
    </>
  );
};

export default DetailsFormHeading;
