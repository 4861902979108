import React from "react";
import "../cashManagment/CashManagment.css";
import { Container, Box, Typography } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
import RadioComponent from "../radioComponent/RadioComponent";
import RangeSlider from "../rangeSlider/RangeSlider";
import question from "../../assets/images/question.png";
import chart from "../../assets/images/chart.png";
import mobchart from "../../assets/images/chartMob.png";
import PrevBUtton from "../prevButton/PrevBUtton";
import AbsButton from "../../components/absButton/AbsButton";
import { PFIFormConstants as PFIFC } from "../../constants/PFIFormConstants";
import RangeSliderGR from "../rangeSliderGR/RangeSliderGR";
import { useDispatch, useSelector } from "react-redux";
import NumInput from "../numInput/NumInput";
import { useState } from "react";

import { useEffect } from "react";

import { useNetWorth } from "../../hooks/useNetWorth";
import { getMyNetWorth } from "../../apiServices/pfscoreForms/netWorth";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import allActions from "../../store/actions";
import { styled } from "@mui/material/styles";
import { capitalizeUtil } from '../../helpers/utils'
import DropDownComponent from "../dropdownComponent/DropDownComponent";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const NetWorth = ({ next, pre }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { saveNetWorth, isLoading, error } = useNetWorth();

  const CURR_CMP = "NetWorth";
  const datafromreducer = useSelector((state) => state.NetWorthReducer);
  const funcitonsformreducer = useDispatch();

  const _handleNextPage = async (e) => {
    e.preventDefault();

    const updateDta = [
      parseInt(datafromreducer.laPrimaryHome),
      parseInt(datafromreducer.laCarAndOthers),
      parseInt(datafromreducer.laPersonalValuables),
      datafromreducer.laAnyOtherDesc.toString(),
      parseInt(datafromreducer.laAnyOther),

      parseInt(datafromreducer.siTotal),
      parseInt(datafromreducer.siCash),
      parseInt(datafromreducer.siRetirementAc),
      parseInt(datafromreducer.siBrokerageAc),

      datafromreducer.isMortgagePrimaryHome === "Yes" ? true : false,
      parseInt(datafromreducer.mortgageInterestRate),
      parseInt(datafromreducer.mortgageYearsLeft),
      datafromreducer.mortgageType.toUpperCase(),
      datafromreducer.mortgageYearsTotal.toUpperCase(),
      parseInt(datafromreducer.lMortgage),
      parseInt(datafromreducer.lCarsOthers),
      parseInt(datafromreducer.lCcDebtLoan),
      datafromreducer.lAnyOtherDesc.toString(),
      datafromreducer.isOtherLoan === "Yes" ? true : false,
      parseInt(datafromreducer.lAnyOther),
    ];

    console.log("Update data is:");
    console.log(updateDta);

    setIsSaving(true);
    const response = await saveNetWorth(updateDta);
    setIsSaving(false);

    if (response) {
      console.log("Save net worth details Response: ", response);
      next();
    } else {
      console.log("Save net worth details: Errors - Alert");
    }

    // next();
  };
  const _handleprev = (e) => {
    e.preventDefault();
    pre();
  };

  const inputOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };

  const getCurrentVal = (variable) => {
    console.log(`${CURR_CMP}:: Getting current value for: ${variable}`);
    switch (variable) {
      // Lifestyle Assets
      case "LA_PRIMARY_HOME":
        return datafromreducer.laPrimaryHome;
      case "LA_CAR_AND_OTHERS":
        return datafromreducer.laCarAndOthers;
      case "LA_PERSONAL_VALUABLES":
        return datafromreducer.laPersonalValuables;
      case "LA_ANY_OTHER_DESC":
        return datafromreducer.laAnyOtherDesc;
      case "LA_ANY_OTHER":
        return datafromreducer.laAnyOther;

      // Savings and Investments
      case "SI_TOTAL":
        return datafromreducer.siTotal;
      case "SI_CASH":
        return datafromreducer.siCash;
      case "SI_RETIREMENT_AC":
        return datafromreducer.siRetirementAc;
      case "SI_BROKERAGE_AC":
        return datafromreducer.siBrokerageAc;

      // Liabilities 
      case "IS_MORTGAGE_PRIMARY_HOME":
        return datafromreducer.isMortgagePrimaryHome;
      case "MORTGAGE_INTEREST_RATE":
        return datafromreducer.mortgageInterestRate;
      case "MORTGAGE_YEARS_LEFT":
        return datafromreducer.mortgageYearsLeft;
      case "MORTGAGE_TYPE":
        return datafromreducer.mortgageType;
      case "MORTGAGE_YEARS_TOTAL":
        return datafromreducer.mortgageYearsTotal;
      case "L_MORTGAGE":
        return datafromreducer.lMortgage;
      case "L_CARS_OTHERS":
        return datafromreducer.lCarsOthers;
      case "L_CC_DEBT_LOAN":
        return datafromreducer.lCcDebtLoan;
      case "L_ANY_OTHER_DESC":
        return datafromreducer.lAnyOtherDesc;
      case "IS_OTHER_LOAN":
        return datafromreducer.isOtherLoan;
      case "L_ANY_OTHER":
        return datafromreducer.lAnyOther;

      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  const handleMinusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      // try {
      const response = await getMyNetWorth();
      const data = await response.getMyNetWorth;
      setIsLoaded(true);
      // Assign default Values
      let updateData = {
        laPrimaryHome: PFIFC.nw.lifestyle_assets.primary_home.default,
        laCarAndOthers: PFIFC.nw.lifestyle_assets.car_and_others.default,
        laPersonalValuables:
          PFIFC.nw.lifestyle_assets.personal_valuables.default,
        laAnyOtherDesc: PFIFC.nw.lifestyle_assets.any_other_desc.default,
        laAnyOther: PFIFC.nw.lifestyle_assets.any_other.default,

        siTotal: PFIFC.nw.savings_and_nvestments.total.default,
        siCash: PFIFC.nw.savings_and_nvestments.cash.default,
        siRetirementAc:
          PFIFC.nw.savings_and_nvestments.retirement_accounts.default,
        siBrokerageAc:
          PFIFC.nw.savings_and_nvestments.brokerage_accounts.default,

        isMortgagePrimaryHome: PFIFC.nw.liabilities.is_mortgage_primary_home.default,
        mortgageInterestRate: PFIFC.nw.liabilities.interest_rate.default,
        mortgageYearsLeft: PFIFC.nw.liabilities.years_left.default,
        mortgageType: PFIFC.nw.liabilities.mortgage_type.default,
        mortgageYearsTotal: PFIFC.nw.liabilities.years_total.default,
        lMortgage: PFIFC.nw.liabilities.primary_home.default,
        lCarsOthers: PFIFC.nw.liabilities.car_and_others.default,
        lCcDebtLoan: PFIFC.nw.liabilities.credit_card_loan.default,
        lAnyOtherDesc: PFIFC.nw.liabilities.any_other_desc.default,
        isOtherLoan: PFIFC.nw.liabilities.is_other_loan.default,
        lAnyOther: PFIFC.nw.liabilities.any_other.default,
      };
      if (data.http_status === 200) {
        updateData = {
          laPrimaryHome: data.data.market_value_primary_home,
          laCarAndOthers: data.data.market_value_cars_major_items,
          laPersonalValuables: data.data.market_value_personal_valuables,
          laAnyOtherDesc: data.data.any_other_assets_description,
          laAnyOther: data.data.any_other_assets_amount,

          siTotal: data.data.total_savings,
          siCash: data.data.cash_savings,
          siRetirementAc: data.data.current_value_retirement_accounts,
          siBrokerageAc: data.data.value_all_brokerage_accounts,

          isMortgagePrimaryHome: data.data.is_mortgage_primary_home === true ? "Yes" : "No",
          mortgageInterestRate: data.data.mortgage_interest_rate,
          mortgageYearsLeft: data.data.mortgage_years_left,
          mortgageType: capitalizeUtil(data.data.mortgage_type),
          mortgageYearsTotal: capitalizeUtil(data.data.mortgage_years_total),
          lMortgage: data.data.mortgage_balance_primary_home,
          lCarsOthers: data.data.loan_balance_cars_other_major_items,
          lCcDebtLoan: data.data.credit_card_debt_loan_balance,
          lAnyOtherDesc: data.data.any_other_loans_description,
          isOtherLoan: data.data.is_other_loan === true ? "Yes" : "No",
          lAnyOther: data.data.any_other_loans_amount,
        };
      }
      console.log(`Net Worth Initial update Data: ${updateData}`);
      console.log(updateData);

      funcitonsformreducer({
        type: "ALL_NW",
        payload: updateData,
      });

      if (data.score && data.score.overAllScore) {
        funcitonsformreducer(
          allActions.scoreActions.UpdateScore({
            overAllScore: data.score.overAllScore,
            debtScore: data.score.debtScore,
            riskScore: data.score.riskScore,
            savingsScore: data.score.savingsScore,
            debugInfo: JSON.stringify(data.score),
          })
        );
      }
      // } catch (err) {
      //   console.log("Initial Load Error:", err);
      //   setLoadingError(err.errors[0].message);
      // }
    };

    fetchData();
  }, [funcitonsformreducer]);

  return (
    <>
      <section className="cash_managment">
        <Container maxWidth="lg" className="cash_contain">
          {/* <PersonalFinancialIndex 
            audio_ogg_path={PFIFC.nw.audio_ogg_path} 
            audio_mp3_path={PFIFC.nw.audio_mp3_path} 
            /> */}
          <DetailsFormHeading
            title_text={"Your Net Worth"}
            subtitle_text={
              "The accuracy of your score is determined by the accuracy of your data. If you elect not to continue, please click on Feedback link below."
            }
          />
          {/* <Box className="row cash_manag">
            <Box className="cash_managment_sect">
              <h6>Step 1:Your Net-Worth</h6>
            </Box>
          </Box> */}
          {isLoaded && (
            <Box className="cash_management_form">
              <form>
                {/* Lifestyle Assets */}
                <br />
                <Box className="row cash_manag2">
                  <Box className="cash_managment_sect">
                    <Typography variant="h6">Lifestyle Assets</Typography>
                  </Box>
                </Box>
                {/* primare homre new */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Market value of primary home
                        <LightTooltip
                          title={PFIFC.nw.lifestyle_assets.primary_home.tooltip}
                        >
                          <span className="tooltip">
                            <img src={question} alt="" />
                          </span>
                        </LightTooltip>
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                     
                        <NumInput
                          value={getCurrentVal("LA_PRIMARY_HOME")}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "LA_PRIMARY_HOME")
                          }
                          onPlusClick={(e) =>
                            handlePlusClick("LA_PRIMARY_HOME")
                          }
                          onMinusClick={(e) =>
                            handleMinusClick("LA_PRIMARY_HOME")
                          }
                          placeholder="$ Value of primary home"
                          displayCurrency={true}
                        />
                      </Box>
                    
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* personal valuables */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Market value of businesses and other assets
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("LA_PERSONAL_VALUABLES")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "LA_PERSONAL_VALUABLES")
                        }
                        onPlusClick={(e) =>
                          handlePlusClick("LA_PERSONAL_VALUABLES")
                        }
                        onMinusClick={(e) =>
                          handleMinusClick("LA_PERSONAL_VALUABLES")
                        }
                        placeholder="$ value of personal valuables"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* cars and others */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">Market value of cars and other major items</Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("LA_CAR_AND_OTHERS")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "LA_CAR_AND_OTHERS")
                        }
                        onPlusClick={(e) =>
                          handlePlusClick("LA_CAR_AND_OTHERS")
                        }
                        onMinusClick={(e) =>
                          handleMinusClick("LA_CAR_AND_OTHERS")
                        }
                        placeholder="$ value of cars and other major items"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>



                {/* description of any other assests */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Value of any other assets
                        <LightTooltip
                          title={PFIFC.nw.lifestyle_assets.any_other.tooltip}
                        >
                          <span className="tooltip">
                            <img src={question} alt="" />
                          </span>
                        </LightTooltip>
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("LA_ANY_OTHER")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "LA_ANY_OTHER")
                        }
                        onPlusClick={(e) => handlePlusClick("LA_ANY_OTHER")}
                        onMinusClick={(e) => handleMinusClick("LA_ANY_OTHER")}
                        placeholder="$ value of any other assets"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* <Box className="textarea_section">
                  <textarea
                    minrows={5}
                    rows={4}
                    value={getCurrentVal("LA_ANY_OTHER_DESC")}
                    onChange={(e) =>
                      inputOnchange(e.target.value, "LA_ANY_OTHER_DESC")
                    }
                    className="text_area_section"
                    placeholder="Write Description of Any Other Assets"
                  />
                </Box> */}

                {/* Savings and Investments */}
                {/* <br />
                <Box className="row cash_manag2 web_view_mortage">
                  <Box className="cash_managment_sect">
                  <Typography variant="h6">Savings and Investments</Typography>
                  </Box>
                </Box> */}

                {/* Savings and Investments */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Savings and Investments
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("SI_TOTAL")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "SI_TOTAL")
                        }
                        onPlusClick={(e) => handlePlusClick("SI_TOTAL")}
                        onMinusClick={(e) => handleMinusClick("SI_TOTAL")}
                        placeholder="$ value of Savings and Investments"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* Cash savings */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Cash savings
                        <LightTooltip
                          title={PFIFC.nw.savings_and_nvestments.cash.tooltip}
                        >
                          <span className="tooltip">
                            <img src={question} alt="" />
                          </span>
                        </LightTooltip>
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("SI_CASH")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "SI_CASH")
                        }
                        onPlusClick={(e) => handlePlusClick("SI_CASH")}
                        onMinusClick={(e) => handleMinusClick("SI_CASH")}
                        placeholder="$ value of Cash savings"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* retirement accounts */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Current value of retirement accounts
                        <LightTooltip
                          title={
                            PFIFC.nw.savings_and_nvestments.retirement_accounts
                              .tooltip
                          }
                        >
                          <span className="tooltip">
                            <img src={question} alt="" />
                          </span>
                        </LightTooltip>
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("SI_RETIREMENT_AC")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "SI_RETIREMENT_AC")
                        }
                        onPlusClick={(e) => handlePlusClick("SI_RETIREMENT_AC")}
                        onMinusClick={(e) =>
                          handleMinusClick("SI_RETIREMENT_AC")
                        }
                        placeholder="Current $ value of retirement accounts"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* retirement accounts */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Value of all brokerage accounts
                        <LightTooltip
                          title={
                            PFIFC.nw.savings_and_nvestments.brokerage_accounts
                              .tooltip
                          }
                        >
                          <span className="tooltip">
                            <img src={question} alt="" />
                          </span>
                        </LightTooltip>
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("SI_BROKERAGE_AC")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "SI_BROKERAGE_AC")
                        }
                        onPlusClick={(e) => handlePlusClick("SI_BROKERAGE_AC")}
                        onMinusClick={(e) =>
                          handleMinusClick("SI_BROKERAGE_AC")
                        }
                        placeholder="Current $ value of all brokerage accounts"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* My Liabilities */}
                <br />
                <Box className="row cash_manag2 web_view_mortage">
                  <Box className="cash_managment_sect">
                    <Typography variant="h6">My Liabilities</Typography>
                  </Box>
                </Box>

                {/* Liability: Mortgage balance */}
                <Box className="mortage_wrapper detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Mortgage on primary home
                    </Typography>
                  </Box>
                  <Box>
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("IS_MORTGAGE_PRIMARY_HOME")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "IS_MORTGAGE_PRIMARY_HOME")
                      }
                    />
                  </Box>
                </Box>

                {getCurrentVal("IS_MORTGAGE_PRIMARY_HOME") === 'Yes' && <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Mortgage interest rate
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("MORTGAGE_INTEREST_RATE")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "MORTGAGE_INTEREST_RATE")
                        }
                        onPlusClick={(e) => handlePlusClick("MORTGAGE_INTEREST_RATE", 1)}
                        onMinusClick={(e) => handleMinusClick("MORTGAGE_INTEREST_RATE", 1)}
                        placeholder="Mortgage interest rate"
                        displayCurrency={false}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>}
                {getCurrentVal("IS_MORTGAGE_PRIMARY_HOME") === 'Yes' && <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        How many years left on mortgage?
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("MORTGAGE_YEARS_LEFT")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "MORTGAGE_YEARS_LEFT")
                        }
                        onPlusClick={(e) => handlePlusClick("MORTGAGE_YEARS_LEFT", 1)}
                        onMinusClick={(e) => handleMinusClick("MORTGAGE_YEARS_LEFT", 1)}
                        placeholder="How many years left on mortgage?"
                        displayCurrency={false}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>}

                {getCurrentVal("IS_MORTGAGE_PRIMARY_HOME") === 'Yes' && <Box className="mortage_wrapper detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Type of mortgage
                    </Typography>
                  </Box>
                  <Box>
                    <RadioComponent
                      label1="Fixed"
                      label2="Adjustable"
                      value1="Fixed"
                      value2="Adjustable"
                      defaultValue={getCurrentVal("MORTGAGE_TYPE")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "MORTGAGE_TYPE")
                      }
                    />
                  </Box>
                  <Box >
                    <FormControl sx={{ m: 1, minWidth: 120, display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop:{xs:'32px', sm:'0px'} }} size="small">
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={getCurrentVal("MORTGAGE_YEARS_TOTAL")}
                        label="Age"
                        displayEmpty
                        input={<OutlinedInput />}
                        sx={{margin:'0px'}}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "MORTGAGE_YEARS_TOTAL")}
                        
                      >
                        <MenuItem value="">
                          <em>Select options</em>
                        </MenuItem>
                        <MenuItem value={'15'}>15 Years</MenuItem>
                        <MenuItem value={'30'}>30 Years</MenuItem>
                        <MenuItem value={'Other'}>Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>}
               
               { getCurrentVal("IS_MORTGAGE_PRIMARY_HOME") === 'Yes' && <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Mortgage balance on primary home
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("L_MORTGAGE")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "L_MORTGAGE")
                        }
                        onPlusClick={(e) => handlePlusClick("L_MORTGAGE")}
                        onMinusClick={(e) => handleMinusClick("L_MORTGAGE")}
                        placeholder="Current Mortgage balance on primary home"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>}

                {/* Liability: Car and Any Other Loan balance */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Loan balances on cars and other major items
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("L_CARS_OTHERS")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "L_CARS_OTHERS")
                        }
                        onPlusClick={(e) => handlePlusClick("L_CARS_OTHERS")}
                        onMinusClick={(e) => handleMinusClick("L_CARS_OTHERS")}
                        placeholder="Loan balances on cars and other major items"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* Liability: Car and Any Other Loan balance */}
                <Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Credit card debt and loan balances on major appliances
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("L_CC_DEBT_LOAN")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "L_CC_DEBT_LOAN")
                        }
                        onPlusClick={(e) => handlePlusClick("L_CC_DEBT_LOAN")}
                        onMinusClick={(e) => handleMinusClick("L_CC_DEBT_LOAN")}
                        placeholder="Credit card debt and loan balances on major appliances"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>

                {/* Liability: any other loan */}

                <Box className="mortage_wrapper detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                    Any other loans?
                    </Typography>
                  </Box>
                  <Box>
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("IS_OTHER_LOAN")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "IS_OTHER_LOAN")
                      }
                    />
                  </Box>
                </Box>

                {getCurrentVal("IS_OTHER_LOAN") === 'Yes' &&<Box className="mortage_wrapper web_view">
                  <Box className="home_mortage_section">
                    <Box className="info_left_sec">
                      <Typography variant="body1">
                        Any other loans amount
                        <LightTooltip
                          title={PFIFC.nw.liabilities.any_other.tooltip}
                        >
                          <span className="tooltip">
                            <img src={question} alt="" />
                          </span>
                        </LightTooltip>
                      </Typography>
                    </Box>
                    <Box className="right_section_cash">
                      <NumInput
                        value={getCurrentVal("L_ANY_OTHER")}
                        onChange={(e) =>
                          inputOnchange(e.target.value, "L_ANY_OTHER")
                        }
                        onPlusClick={(e) => handlePlusClick("L_ANY_OTHER")}
                        onMinusClick={(e) => handleMinusClick("L_ANY_OTHER")}
                        placeholder="$ amounts of any other loan(s)"
                        displayCurrency={true}
                      />
                    </Box>
                  </Box>
                  <Box className="mobile_radio_view"></Box>
                </Box>}

                {/* <Box className="textarea_section">
                  <textarea
                    minrows={5}
                    rows={4}
                    value={getCurrentVal("L_ANY_OTHER_DESC")}
                    onChange={(e) =>
                      inputOnchange(e.target.value, "L_ANY_OTHER_DESC")
                    }
                    className="text_area_section"
                    placeholder="Write Description of any other loan(s) you have"
                  />
                </Box> */}

                <br />
                <br />
                <Box className="buttons_section" sx={{ marginTop: '80px' }}>
                  <PrevBUtton prev_name="Previous Page" onClick={_handleprev} />
                  <AbsButton title="Next Page" onClick={_handleNextPage} />
                </Box>
              </form>
            </Box>
          )}

          {!isLoaded && <Box>Loading...</Box>}

          {error && <Box>Error: {error}</Box>}
          {loadingError && <Box>Error: {loadingError}</Box>}
        </Container >
      </section >
    </>
  );
};

export default NetWorth;
