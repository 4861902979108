const mpInitialState = {
    smpNumber: 0,
    smpPurchases: [],
    itemIndex: 0
};

export const individualMpInitialState = {

    // Identifiers
    pIndex: 0,
    pId: "",

    // Individual Purchase Variables
    pName: "",
    pYearsToPurchase: 0,
    pAmountNeeded: 0,
    pAmountSaved: 0,
    pMonthlySaving: 0,
    pAnnualReturn: 2,

    // Calculated Values
    pMonthlySavingRequired: 0,
    pAdditionalSavingRequired: 0
};

const MergeMajorPurchaseVar = (state = individualMpInitialState, action_type, action_payload) => {
    switch (action_type) {
        // identifiers
        case "P_INDEX":
            return {
                ...state,
                pIndex: (state.pIndex = action_payload),
            };
        case "P_ID":
            return {
                ...state,
                pId: (state.pId = action_payload),
            };
        // Input Varialbles
        case "P_NAME":
            return {
                ...state,
                pName: (state.pName = action_payload),
            };
        case "P_YEARS_TO_PURCHASE":
            return {
                ...state,
                pYearsToPurchase: (state.pYearsToPurchase = action_payload),
            };
        case "P_AMOUNT_NEEDED":
            return {
                ...state,
                pAmountNeeded: (state.pAmountNeeded = action_payload),
            };
        case "P_AMOUNT_SAVED":
            return {
                ...state,
                pAmountSaved: (state.pAmountSaved = action_payload),
            };
        case "P_MONTHLY_SAVING":
            return {
                ...state,
                pMonthlySaving: (state.pMonthlySaving = action_payload),
            };
        case "P_ANNUAL_RETURN":
            return {
                ...state,
                pAnnualReturn: (state.pAnnualReturn = action_payload),
            };

        // Calculated Values
        case "P_MONTHLY_SAVING_REQUIRED":
            return {
                ...state,
                pMonthlySavingRequired: (state.pMonthlySavingRequired = action_payload),
            };
        case "P_ADDITIONAL_SAVING_REQUIRED":
            return {
                ...state,
                pAdditionalSavingRequired: (state.pAdditionalSavingRequired = action_payload),
            };

        // Update All at once
        case "ALL":
            return {
                ...state,

                pIndex: (state.pIndex = action_payload.pIndex),
                pId: (state.pId = action_payload.pId),

                pName: (state.pName = action_payload.pName),
                pYearsToPurchase: (state.pYearsToPurchase = action_payload.pYearsToPurchase),
                pAmountNeeded: (state.pAmountNeeded = action_payload.pAmountNeeded),
                pAmountSaved: (state.pAmountSaved = action_payload.pAmountSaved),
                pMonthlySaving: (state.pMonthlySaving = action_payload.pMonthlySaving),
                pAnnualReturn: (state.pAnnualReturn = action_payload.pAnnualReturn),

                pMonthlySavingRequired: (state.pMonthlySavingRequired = action_payload.pMonthlySavingRequired),
                pAdditionalSavingRequired: (state.pAdditionalSavingRequired = action_payload.pAdditionalSavingRequired)
            };
    
        default:
            return state;
    }

};

export const MajorPurchasesReducer = (state = mpInitialState, action) => {
    /**
        {
            payload: {
                itemIndex: Number,
                data: {
                    pIndex: String,
                    pId: String,
                }
            }   
        };
     */


    switch (action.type) {

        // Savings for major purchases
        case "SMP_NUMBER":
            return {
                ...state,
                smpNumber: (state.smpNumber = action.payload.data),
            };
        case "SMP_PURCHASES":
            return {
                ...state,
                smpPurchases: (state.smpPurchases = action.payload.data),
            };

        case "SMP_INITIAL_SYNC":
            console.log("Inside SMP_INITIAL_SYNC Action!");
            let newSmpPurchases = [];
            for (let index = 0; index < action.payload.data.length; index++) {
                // const element = action.payload.data[index];
                const newPurchaseItem = MergeMajorPurchaseVar(individualMpInitialState, 'ALL', action.payload.data[index]);
                newSmpPurchases.push(newPurchaseItem);
            }
            console.log("Sync All: Final state is:");
            console.log(newSmpPurchases);
            state.smpPurchases = newSmpPurchases;
            return state;
    
        case "SMP_ADD":
            console.log("Inside ADD Action!!!!!!!!!!!!!!!!!!!!!");
            let inState = action.payload.data;
            inState.pName = Date.now().toString();

            const newPurchaseItem = MergeMajorPurchaseVar({ ...inState });
            state.smpPurchases.push(newPurchaseItem);
            return state;

        case "SMP_UPDATE":
            let smpPurchases = state.smpPurchases;
            let item_index = parseInt(action.payload.itemIndex);
            console.log(`Updating item_index: ${item_index}`);
            const itemOldState = state.smpPurchases[item_index];
            console.log(`itemOldState: ${itemOldState}`);
            console.log(itemOldState);
            const itemNewState = MergeMajorPurchaseVar(itemOldState, action.payload.variable, action.payload.data);
            console.log(`itemNewState: ${itemNewState}`);
            console.log(itemNewState);

            smpPurchases[item_index] = itemNewState;
            return {
                ...state,
                smpPurchases
            };

        case "SMP_REMOVE":

            return {
                ...state,
                smpPurchases: state.smpPurchases.filter((_value, index)=> index !== parseInt(action.payload.itemIndex))
            };

        default:
            return state;
    }
};
