import { useState, useRef } from "react"; //useContext
import { useAuthContext } from "./useAuthContext";
import { login, verifyEmail as verifyEmailAPI } from "../apiServices";

export const useSignin = () => {
  // const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const { dispatch } = useAuthContext();
  const emailRef = useRef("");

  const signin = async (email, password) => {
    emailRef.current = email;
    setIsLoading(true);
    setError(null);
    let isSuccess = true;
    const response = await login(email, password);
    if (response.login.http_status !== 200) {
      isSuccess = false;
      // setError(response.login.message);
    } else {
      isSuccess = true;
    }

    if (!isSuccess) {
      setIsLoading(false);
      setError({ status: response.login.code, message: response.login.message });
    } else {
      // save token
      localStorage.setItem("token", response.login.data.token);
      localStorage.setItem("user", JSON.stringify(response.login.data.user));
      // update authcontext
      dispatch({ type: "LOGIN", payload: response.login.data });
      setIsLoading(false);
      setError(null);
      // console.log("Signin Done!!!");
      // navigate('/scoring-details/personal-details')
    }
    return isSuccess;
  };

  const verifyEmail = async () => {
    const response = await verifyEmailAPI(emailRef.current);
    let isSuccess = true;

    if (response.verifyEmail.http_status !== 200) {
      isSuccess = false;
    } else {
      isSuccess = true;
    }

    if (!isSuccess) {
      setIsLoading(false);
      setError({ status: response.verifyEmail.code, message: response.verifyEmail.message });
    } else {
      setIsLoading(false);
      setError({status:"SUCCESS", message: response.verifyEmail.message });
    }

    
    return isSuccess;
  };
  return { signin, verifyEmail, isLoading, error };
};
