import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyCollegeEducation, addMyCollegeEducation, updateMyCollegeEducation, removeMyCollegeEducation } from '../apiServices/pfscoreForms/collegeEducation';
import { useNavigate } from "react-router-dom";

export const useCollegeEducation= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const loadCollegeEducation = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyCollegeEducation()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadCollegeEducation Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('CollegeEducation', response.getMyCollegeEducation.data);
            setIsLoading(false);
            console.log("Feteched Initial Data for CollegeEducation!!!");
        }
    }

    const addCollegeEducation = async (updateData) => {
        setIsLoading(true);
        setError(null);
        const response = await addMyCollegeEducation(updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.addMyEducation.http_status!==200) {
            isSuccess = false;
            setError(response.addMyEducation.message);
        } else {
            isSuccess = true;
            setError(response.addMyEducation.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.addMyEducation.message);
            console.log("saveCollegeEducation Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('CollegeEducation', JSON.stringify(response.addMyEducation.data));
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved CollegeEducation details!!!");
            return response.addMyEducation.data
        }
        return isSuccess
    }

    const updateCollegeEducation = async (updateData) => {
        setIsLoading(true);
        setError(null);
        const response = await updateMyCollegeEducation(updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.updateMyEducation.http_status!==200) {
            isSuccess = false;
            setError(response.updateMyEducation.message);
        } else {
            isSuccess = true;
            setError(response.updateMyEducation.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.updateMyEducation.message);
            console.log("saveCollegeEducation Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('CollegeEducation', JSON.stringify(response.updateMyEducation.data));
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved CollegeEducation details!!!");
        }
        return isSuccess
    }

    const removeCollegeEducation = async (id) => {
        setIsLoading(true);
        setError(null);
        const response = await removeMyCollegeEducation(id);
        console.log("response is:", response);
        let isSuccess = true;
        if (response.removeMyEducation.http_status!==200) {
            isSuccess = false;
            setError(response.removeMyEducation.message);
        } else {
            isSuccess = true;
            setError(response.removeMyEducation.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.removeMyEducation.message);
            console.log("saveCollegeEducation Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('CollegeEducation', JSON.stringify(response.removeMyEducation.data));
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved CollegeEducation details!!!");
        }
        return isSuccess
    }

    return {loadCollegeEducation, addCollegeEducation, updateCollegeEducation, removeCollegeEducation, isLoading, error}
}