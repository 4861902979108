import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyNetWorth = async function () {
  console.log("Inside getMyNetWorth()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyNetWorth {
          code
          message
          http_status
          data {
            id
            market_value_primary_home
            market_value_cars_major_items
            market_value_personal_valuables
            any_other_assets_description
            any_other_assets_amount
            total_savings
            cash_savings
            current_value_retirement_accounts
            value_all_brokerage_accounts
            is_mortgage_primary_home
            mortgage_interest_rate
            mortgage_years_left
            mortgage_type
            mortgage_years_total
            mortgage_balance_primary_home
            loan_balance_cars_other_major_items
            credit_card_debt_loan_balance
            any_other_loans_description
            is_other_loan
            any_other_loans_amount
            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
           market_value_primary_home
            market_value_cars_major_items
            market_value_personal_valuables
            any_other_assets_description
            any_other_assets_amount
            total_savings
            cash_savings
            current_value_retirement_accounts
            value_all_brokerage_accounts
            is_mortgage_primary_home
            mortgage_interest_rate
            mortgage_years_left
            mortgage_type
            mortgage_years_total
            mortgage_balance_primary_home
            loan_balance_cars_other_major_items
            credit_card_debt_loan_balance
            any_other_loans_description
            is_other_loan
            any_other_loans_amount
          }
        }
      }
    `,
    fetchPolicy: "no-cache" 
  });
  console.log("getMyNetWorth API Response is: ", data);
  return data;
};

export const addOrUpdateMyNetWorth = async function (
  marketValuePrimaryHome,
  marketValueCarsMajorItems,
  marketValuePersonalValuables,
  anyOtherAssetsDescription,
  anyOtherAssetsAmount,
  totalSavings,
  cashSavings,
  currentValueRetirementAccounts,
  valueAllBrokerageAccounts,
  isMortgagePrimaryHome,
  mortgageInterestRate,
  mortgageYearsLeft,
  mortgageType,
  mortgageYearsTotal,
  mortgageBalancePrimaryHome,
  loanBalanceCarsOtherMajorItems,
  creditCardDebtLoanBalance,
  anyOtherLoansDescription,
  isOtherLoan,
  anyOtherLoansAmount
) {
  console.log(`marketValuePrimaryHome: ${marketValuePrimaryHome}`);
  console.log(`marketValueCarsMajorItems: ${marketValueCarsMajorItems}`);
  console.log(`marketValuePersonalValuables: ${marketValuePersonalValuables}`);
  console.log(`anyOtherAssetsDescription: ${anyOtherAssetsDescription}`);
  console.log(`anyOtherAssetsAmount: ${anyOtherAssetsAmount}`);
  console.log(`cashSavings: ${cashSavings}`);
  console.log(
    `currentValueRetirementAccounts: ${currentValueRetirementAccounts}`
  );
  console.log(`valueAllBrokerageAccounts: ${valueAllBrokerageAccounts}`);
  console.log(`mortgageBalancePrimaryHome: ${mortgageBalancePrimaryHome}`);
  console.log(
    `loanBalanceCarsOtherMajorItems: ${loanBalanceCarsOtherMajorItems}`
  );
  console.log(`creditCardDebtLoanBalance: ${creditCardDebtLoanBalance}`);
  console.log(`anyOtherLoansDescription: ${anyOtherLoansDescription}`);
  console.log(`anyOtherLoansAmount: ${anyOtherLoansAmount}`);

  console.log("Inside addOrUpdateMyNetWorth()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addOrUpdateMyNetWorth(
        $marketValuePrimaryHome: Int!
        $marketValueCarsMajorItems: Int!
        $marketValuePersonalValuables: Int!
        $anyOtherAssetsDescription: String!
        $anyOtherAssetsAmount: Int!
        $totalSavings: Int!
        $cashSavings: Int!
        $currentValueRetirementAccounts: Int!
        $valueAllBrokerageAccounts: Int!
        $isMortgagePrimaryHome: Boolean!
        $mortgageInterestRate: Int!
        $mortgageYearsLeft: Int!
        $mortgageType: MortgageType!
        $mortgageYearsTotal: String!
        $mortgageBalancePrimaryHome: Int!
        $loanBalanceCarsOtherMajorItems: Int!
        $creditCardDebtLoanBalance: Int!
        $anyOtherLoansDescription: String!
        $isOtherLoan: Boolean!
        $anyOtherLoansAmount: Int!
      ) {
        addOrUpdateMyNetWorth(
          data: {
            market_value_primary_home: $marketValuePrimaryHome
            market_value_cars_major_items: $marketValueCarsMajorItems
            market_value_personal_valuables: $marketValuePersonalValuables
            any_other_assets_description: $anyOtherAssetsDescription
            any_other_assets_amount: $anyOtherAssetsAmount
            total_savings: $totalSavings
            cash_savings: $cashSavings
            current_value_retirement_accounts: $currentValueRetirementAccounts
            value_all_brokerage_accounts: $valueAllBrokerageAccounts
            is_mortgage_primary_home: $isMortgagePrimaryHome
            mortgage_interest_rate: $mortgageInterestRate
            mortgage_years_left: $mortgageYearsLeft
            mortgage_type: $mortgageType
            mortgage_years_total: $mortgageYearsTotal
            mortgage_balance_primary_home: $mortgageBalancePrimaryHome
            loan_balance_cars_other_major_items: $loanBalanceCarsOtherMajorItems
            credit_card_debt_loan_balance: $creditCardDebtLoanBalance
            any_other_loans_description: $anyOtherLoansDescription
            is_other_loan: $isOtherLoan
            any_other_loans_amount: $anyOtherLoansAmount
          }
        ) {
          code
          message
          http_status
          data {
            id
            market_value_primary_home
            market_value_cars_major_items
            market_value_personal_valuables
            any_other_assets_description
            any_other_assets_amount
            total_savings
            cash_savings
            current_value_retirement_accounts
            value_all_brokerage_accounts
            is_mortgage_primary_home
            mortgage_interest_rate
            mortgage_years_left
            mortgage_type
            mortgage_years_total
            mortgage_balance_primary_home
            loan_balance_cars_other_major_items
            credit_card_debt_loan_balance
            any_other_loans_description
            is_other_loan
            any_other_loans_amount
            added_on

          }
          score {
            overAllScore
            debtScore
            savingsScore
            riskScore
          }
          errors {
             market_value_primary_home
            market_value_cars_major_items
            market_value_personal_valuables
            any_other_assets_description
            any_other_assets_amount
            total_savings
            cash_savings
            current_value_retirement_accounts
            value_all_brokerage_accounts
            is_mortgage_primary_home
            mortgage_interest_rate
            mortgage_years_left
            mortgage_type
            mortgage_years_total
            mortgage_balance_primary_home
            loan_balance_cars_other_major_items
            credit_card_debt_loan_balance
            any_other_loans_description
            is_other_loan
            any_other_loans_amount
          }
        }
      }
    `,
    variables: {
      marketValuePrimaryHome: marketValuePrimaryHome,
      marketValueCarsMajorItems: marketValueCarsMajorItems,
      marketValuePersonalValuables: marketValuePersonalValuables,
      anyOtherAssetsDescription: anyOtherAssetsDescription,
      anyOtherAssetsAmount: anyOtherAssetsAmount,
      totalSavings: totalSavings,
      cashSavings: cashSavings,
      currentValueRetirementAccounts: currentValueRetirementAccounts,
      valueAllBrokerageAccounts: valueAllBrokerageAccounts,
      isMortgagePrimaryHome: isMortgagePrimaryHome,
      mortgageInterestRate: mortgageInterestRate,
      mortgageYearsLeft: mortgageYearsLeft,
      mortgageType: mortgageType,
      mortgageYearsTotal: mortgageYearsTotal,
      mortgageBalancePrimaryHome: mortgageBalancePrimaryHome,
      loanBalanceCarsOtherMajorItems: loanBalanceCarsOtherMajorItems,
      creditCardDebtLoanBalance: creditCardDebtLoanBalance,
      anyOtherLoansDescription: anyOtherLoansDescription,
      isOtherLoan: isOtherLoan,
      anyOtherLoansAmount: anyOtherLoansAmount,
    },
  });

  console.log("addOrUpdateMyNetWorth API Response is: ", data);
  return data;
};
