export const getBirthYearOptions = (minAge = 1, maxAge = 125 ) => { 
  if (maxAge > 125) {
    maxAge = 125;
  }
  if (minAge < 1) {
    minAge = 1;
  }

  const today = new Date();
  const currentYear = parseInt(today.getFullYear());
  let yearOptions = [];
  for (let index = minAge; index < maxAge; index++) { 
    // console.log(`Index: ${index} - minAge: ${minAge} - maxAge: ${maxAge} - Adding Year: ${currentYear - index}`); 
    yearOptions.push(currentYear - index);
  }
  return yearOptions;
};

// console.log(getBirthYearOptions(0, 100));

export const getDefaultBirthYear = (defaultAge = 40 ) => { 
  if (defaultAge > 125) {
    defaultAge = 125;
  }
  if (defaultAge < 1) {
    defaultAge = 1;
  }
  const today = new Date();
  const currentYear = parseInt(today.getFullYear());
  return (currentYear - defaultAge);
};

// console.log(getDefaultBirthYear(33));

export const AuthConstants = {
    "FirstNameRegex": /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/,
    "LastNameRegex": /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/,
    "PhoneRegex": /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    "SecQuestionOneOptions": [
        {
          "id": 1,
          "name": "What was the first car you drove?"
        },
        {
          "id": 2,
          "name": "Your favorite fruit?"
        },
        {
          "id": 3,
          "name": "The name of your high school?"
        },
        {
          "id": 4,
          "name": "What's your favorite color?"
        },
        {
          "id": 5,
          "name": "our favorite movie?"
        },
        {
          "id": 6,
          "name": "Your favorite sport?"
        },

      ],
    "SecQuestionTwoOptions": [
      {
          "id": 1,
          "name": "What was the first car you drove?"
        },
        {
          "id": 2,
          "name": "Your favorite fruit?"
        },
        {
          "id": 3,
          "name": "The name of your high school?"
        },
        {
          "id": 4,
          "name": "What's your favorite color?"
        },
        {
          "id": 5,
          "name": "our favorite movie?"
        },
        {
          "id": 6,
          "name": "Your favorite sport?"
        },
    
    ],
    "GenderOptions": [
      {
        "id": 1,
        "name": "Male"
      },
      {
        "id": 2,
        "name": "Female"
      },
      {
        "id": 3,
        "name": "Other"
      },
      {
        "id": 4,
        "name": "Other2"
      },
    ],
    "MaritalStatusOptions": [
      {
        "id": 1,
        "name": "Married"
      },
      {
        "id": 2,
        "name": "Single"
      },
      {
        "id": 3,
        "name": "Other"
      },
    ],
    "EducationLevelOptions": [
      {
        "id": 1,
        "name": "High School"
      },
      {
        "id": 2,
        "name": "College"
      },
      {
        "id": 3,
        "name": "Some College"
      },
      {
        "id": 4,
        "name": "Other"
      },
    ],
    "BirthYearOptions": {
      "getBirthYearOptions": getBirthYearOptions,
      "getDefaultBirthYear": getDefaultBirthYear
    }

}


