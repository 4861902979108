const scoreInitialState = {
  overAllScore: 10,
  savingsScore: 0,
  debtScore: 0,
  riskScore: 0,
  overAllScorePercentage: 10,
  debugInfo: "",
};

export const ScoreReducer = (state = scoreInitialState, action) => {
  switch (action.type) {
    case "UPDATE_WIDGET_SCORE":
      console.log(
        `Inside UPDATE_WIDGET_SCORE overAllScore: ${action.payload.overAllScore}`
      );
      console.log(action.payload);

      const totalPossibleScore = 750;
      const percentageScore = parseInt(
        ((100 * action.payload.overAllScore) / totalPossibleScore).toString()
      );
      console.log(
        `Inside UPDATE_WIDGET_SCORE percentageScore: ${percentageScore}`
      );
      return {
        ...state,
        overAllScore: (state.overAllScore = action.payload.overAllScore),

        savingsScore: (state.savingsScore = action.payload.savingsScore),
        debtScore: (state.debtScore = action.payload.debtScore),
        riskScore: (state.riskScore = action.payload.riskScore),
        // debugInfo: (state.debugInfo = action.payload.debugInfo),

        overAllScorePercentage: percentageScore,
      };

    case "SET_FINAL_SCORE":
      console.log(
        `Inside ScoreReducer > action->SET_FINAL_SCORE: overAllScore: ${action.payload.overAllScore}`
      );
      console.log(action.payload);

      return {
        ...state,
        // debugInfo: (state.debugInfo = action.payload.debugInfo),
        overAllScore: (state.overAllScore = action.payload.overAllScore),
        savingsScore: (state.savingsScore = action.payload.savingsScore),
        debtScore: (state.debtScore = action.payload.debtScore),
        riskScore: (state.riskScore = action.payload.riskScore),
        overAllScorePercentage: parseInt(
          ((100 * action.payload.overAllScore) / 750).toString()
        ),
      };

    default:
      return state;
  }
};
