import React from "react";
import "../cashManagment/CashManagment.css";
import { Container, Box, Tooltip, Typography } from "@mui/material";
import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
import RadioComponent from "../radioComponent/RadioComponent";
import RangeSlider from "../rangeSlider/RangeSlider";
import RangeSliderGR from "../rangeSliderGR/RangeSliderGR";
import question from "../../assets/images/question.png";
import chart from "../../assets/images/chart.png";
import mobchart from "../../assets/images/chartMob.png";
import PrevBUtton from "../prevButton/PrevBUtton";
import AbsButton from "../../components/absButton/AbsButton";
import { PFIFormConstants as PFIFC } from "../../constants/PFIFormConstants";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NumInput from "../numInput/NumInput";
import { useEffect } from "react";
import { useRetirement } from "../../hooks/useRetirement";
import { getMyRetirement } from "../../apiServices/pfscoreForms/retirement";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import allActions from "../../store/actions";
import { capitalizeUtil } from "../../helpers/utils";

const Retirement = ({ next, pre }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { saveRetirement, isLoading, error } = useRetirement();

  const CURR_CMP = "Retirement";
  const datafromreducer = useSelector((state) => state.RetirementReducer);
  const funcitonsformreducer = useDispatch();

  const inputOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };

  const getCurrentVal = (variable) => {
    // console.log(`${CURR_CMP}:: Getting current value for: ${variable}`);
    switch (variable) {
      case "YEARS_TO_RETIRE":
        return datafromreducer.yearsToRetire;
      case "RETIREMENT_DURATION":
        return datafromreducer.retirementDuration;

      // Living expenses during retirement (in today's $s)
      case "LE_HOUSING":
        return datafromreducer.leHousing;
      case "LE_HOUSING_PERIOD":
        return datafromreducer.leHousingPeriod;

      case "HEALTHCARE_EXPENSES":
        return datafromreducer.healthcareExpenses;
      case "LIFE_EXPENSES":
        return datafromreducer.lifeExpenses;

      case "T_E_EXPENSES":
        return datafromreducer.teExpenses;
      case "OTHER_EXPENSES":
        return datafromreducer.otherExpenses;

      // Expected liabilities during retirement
      case "EL_MORTGAGE":
        return datafromreducer.elMortgage;
      case "EL_LOANS":
        return datafromreducer.elLoans;
      case "EL_LEGACY":
        return datafromreducer.elLegacy;

      // Retirement savings
      case "RS_RETIREMENT_AC":
        return datafromreducer.rsRetirementAc;
      case "RS_CONTRIBUTION_EMPLOYER":
        return datafromreducer.rsContributionByEmployer;
      case "RS_CONTRIBUTION_YOU":
        return datafromreducer.rsContributionByYou;

      // Income from Social Security and pensions
      case "ISSP_PENSION":
        return datafromreducer.isspPension;
      case "ISSP_SOC_SEC":
        return datafromreducer.isspSocSec;

      // Economic and market factors
      case "EMF_INFLATION_RATE":
        return datafromreducer.emfInflationRate;
      case "EMF_ANNUAL_RETURN":
        return datafromreducer.emfAnnualReturn;

      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  const handleMinusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  // const [bonus, setBonus] = useState("");
  const _handleNextPage = async (e) => {
    e.preventDefault();

    const updateDta = [
      parseInt(datafromreducer.yearsToRetire),
      parseInt(datafromreducer.retirementDuration),
      datafromreducer.leHousingPeriod.toUpperCase(),
      parseInt(datafromreducer.healthcareExpenses),
      parseInt(datafromreducer.lifeExpenses),
      parseInt(datafromreducer.teExpenses),
      parseInt(datafromreducer.otherExpenses),
      parseInt(datafromreducer.leHousing),
      parseInt(datafromreducer.elMortgage),
      parseInt(datafromreducer.elLoans),
      parseInt(datafromreducer.elLegacy),
      parseInt(datafromreducer.rsRetirementAc),
      parseInt(datafromreducer.rsContributionByEmployer),
      parseInt(datafromreducer.rsContributionByYou),
      parseInt(datafromreducer.isspPension),
      parseInt(datafromreducer.isspSocSec),
      parseInt(datafromreducer.emfInflationRate),
      parseInt(datafromreducer.emfAnnualReturn),
    ];

    console.log("Update data is:");
    console.log(updateDta);

    setIsSaving(true);
    const response = await saveRetirement(updateDta);
    setIsSaving(false);

    if (response) {
      console.log("Save retirement details Response: ", response);
      next();
    } else {
      console.log("Save retirement details: Errors - Alert");
    }
  };
  const _handleprev = (e) => {
    e.preventDefault();
    pre();
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      // try {
      const response = await getMyRetirement();
      const data = await response.getMyRetirement;
      setIsLoaded(true);
      // Assign default Values
      let updateData = {
        yearsToRetire: PFIFC.rt.years_to_retire.default,
        retirementDuration: PFIFC.rt.retirement_duration.default,

        // Living expenses during retirement (in today's $s)
        leHousing: PFIFC.rt.living_expenses.housing.default,
        leHousingPeriod: capitalizeUtil(
          PFIFC.rt.living_expenses.housing_period.default
        ),
        healthcareExpenses: PFIFC.rt.living_expenses.healthcare_expenses.default,
        lifeExpenses: PFIFC.rt.living_expenses.life_expenses.default,
        teExpenses: PFIFC.rt.living_expenses.t_e_expenses.default,
        otherExpenses: PFIFC.rt.living_expenses.other_expenses.default,
        // Expected liabilities during retirement
        elMortgage: PFIFC.rt.liabilities.mortgage.default,
        elLoans: PFIFC.rt.liabilities.loans.default,
        elLegacy: PFIFC.rt.liabilities.legacy.default,

        // Retirement savings
        rsRetirementAc: PFIFC.rt.savings.retirement_accounts.default,
        rsContributionByEmployer: PFIFC.rt.savings.employer_contribution.default,
        rsContributionByYou: PFIFC.rt.savings.your_contribution.default,

        // Income from Social Security and pensions
        isspPension: PFIFC.rt.income.pension.default,
        isspSocSec: PFIFC.rt.income.social_sec.default,

        // Economic and market factors
        emfInflationRate: PFIFC.rt.economic_factors.inflation_rate.default,
        emfAnnualReturn: PFIFC.rt.economic_factors.annual_return.default,
      };
      if (data.http_status === 200) {
        updateData = {
          yearsToRetire: data.data.number_of_years_left_to_retire,
          retirementDuration: data.data.expected_duration_of_retirement,

          // Living expenses during retirement (in today's $s)
          leHousing: data.data.housing_expenses_amount,
          leHousingPeriod: capitalizeUtil(data.data.housing_expenses_period),
          healthcareExpenses: data.data.healthcare_expenses,
          lifeExpenses: data.data.life_expenses,
          teExpenses: data.data.te_expenses,
          otherExpenses: data.data.other_expenses,
          // Expected liabilities during retirement
          elMortgage: data.data.expected_mortgage_at_retirement,
          elLoans: data.data.other_expected_loans_at_retirement,
          elLegacy: data.data.legacy_you_want_to_leave,

          // Retirement savings
          rsRetirementAc: data.data.all_retirement_accounts_value,
          rsContributionByEmployer: data.data.average_monthly_contribution_by_employer,
          rsContributionByYou: data.data.monthly_contribution,

          // Income from Social Security and pensions
          isspPension: data.data.estimated_monthly_pension_benefit,
          isspSocSec: data.data.estimated_monthly_soc_sec_benefit,

          // Economic and market factors
          emfInflationRate: data.data.expected_avg_inflation_rate,
          emfAnnualReturn: data.data.expected_avg_annual_return_retirement_balance,
        };
      }
      console.log(`Retirement Initial update Data: ${updateData}`);
      console.log(updateData);

      funcitonsformreducer({
        type: "ALL_RETIREMENT",
        payload: updateData,
      });

      if (data.score && data.score.overAllScore) {
        funcitonsformreducer(
          allActions.scoreActions.UpdateScore({
            overAllScore: data.score.overAllScore,
            debtScore: data.score.debtScore,
            riskScore: data.score.riskScore,
            savingsScore: data.score.savingsScore,
            debugInfo: JSON.stringify(data.score),
          })
        );
      }

      // } catch (err) {
      //   console.log("Initial Load Error:", err);
      //   setLoadingError(err.errors[0].message);
      // }
    };

    fetchData();
  }, [funcitonsformreducer]);

  return (
    <>
      <section className="cash_managment">
        <Container maxWidth="lg" className="cash_contain">
          <DetailsFormHeading
            title_text={"Retirement"}
            subtitle_text={"Questions? See our FAQs below."}
          />

          {/* <PersonalFinancialIndex 
            audio_ogg_path={PFIFC.rt.audio_ogg_path} 
            audio_mp3_path={PFIFC.rt.audio_mp3_path} 
            /> */}

          {/* <Box className="row cash_manag">
            <Box className="cash_managment_sect">
              <h6>Step 3: Retirement</h6>
            </Box>
          </Box> */}
          <Box className="cash_management_form">
            <form>
              {/* Years to Retire */}

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Number of years left until retirement</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YEARS_TO_RETIRE")}
                      onChange={(e) => inputOnchange(e.target.value, "YEARS_TO_RETIRE")}
                      onPlusClick={(e) => handlePlusClick("YEARS_TO_RETIRE", 1)}
                      onMinusClick={(e) => handleMinusClick("YEARS_TO_RETIRE", 1)}
                      placeholder="0"
                    />
                  </Box>
                </Box>

              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Expected duration of retirement</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("RETIREMENT_DURATION")}
                      onChange={(e) => inputOnchange(e.target.value, "RETIREMENT_DURATION")}
                      onPlusClick={(e) => handlePlusClick("RETIREMENT_DURATION", 1)}
                      onMinusClick={(e) => handleMinusClick("RETIREMENT_DURATION", 1)}
                      placeholder="0"
                    />
                  </Box>
                </Box>

              </Box>


              {/* Living expenses */}
              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">
                    Living expenses during retirement
                  </Typography>
                </Box>
              </Box>

              {/* Living Expenses: Housing Expenses */}
              <Box className="mortage_wrapper web_view_mortage web_view_mortage33">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Housing expenses
                      <Tooltip title={PFIFC.rt.living_expenses.housing.tooltip}>
                        <span className="tooltip">
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <RadioComponent
                      label1="Monthly"
                      label2="Annual"
                      value1="Monthly"
                      value2="Annual"
                      defaultValue={datafromreducer.leHousingPeriod}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "LE_HOUSING_PERIOD")
                      }
                    />
                  </Box>
                </Box>

                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("LE_HOUSING")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "LE_HOUSING")
                      }
                      onPlusClick={(e) => handlePlusClick("LE_HOUSING")}
                      onMinusClick={(e) => handleMinusClick("LE_HOUSING")}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
              </Box>


              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Healthcare expenses</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("HEALTHCARE_EXPENSES")}
                      onChange={(e) => inputOnchange(e.target.value, "HEALTHCARE_EXPENSES")}
                      onPlusClick={(e) => handlePlusClick("HEALTHCARE_EXPENSES")}
                      onMinusClick={(e) => handleMinusClick("HEALTHCARE_EXPENSES")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Life expenses (food, clothing, etc)</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("LIFE_EXPENSES")}
                      onChange={(e) => inputOnchange(e.target.value, "LIFE_EXPENSES")}
                      onPlusClick={(e) => handlePlusClick("LIFE_EXPENSES")}
                      onMinusClick={(e) => handleMinusClick("LIFE_EXPENSES")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1"> Travel and entertainment per year</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("T_E_EXPENSES")}
                      onChange={(e) => inputOnchange(e.target.value, "T_E_EXPENSES")}
                      onPlusClick={(e) => handlePlusClick("T_E_EXPENSES")}
                      onMinusClick={(e) => handleMinusClick("T_E_EXPENSES")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">Any other monthly expenses</Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("OTHER_EXPENSES")}
                      onChange={(e) => inputOnchange(e.target.value, "OTHER_EXPENSES")}
                      onPlusClick={(e) => handlePlusClick("OTHER_EXPENSES")}
                      onMinusClick={(e) => handleMinusClick("OTHER_EXPENSES")}
                      placeholder="0"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>

              </Box>



              {/* Expected liabilities during retirement */}
              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">
                    Expected liabilities during retirement
                  </Typography>
                </Box>
              </Box>
              {/* Home Mortgage */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Expected mortgage at the <br />
                      time of retirement
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.liabilities.mortgage.max}
                        min={PFIFC.rt.liabilities.mortgage.min}
                        val={datafromreducer.elMortgage}
                        handleOnChange={hanldeChangeElMortgage}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.elMortgage}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "EL_MORTGAGE")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("EL_MORTGAGE")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "EL_MORTGAGE")
                      }
                      onPlusClick={(e) => handlePlusClick("EL_MORTGAGE", 1000)}
                      onMinusClick={(e) =>
                        handleMinusClick("EL_MORTGAGE", 1000)
                      }
                      step={"1000"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.liabilities.mortgage.max}
                    min={PFIFC.rt.liabilities.mortgage.min}
                    val={datafromreducer.elMortgage}
                    handleOnChange={hanldeChangeElMortgage}

                  />
                </Box> */}
              </Box>

              {/* Expected loans */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Other expected loans at the <br />
                      time of retirement
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.liabilities.loans.max}
                        min={PFIFC.rt.liabilities.loans.min}
                        val={datafromreducer.elLoans}
                        handleOnChange={hanldeChangeElLoans}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.elLoans}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "EL_LOANS")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("EL_LOANS")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "EL_LOANS")
                      }
                      onPlusClick={(e) => handlePlusClick("EL_LOANS", 1000)}
                      onMinusClick={(e) => handleMinusClick("EL_LOANS", 1000)}
                      step={"1000"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.liabilities.loans.max}
                    min={PFIFC.rt.liabilities.loans.min}
                    val={datafromreducer.elLoans}
                    handleOnChange={hanldeChangeElLoans}

                  />
                </Box> */}
              </Box>

              {/* Legacy you want to leave */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Legacy you want to leave
                      <Tooltip title={PFIFC.rt.liabilities.legacy.tooltip}>
                        <span className="tooltip">
                          <img src={question} alt="" />
                        </span>
                      </Tooltip>
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.liabilities.legacy.max}
                        min={PFIFC.rt.liabilities.legacy.min}
                        val={datafromreducer.elLegacy}
                        handleOnChange={hanldeChangeElLegacy}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.elLegacy}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "EL_LEGACY")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("EL_LEGACY")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "EL_LEGACY")
                      }
                      onPlusClick={(e) => handlePlusClick("EL_LEGACY", 1000)}
                      onMinusClick={(e) => handleMinusClick("EL_LEGACY", 1000)}
                      step={"1000"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.liabilities.legacy.max}
                    min={PFIFC.rt.liabilities.legacy.min}
                    val={datafromreducer.elLegacy}
                    handleOnChange={hanldeChangeElLegacy}
                  />
                </Box> */}
              </Box>

              {/* Retirement savings */}
              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">Retirement savings</Typography>
                </Box>
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Current value of all retirement accounts
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                  
                    <NumInput
                      value={getCurrentVal("RS_RETIREMENT_AC")}
                      onChange={(e) =>
                        inputOnchange(
                          e.target.value,
                          "RS_RETIREMENT_AC"
                        )
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("RS_RETIREMENT_AC", 100)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("RS_RETIREMENT_AC", 100)
                      }
                      step={"100"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                
              </Box>
              {/* Savings: contribution by employer */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Average monthly contribution by employer
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                   
                    <NumInput
                      value={getCurrentVal("RS_CONTRIBUTION_EMPLOYER")}
                      onChange={(e) =>
                        inputOnchange(
                          e.target.value,
                          "RS_CONTRIBUTION_EMPLOYER"
                        )
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("RS_CONTRIBUTION_EMPLOYER", 100)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("RS_CONTRIBUTION_EMPLOYER", 100)
                      }
                      step={"100"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
               
              </Box>

              {/* Savings: Your monthly contribution */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Your monthly contribution
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.savings.your_contribution.max}
                        min={PFIFC.rt.savings.your_contribution.min}
                        val={datafromreducer.rsContributionByYou}
                        handleOnChange={hanldeChangeRsContributionByYou}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.rsContributionByYou}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "RS_CONTRIBUTION_YOU")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("RS_CONTRIBUTION_YOU")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "RS_CONTRIBUTION_YOU")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("RS_CONTRIBUTION_YOU", 100)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("RS_CONTRIBUTION_YOU", 100)
                      }
                      step={"100"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.savings.your_contribution.max}
                    min={PFIFC.rt.savings.your_contribution.min}
                    val={datafromreducer.rsContributionByYou}
                    handleOnChange={hanldeChangeRsContributionByYou}

                  />
                </Box> */}
              </Box>

              {/* Income from Social Security and pensions */}
              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">
                    Income from Social Security and pensions
                  </Typography>
                </Box>
              </Box>

              {/* Income: Pension */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Estimated monthly pension <br />
                      benefit in today's $s
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.income.pension.max}
                        min={PFIFC.rt.income.pension.min}
                        val={datafromreducer.isspPension}
                        handleOnChange={hanldeChangeIsspPension}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.isspPension}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "ISSP_PENSION")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("ISSP_PENSION")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "ISSP_PENSION")
                      }
                      onPlusClick={(e) => handlePlusClick("ISSP_PENSION", 100)}
                      onMinusClick={(e) =>
                        handleMinusClick("ISSP_PENSION", 100)
                      }
                      step={"100"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.income.pension.max}
                    min={PFIFC.rt.income.pension.min}
                    val={datafromreducer.isspPension}
                    handleOnChange={hanldeChangeIsspPension}

                  />
                </Box> */}
              </Box>

              {/* Income: Social Sec */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Estimated monthly Soc. Sec. <br />
                      benefit in today's $s
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.income.social_sec.max}
                        min={PFIFC.rt.income.social_sec.min}
                        val={datafromreducer.isspSocSec}
                        handleOnChange={hanldeChangeIsspSocSec}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <p className="dollar">$</p>
                        <input
                          value={datafromreducer.isspSocSec}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "ISSP_SOC_SEC")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("ISSP_SOC_SEC")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "ISSP_SOC_SEC")
                      }
                      onPlusClick={(e) => handlePlusClick("ISSP_SOC_SEC", 100)}
                      onMinusClick={(e) =>
                        handleMinusClick("ISSP_SOC_SEC", 100)
                      }
                      step={"100"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.income.social_sec.max}
                    min={PFIFC.rt.income.social_sec.min}
                    val={datafromreducer.isspSocSec}
                    handleOnChange={hanldeChangeIsspSocSec}

                  />
                </Box> */}
              </Box>

              {/* Economic and market factors */}
              <Box className="row cash_manag" sx={{ marginTop: "32px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">
                    Economic and market factors
                  </Typography>
                </Box>
              </Box>

              {/* Economic Factor: Inflation Rate */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Expected avg. inflation rate
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.economic_factors.inflation_rate.max}
                        min={PFIFC.rt.economic_factors.inflation_rate.min}
                        val={datafromreducer.emfInflationRate}
                        handleOnChange={hanldeChangeEmfInflationRate}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <input
                          value={datafromreducer.emfInflationRate}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "EMF_INFLATION_RATE")
                          }
                        />
                      </Box>
                    </Box> */}

                    <NumInput
                      value={getCurrentVal("EMF_INFLATION_RATE")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "EMF_INFLATION_RATE")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("EMF_INFLATION_RATE", 1)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("EMF_INFLATION_RATE", 1)
                      }
                      placeholder=""
                      displayPercentage={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.economic_factors.inflation_rate.max}
                    min={PFIFC.rt.economic_factors.inflation_rate.min}
                    val={datafromreducer.emfInflationRate}
                    handleOnChange={hanldeChangeEmfInflationRate}

                  />
                </Box> */}
              </Box>

              {/* Economic Factor: Inflation Rate */}
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Expected avg. annual return <br />
                      on retirement balance
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    {/* <Box className="web_view_mortage property_ranger_slider">
                      <RangeSliderGR
                        max={PFIFC.rt.economic_factors.annual_return.max}
                        min={PFIFC.rt.economic_factors.annual_return.min}
                        val={datafromreducer.emfAnnualReturn}
                        handleOnChange={hanldeChangeEmfAnnualReturn}
                      />
                    </Box>
                    <Box className="">
                      <Box className="value">
                        <input
                          value={datafromreducer.emfAnnualReturn}
                          onChange={(e) =>
                            inputOnchange(e.target.value, "EMF_ANNUAL_RETURN")
                          }
                        />
                      </Box>
                    </Box> */}
                    <NumInput
                      value={getCurrentVal("EMF_ANNUAL_RETURN")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "EMF_ANNUAL_RETURN")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("EMF_ANNUAL_RETURN", 1)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("EMF_ANNUAL_RETURN", 1)
                      }
                      step={1}
                      placeholder=""
                      displayPercentage={true}
                    />
                  </Box>
                </Box>
                {/* <Box className="mobile_radio_view">
                  <RangeSliderGR
                    max={PFIFC.rt.economic_factors.annual_return.max}
                    min={PFIFC.rt.economic_factors.annual_return.min}
                    val={datafromreducer.emfAnnualReturn}
                    handleOnChange={hanldeChangeEmfAnnualReturn}

                  />
                </Box> */}
              </Box>

              {/* Buttons */}
              <br />
              <br />
              <Box className="buttons_section" sx={{ marginTop: "32px" }}>
                <PrevBUtton prev_name="Previous Page" onClick={_handleprev} />
                <AbsButton title="Next Page" onClick={_handleNextPage} />
              </Box>
            </form>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default Retirement;
